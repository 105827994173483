Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "certificationtracking";
exports.labelBodyText = "certificationtracking Body";

exports.btnExampleTitle = "CLICK ME";
exports.prjHeader="Project in Progress";
exports.btnProjectsSummary = 'Projects Summary';
exports.btnProjectsDetails = 'Projects Details';
exports.btnMessage = 'Message';
exports.milestone1='1';
exports.milestone2='2';
exports.labelSubmitWorkReview='Submit Work for Review';
exports.labelVerify='Verified';
exports.labelFileSubmitted="File Submitted";
exports.labelAddMilestone='Add New Milestone';
exports.labelMenuBrowse="Browse All Projects";
exports.labelMenuProgress="Project In-Progress";
exports.labelMenuComplete="Completed Projects";
exports.labelMenuBids="My Bids";
exports.btnSubmit="Submit File";
exports.labelSponsorMsg="Message for Sponsor";
exports.labelUpload="Upload File";
exports.submitButton="Submit";
exports.cancelButton="Cancel";
exports.searchPlaceholder='Write here...';
exports.detailsMilestonesHeader="September, 2022";
exports.detailsAvatarname="John Doe";

exports.detailsBidAccepted="Bid Accepted"
exports.detailsMilestoneCreated="Milestone Created";
exports.detailsMessageSent="Message Sent";
exports.detailsMessageReceived="Message Received";
exports.detailsMileStoneFileUploaded="Mile Stone 1 - File Uploaded";
exports.detailsMileStoneRequestedRevision="Mile Stone 1 - Requested Revision";

exports.detailsBidAcceptedText="Agreed Amount : $10    Deadline :24-Sep-2022";
exports.detailsMilestoneCreatedText="Name :Milestone Name 1  Deadline : 24-Sep-2022";
exports.detailsMessageSentText="Hey Amin Mohamed, Please try to complete the first milestopne by 10th sep. Thanks!";
exports.detailsMessageReceivedText="Sure, John";
exports.detailsMileStoneFileUploadedText="Mile Stone 1 - File Uploaded";
exports.detailsMileStoneRequestedRevisionDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.";
exports.detailsMileStoneRequestedRevisionDeadline="Deadline :24-Sep-2022";
exports.deliverSuccessMsg="Milestone Submitted Successfully"
exports.deliverErrorMsg="Something went wrong. Please try again."

exports.detailsMilestonesTime="10:19 AM"
exports.labelAnotherAmount="Another Amount";
exports.labelRadio="Payment for this milestone";
exports.errorNoMilestones="No records for milestones found."
exports.errorNoDetailsForMilestones="No details for milestones found."
exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";

exports.sponsorDetailsApiCallUrl = "bx_block_profile/projects/get_ids?project_id="
exports.milestonesApiCallUrl = "bx_block_scheduling/freelancers_milestones?project_id="
exports.milestonesDeliverApiCallUrl = "bx_block_scheduling/project_milestones/"
exports.getDataApiCallIdUrl = "bx_block_profile/projects/get_ids?project_id="
exports.apiPatchMethodType = "PATCH";
exports.submitCertificateCorrectionApiUrl= "bx_block_profile/projects/request_for_correction"
exports.getCertificationCostApiUrl= "bx_block_profile/projects/get_certificate_cost"
exports.paymentCertificationApiUrl= "bx_block_ccavenueintegration2/payment_for_certificate"

exports.BackTxt="Back";
exports.continueBtnTxt = 'Continue';
exports.gotoDashboard = 'Go to Dashboard';
exports.browseMoreProjects ='Browse More Projects';
exports.shareYourFeedBackReview ='Share Your Feedback';
exports.behindTimeTxt = "is behind the scheduled time"
exports.pendingLabel = "Pending";
exports.reqForRevisionLabel = "Requested for Revision";
exports.createChatApiUrl="bx_block_chat/chats"
exports.rateSponserAPIEndpoint="/bx_block_reviews/reviews"
exports.httpPostMethod = "POST"
exports.rateSponser = "Rate Sponsor"
exports.labelMB = " MB"
exports.labelKB = " KB"
exports.milestoneCancelSign= 'X'
exports.milestoneCanceledStatus="Milestone Canceled"
exports.RejectionHeaderTxt="Select a Reason for Rejection"
exports.acceptBtnTxt="Accept"
exports.rejectBtnTxt="Reject"
exports.rejectOptionTxt1="Payment not initiated for submitted files"
exports.rejectOptionTxt2="Client is not responding"
exports.rejectOptionTxt3="Other"
exports.rejectOptionValue1="Payment not initiated"
exports.rejectOptionValue2="Client not responding"
exports.rejectOptionValue3="other"
exports.textareaPlaceholder="Write Here..."
exports.errorNoSelection="Please select the option for rejection."
exports.errorNoReason="Please write the reason for rejection."
exports.prjDetailsTimeFormat="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
exports.prjDetailsTimeHourFormat="hh"
exports.prjDetailsTimeMinFormat="mm"
exports.optionTxt="Add Files (optional)"
exports.closureSuccessMsg = "Closure option selected successfully!"
exports.disputeSuccessMsg = "Dispute option selected successfully!"
exports.acceptClosureApiCallUrl = "bx_block_profile/projects/approve_project_closure"
exports.acceptTxt="accept"
exports.rejectTxt="reject"
exports.disputeRaisedTxt = "Dispute has been Raised for Project"
exports.closureRequestedTxt = "Project Closure Requested"
exports.disputeApiCallUrl = "bx_block_profile/projects/approve_dispute"
exports.milestoneRefundStatus="Refund Accepted"
exports.labelDueDate= "Due Date:"
exports.labelTotalAmt= "Total Amount:"
exports.labelAcceptanceStart= "Acceptance start date:"
exports.labelAcceptanceEnd= "Validation period end date:"
exports.labelCoolingStart= "Cooling period start date:"
exports.labelCoolingEnd= "Cooling period end date:"
exports.dueDateFormat ="MMM DD, YYYY"
exports.rejectRefundOptionTxt1="Payment not initiated for submitted files"
exports.rejectRefundOptionTxt2="Client is not responding"
exports.rejectRefundOptionTxt3="Other"
exports.rejectRefundOptionValue1="Payment not initiated"
exports.rejectRefundOptionValue2="Client not responding"
exports.rejectRefundOptionValue3="other"
exports.refundRequestTxt="Project Refund Requested"
exports.raiseFlagTxt='raise' 
exports.startedLabel = "Started";
exports.fileSubmitLabel = "File Submitted";
exports.completedLabel = "Completed";
exports.milestoneOnHoldStatus="On Hold"
exports.periodStatus="start"
exports.acceptRefundApiCallUrl = "bx_block_refund_management/refund_managements/update_refund_status?id="
exports.refundFlagTxt='raised' 
exports.refundAmtTxt='Refund amount:'
exports.refundReqRaisedTitleText = "Refund request has been Raised"
exports.refundReqAcceptTitleText = "Refund request Resolved"
exports.refundReqRejectedTitleText = "Refund request Rejected"
exports.disputeRequestFlag='raised'
exports.disputeMilestoneApiCallUrl = "bx_block_scheduling/raise_disputes/approve_dispute?role=freelancer"
exports.rejectDisputeMilestoneOptionTxt1="Payment not initiated for submitted files"
exports.rejectDisputeMilestoneOptionTxt2="Client is not responding"
exports.rejectDisputeMilestoneOptionTxt3="Other"
exports.rejectDisputeMilestoneOptionValue1="Payment not initiated"
exports.rejectDisputeMilestoneOptionValue2="Client not responding"
exports.rejectDisputeMilestoneOptionValue3="other"

exports.pathPrjSummary= "PrjSummary";
exports.pathOnHoldPrjSummary=  "OnHoldPrjSummary";
exports.pathClosedPrjSummary=  " ClosedPrjSummary";
exports.pathCompletedPrjSummary=  "CompletedPrjSummary";
exports.pathDisputePrjSummary=  "DisputePrjSummary";
exports.pathFreelancerProjects=  "FreelancerProjects";
exports.closedType=  "closed";
exports.onholdType=  'onhold';
exports.progressType=  'progress';
exports.completedType=  'completed';
exports.disputeType=  'dispute' ;
exports.labelMaxUpload="Max file size: 15MB";
exports.errorMaxUpload="File size exceeds 15 MB.";
exports.pathProjects="Projects"
exports.disputeProjectType="disputeproject";
exports.closedProjectType="closedproject";
exports.completedProjectType="completedproject";
exports.onholdProjectType="projectonhold";
exports.milestoneRefundRejectedStatus="Refund Rejected"

exports.milestonesDetailsApiCallUrl= "bx_block_scheduling/project_details?check_rating_from=freelancer&project_id="
exports.ratingHeaderTxt= "How would you rate this sponsor ?"
exports.ratingPoor="Poor"
exports.ratingAverage ="Average"
exports.ratingGood="Good"
exports.ratingVeryGood="Very Good"
exports.ratingExcellent="Excellent"
exports.anyFeedbackTxt="Any Feedback"
exports.feedbackPlaceholder="Enter your feedback"
exports.sendButton="Send";
exports.ratingErrorMsg="Please give the ratings."
exports.convertionText1="Converted from"
exports.convertionText2="into"
exports.convertionText3="based on today's conversion rate."
// Customizable Area End