import React from "react";
import { ThemeProvider } from "react-native-elements";

import { createTheme } from "@material-ui/core/styles";
import FreelancerHeader from "../FreelancerHeader.web";
import DashboardFooter from "../DashboardFooter.web";
import {
  Container,
  Typography,
  makeStyles,
  Grid,
  Box,
} from "@material-ui/core";
import {
  browseAllProjectIcon,
  myBidsIcon,
  completedProjectIcon,
  projectInProgressIcon,
} from "../assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { webStyle } from "../Bid.web";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const useStyles = makeStyles({
  browseAllProjectHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    color: "#222222",
    paddingTop: "40px",
    paddingBottom: "26px",
  },
  sideRoutingBlock: {
    padding: "20px",
    borderRadius: "14px",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    backgroundColor: "rgba(242, 242, 242, 1)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "218px",
    height:"fit-content",
    marginBottom:"20px"
  },
  sideNavigationItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 5px",
    cursor: "pointer",
  },
  singleRouteBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  routeIcons: {
    margin: "0 10px 0",
  },
  textLabelLink: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#222222",
    fontFamily: "Inter",
  },
  activeSpecificBlock: {
    color: "#EC952D",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Inter",
  },
  forwardIcon: {
    color: "#EC952D",
  },
  rightSideItem: {
    paddingLeft: "20px"
  },
});

const routeItemsArray = [
  {
    img: browseAllProjectIcon,
    name: "Browse All Projects",
    route: "browse-all",
    activeImg: browseAllProjectIcon,
  },
  {
    img: projectInProgressIcon,
    name: "Projects In-Progress",
    route: "progress",
    activeImg: browseAllProjectIcon,
  },
  {
    img: completedProjectIcon,
    name: "Completed Projects",
    route: "completed",
    activeImg: browseAllProjectIcon,
  },
  {
    img: completedProjectIcon,
    name: "Closed Projects",
    route: "closed",
    activeImg: browseAllProjectIcon,
  },
  {
    img: completedProjectIcon,
    name: "Disputed Projects",
    route: "dispute",
    activeImg: browseAllProjectIcon,
  },
  {
    img: completedProjectIcon,
    name: "On-Hold Projects",
    route: "onhold",
    activeImg: browseAllProjectIcon,
  },
  {
    img: myBidsIcon,
    name: "My Quotes",
    route: "my-bid",
    activeImg: browseAllProjectIcon,
  }, {
    img: myBidsIcon,
    name: "My Invitations",
    route: "my-invitations",
    activeImg: browseAllProjectIcon,
  },
];

interface Props {
  children: React.ReactNode;
  navigation: any;
  title: string;
  route: string;
}

const FreelancerProjectsLayout: React.FC<Props> = ({
  children,
  navigation,
  title,
  route,
}) => {
  const styles = useStyles();

  const navigate = (path: string) => {
    navigation?.navigate("FreelancerProjects", { type: path });
  };
  return (
    <ThemeProvider theme={theme}>
      <FreelancerHeader
        navigation={navigation}
        history={undefined}
        location={undefined}
        id={""} route={"projects"}
      />
     
      <Container maxWidth="lg">
        <Typography variant="h5" className={styles.browseAllProjectHeading}>
          {title}
        </Typography>
        <style>
                {`
                  @media(max-width: 959px){
                    .resRightSide{
                      padding-left: 0px !important;
                    }
                  }
                `}
              </style>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} className={styles.sideRoutingBlock}>
            <Box>
              {routeItemsArray.map((item) => {
                return (
                  <Box
                    data-testid="routeItem"
                    className={styles.sideNavigationItem}
                    key={item.name}
                    onClick={() => navigate(item.route)}
                  >
                    <Box className={styles.singleRouteBlock}>
                      <img
                        src={route === item.route ? item.activeImg : item.img}
                        alt={item.name}
                        className={styles.routeIcons}
                      />
                      <Typography
                        variant="h6"
                        className={
                          route === item.route
                            ? styles.activeSpecificBlock
                            : styles.textLabelLink
                        }
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <ChevronRightIcon
                      className={route === item.route ? styles.forwardIcon : ""}
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9} style={webStyle.rightSideItem} 
          className="resRightSide">
            {children}
          </Grid>
        </Grid>
      </Container>
      <DashboardFooter history={undefined} location={undefined} />
    </ThemeProvider>
  );
};

export default FreelancerProjectsLayout;
