import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import {IRole } from "./types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "../../../framework/src/Utilities";

import {countryCodes} from './assets'
const cityList =require('../assets/cities.json');
export interface  ICurrencyInfo {
  id: string;
  type: string;
  attributes: {
      name: string;
      currency: string;
      currency_symbol: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  file: any;
  basicdetailarray: any;
  roleList: IRole[];
  subRoleList: IRole[];
  errors: any;
  initialValues: any;
  countryList: string[];
  stateList: string[];
  cityList: string[];
  countryLoading:boolean;
  cityLoading:boolean;
  stateLoading:boolean;
  timeZoneLoading:boolean;
  timeZoneList:string[];
  currencyList:ICurrencyInfo[];
  profileRole: {
    freelancer: boolean;
    sponsor: boolean;
    subRole: "individual" | "student" | "corporate";
  };
  roleSelectLoader:boolean;
  currentUserEmail:string;
  saveUserInfo:boolean;
  country_code:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AccountinfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInput: any;
  roleid: any;
  getRoleListApiCallId: string = "";
  getSubRoleListApiCallId: string = "";
  getCountryListApiCallId:string="";
  getCityListApiCallId:string="";
  getStateListApiCallId:string="";
  getTimeZoneListApiCallId:string="";
  getCurrentUserInfoApiCallId:string="";
  getCurrencyListApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      profileRole: {
        freelancer: false,
        sponsor: false,
        subRole: "individual",
      },
      file: null,
      roleList: [],
      subRoleList: [],
      basicdetailarray: [],
      errors: {},
      timeZoneList:[],
      currencyList:[],
      timeZoneLoading:false,
      initialValues: {
        profilephoto: null,
        first_name: "",
        last_name: "",
        gender: "",
        mobileNumber: "",
        mobile_number:"",
        address: "",
        address_line_2: "",
        country: "",
        city: "",
        state: "",
        postal_code: "",
        date_of_birth: "",
        time_zone: "",
        currency:"",
        countryCode:"IN",
        dialCode:""
      },
      countryList: [],
      cityList: [],
      stateList: [],
      countryLoading:false,
      cityLoading:false,
      stateLoading:false,
      roleSelectLoader:false,
      currentUserEmail:"",
      saveUserInfo:true,
      country_code:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getRoleListApiCallId) {
      this.setState({
        roleList: response?.data?.length > 0 ? response?.data : [],
        roleSelectLoader:false
      });
    } else if (apiRequestCallId === this.getSubRoleListApiCallId) {
      this.setState(
        {
          subRoleList: response?.data?.length > 0 ? response?.data : [],
          roleSelectLoader:false
        },
        () => this.setActualSubRole()
      );
    }else if(apiRequestCallId===this.getCountryListApiCallId){
      this.handleCountryListPayload(response);
    }else if(apiRequestCallId===this.getStateListApiCallId){
      this.handleStateListPayload(response);
    }else if(apiRequestCallId===this.getTimeZoneListApiCallId){
      this.handleGetTimeZonePayload(response);
    }else if(apiRequestCallId===this.getCurrentUserInfoApiCallId){
      this.handleGetCurrentUserPayload(response);
    }else if(apiRequestCallId===this.getCityListApiCallId){
      this.handleCityListPayload(response);
    }else if(apiRequestCallId===this.getCurrencyListApiCallId){
      this.handleCurrencyListingPayload(response);
    }
  }
  handleCurrencyListingPayload=(response:any)=>{
      this.setState({currencyList:response?.data || []})
  }
  handleGetCurrentUserPayload=(response:any)=>{
    this.setState({
      currentUserEmail:response?.email || ""
    })
  }
  handleCityListPayload=(response:any)=>{
    this.setState({
      cityLoading:false,
      cityList:response?.city_names.length>0?response?.city_names:[]
    })
  }
  
  sendLogoutUserMessage=()=>{
    const msg: Message = new Message(getName(MessageEnum.UserLogoutMessage));
    this.send(msg);
  }

  handleCountryListPayload=(response:any)=>{
    this.setState({
      countryList:response?.countries_name || [],
      countryLoading:false
    })
  }
  
  handleGetTimeZonePayload=(response:any)=>{
    this.setState({
      timeZoneList:response?.length>0 ? response:[],
      timeZoneLoading:false,
    })
  }
  handleStateListPayload=(response:any)=>{
    this.setState({
      stateList:response?.state_names || [],
      stateLoading:false
    })
  }
  //Web events

  async componentDidMount() {
    this.getRoleList();
    this.getCountryList();
    this.getCurrentUserEmail();
    this.getCurrencyListing();
    const basicDetailsInfoString = localStorage.getItem(
      "Basicdetailarray"
    ) as string;
    const userRole = localStorage.getItem("userRole");
    if (userRole) {
      this.setState({
        profileRole: JSON.parse(userRole),
      });
    }

    try {
      if (basicDetailsInfoString) {
        const parsedInfo = JSON.parse(basicDetailsInfoString);
        this.setState({ initialValues: parsedInfo },this.checkCountrySaved);
        const convertedFile = await this.convertBlobToFile(
          parsedInfo?.photo,
          parsedInfo?.photoName
        );
        this.setState({ file: convertedFile });
      }
    } catch (err) {
      console.log(err);
    }
  }

  checkCountrySaved=()=>{
     const {initialValues}=this.state;
     if(initialValues?.country){
      this.getStateList(initialValues.country)
      this.getTimeZoneList(initialValues?.country)
     }
     if(initialValues?.state){
       this.getCityList(initialValues?.state)
     }
  }

  getTodayDate=()=>{
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }
  onCountrySelected=(countryName:string)=>{
    const countryCode =
      countryCodes?.find((item:any) => item?.country_name===countryName)
        ?.country_code || "";
      this.setState({country_code:countryCode})
    this.getStateList(countryName);
    this.getTimeZoneList(countryName)
  }
  getCurrentUserEmail = async() => {
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getCurrentUserInfoApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCurrencyListing = async() => {
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCurrencyListingApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getCurrencyListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    return header;
  }
  getTimeZoneList =async (countryName:string) => {
    this.setState({timeZoneLoading:true})
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getTimeZoneListApiUrl}?country=${countryName}`,
      method: "GET",
      body: null,
    });
    this.getTimeZoneListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getCountryList = async() => {
    const header=await this.getHeader();
    this.setState({countryLoading:true})
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCountryListApiUrl}?enable=true`,
      method: "GET",
      body: null,
    });
    this.getCountryListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getStateList = async(countryName:string) => {
    const header=await this.getHeader();
    this.setState({stateLoading:true,stateList:[]})
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getStateListApiUrl}?country_name=${countryName}`,
      method: "GET",
      body:null
    });
    this.getStateListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCityList = async(stateName:string) => {
    let cityNames:string[]=[];
    for(let i=0; i<cityList?.length; i++){
      const city=cityList[i];
      if(city?.state_name?.includes(stateName)){
        cityNames.push(city?.name)
      }
    }
    this.setState({cityList:cityNames,cityLoading:false})
  };

  handleSubRoleChange = (subRole: "individual" | "corporate" | "student") => {
    this.setState(
      (prev) => ({
        profileRole: {
          ...prev.profileRole,
          subRole: subRole,
        },
      }),
      () => this.setActualSubRole()
    );
  };
  handleFreelancerChange = () => {
    this.setState(
      (prev) => ({
        profileRole: {
          ...prev.profileRole,
          freelancer: !prev.profileRole.freelancer,
        },
      }),
      () => this.checkSubRoleCall()
    );
  };
  handleSponsorChange = () => {
    this.setState(
      (prev) => ({
        profileRole: {
          ...prev.profileRole,
          sponsor: !prev.profileRole.sponsor,
        },
      }),
      () => this.checkSubRoleCall()
    );
  };

  checkSubRoleCall = () => {
    const { profileRole } = this.state;
    const { freelancer, sponsor } = profileRole || {};
    let parentRole;
    if (freelancer && sponsor) {
      parentRole = this.getParentRoleId("Both");
    } else if (freelancer && !sponsor) {
      parentRole = this.getParentRoleId("Freelancer");
    } else if (!freelancer && sponsor) {
      parentRole = this.getParentRoleId("Project Sponsor");
    }
    this.getSubRoleList(parentRole as string);
  };

  getParentRoleId = (name: "Project Sponsor" | "Freelancer" | "Both") => {
    const roleList = this.state.roleList;
    const role = roleList.find((item) => item.attributes.name === name);
    return role?.id ? role?.id : roleList[0]?.id;
  };
  setActualSubRole = () => {
    const {profileRole:{freelancer}}=this.state;
    const subRoleName = freelancer?this.state.profileRole.subRole:"Corporate";
    const subRoleList = this.state.subRoleList;
    const subRole = subRoleList.find((item) =>
      item.attributes.name
        .toLocaleLowerCase()
        .includes(subRoleName.toLocaleLowerCase())
    );
    if (subRole?.id) {
      localStorage.setItem("selectedRole", subRole?.id);
    }
  };

  convertBlobToFile = (photo: any, photoName: string) => {
    if (photo && photoName) {
      return fetch(photo)
        .then((response) => response.blob())
        .then((blobData) => {
          return new File([blobData], photoName);
        });
    }
    return null;
  };

  handlecancle = () => {
    this.props.navigation.navigate("SignIn");
  };

  handleClick = () => {
    this.fileInput.click(); // Programmatically trigger the file input click
  };
  submitRoleSelection = () => {
    window.scrollTo(0, 0);
    localStorage.setItem("userRole", JSON.stringify(this.state.profileRole));
    this.props.navigation?.navigate("Basicdetails");
  };
  handleGoBack=()=>{
    this.setState({saveUserInfo:false},()=>{
      this.props.navigation?.goBack();
    })
  }

  basicdetailsubmit = (value: any) => {
    const minDate = new Date("01/01/1900");
    const currentDate = new Date();
    const selectedDate = new Date(value?.date_of_birth);
    if (selectedDate > currentDate || minDate > selectedDate) {
      return;
    }
    if (this.state.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.state.file);
      const { name } = this.state.file;
      reader.onload = () => {
        const fileDataUrl = reader.result;
        const updatedArray = { ...value, profilephoto: fileDataUrl, photoName: name };
        this.setState({ basicdetailarray: updatedArray });
        window.localStorage.setItem(
          "Basicdetailarray",
          JSON.stringify(updatedArray)
        );
        window.scrollTo(0, 0);
        this.props.navigation.navigate("Profilesetup");
      };
    } 
    else {
      const updatedArray = { ...value, profilephoto: "", photoName: "" };
      this.setState({ basicdetailarray: value });
      window.localStorage.setItem(
        "Basicdetailarray",
        JSON.stringify(updatedArray)
      );
      window.scrollTo(0, 0);
      this.props.navigation.navigate("Profilesetup");
    }
  };

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    this.setState({ file: droppedFile });
  };

  handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      // File size exceeds 5MB
      toast.error("Please select a file smaller than 5MB.");
      event.target.value = null; // Reset the file input
      return;
    }
    this.setState({ file: selectedFile });
  };

  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleUpload = () => {};

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const result = year + "-" + month + "-" + day;
    return result;
  }

  handleDateOfBirthChange = (date: string) => {
    const selectedDate = moment(date);
    const today = moment();
    const isAfter = moment(selectedDate.add(18, "year")).isAfter(today, "year");
    console.log(isAfter, "isAfter");
    if (isAfter) {
      const label = document.querySelector('label[for="date_of_birth"]');
      if (label) {
        label.innerHTML = "Date of Birth* (Age must be above 18)";
      }
    }
  };

  getRoleList = () => {
    const requestMessage = CreateRestApiMessage({
      header: {},
      apiUrl: `${configJSON.getUserRoleApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getRoleListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSubRoleList = (parentId: string) => {
    this.setState({roleSelectLoader:true})
    const requestMessage = CreateRestApiMessage({
      header: {},
      apiUrl: `${configJSON.getUserRoleApiUrl}?parent_id=${parentId}`,
      method: "GET",
      body: null,
    });
    this.getSubRoleListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
