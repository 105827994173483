import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

  // Customizable Area Start
export const configJSON = require("./config");

import { IProjectData } from "../../dashboard/src/types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "framework/src/Utilities";
import {toast} from 'react-toastify'
interface ITabOptions{
  id:number;
   label:string;
   value:string;
   isActive:boolean;
}
export interface IFreelancer {
  id: string;
  type: string;
  attributes: {
      id: number;
      role_id: number;
      first_name: string;
      last_name: string;
      gender: string;
      date_of_birth: string;
      country_code: string | null;
      mobile_number: string;
      time_zone: string;
      address: string;
      rating: number | null;
      address_line_2: string;
      city: string;
      state: string;
      country: string;
      postal_code: string;
      account_id: number;
      bid_id:string|number;
      hour_rate:number | null;
      total_ratings:number | null;
      is_favourite_freelancer:boolean;
      availability:boolean;
      certification: any[];  
      experience_in_years: number | null;
      hobbies: any[];  
      full_name: string;
      skills: any[];  
      invitation_sent: boolean;
      invitation_status: string | null;
      invitation_id: string | null;
      other_skills: string | null;
      category_of_interests: ICategoryOfInterest[];
      role_name: string;
      photo: { url: string }[];
      uploaded_documents: any[];  
      uploaded_resumes: any[];  
      field_of_study: string | null;
      work_experiences: any[];  
      currency_symbol:string | null;
      company_details?: {
          data: ICompany[];
      } | null;
      educational_qualification: string | null;
      account_detail: {
          data: {
              id: string;
              type: string;
              attributes: {
                  activated: boolean;
                  country_code: string | null;
                  email: string;
                  first_name: string | null;
                  full_phone_number: string;
                  last_name: string | null;
                  phone_number: string | null;
                  type: string;
                  created_at: string;
                  updated_at: string;
                  device_id: string | null;
                  unique_auth_id: string;
              };
          };
      };
  };
}

interface ICategoryOfInterest {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: string | null;
  rank: number | null;
  light_icon: { url: string | null };
  light_icon_active: { url: string | null };
  light_icon_inactive: { url: string | null };
  dark_icon: { url: string | null };
  dark_icon_active: { url: string | null };
  dark_icon_inactive: { url: string | null };
  identifier: string | null;
  description: string;
  number_of_bidders: number;
}

interface ICompany {
  id: string;
  type: string;
  attributes: {
      account_id: number;
      company_name: string;
      company_website: string;
      company_contact: string;
      company_address: string | null;
      other_industry: string;
      year_of_registration: number | null;
      registration_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      country: string | null;
      state: string | null;
      city: string | null;
      postal_code: string | null;
      awards_won_by_company: any[]; 
      freelancing_experience: string | null;
      number_of_employee: number;
      vat_id: string;
      industry: string | null;
      company_size: string | null;
      company_type: string;
      company_profile: string | null;
      company_overview: string | null;
      certification: any[]; 
      created_at: string;
      updated_at: string;
      skills: any[]; 
      category_of_interests?: ICategoryOfInterest[] | null;
      logo: string | null;
  };
}
const FREELANCER_PER_PAGE=5;
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 
  // Customizable Area Start
  tabOptions:Array<ITabOptions>;
  openSendInvitationModal:boolean;
  sendInvitationLoading:boolean;
  openDeclineInvitationModal:boolean;
  declineInvitationLoading:boolean;
  getProjectLoading:boolean;
  project:IProjectData | null;

  freelancerList: IFreelancer[];
  getFreelancerLoading: boolean;
  totalFreelancer: number;
  currentPage: number;
  freelancerSearchText: string;
  freelancerQueryString: string;
  selectedInviteFreelancer:{
    name:string;
    image:string;
    skills:string;
    id:string;
    location:string;
    invitationId:string | null;
  } | null;
  clearPopupInput:boolean;
  isFavouriteProfileID: number;
  loading:boolean;
  loadingindex:number | string;
  isInvitedList: boolean;
  noInvitedFreelancer:boolean;
  // Customizable Area End

}

interface SS { }

export default class SponsorInviteFreelancerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSingleProjectInfoApiCallId:string="";
  getFreelancerListApiCallId:string="";
  sendInvitationApiCallId:string="";
  declineInvitationApiCallId:string="";
  favouriteBidCallID: any;
  favouriteProfileId:number=-1;
  getInvitedFreelancerListApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      // Customizable Area End
    ]);

    this.state = {
        // Customizable Area Start
        tabOptions:[
          {
            id:1,
            label:"All",
            value:"all",
            isActive:true,
          },{
            id:2,
            label:"Invited Freelancers",
            value:"invited_freelancers",
            isActive:false,
          },
        ],
        openSendInvitationModal:false,
        sendInvitationLoading:false,
        openDeclineInvitationModal:false,
        declineInvitationLoading:false,
        getProjectLoading:false,
        project:null,
        freelancerList:[],
        currentPage:1,
        freelancerQueryString:"",
        freelancerSearchText:"",
        getFreelancerLoading:false,
        totalFreelancer:0,
        selectedInviteFreelancer:null,
        clearPopupInput:false,
        isFavouriteProfileID: NaN,
        loading:false,
        loadingindex:"",
        isInvitedList:false,
        noInvitedFreelancer:false,
        // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    // Customizable Area Start
     this.handleApiResponse(responseJson,apiRequestCallId)
    // Customizable Area End

    }
  }
// Customizable Area Start
handleApiResponse=(responseJson:any,apiRequestCallId:string)=>{
   if(apiRequestCallId===this.getSingleProjectInfoApiCallId){
    this.handleGetSingleProjectApiResponse(responseJson);
   }else if(apiRequestCallId===this.getFreelancerListApiCallId){
    this.handleGetFreelancersApiResponse(responseJson)
   }else if(apiRequestCallId===this.sendInvitationApiCallId){
    this.handleSendInvitationApiResponse(responseJson)
   }else if(apiRequestCallId===this.declineInvitationApiCallId){
    this.handleDeclineInvitationApiResponse(responseJson)
   }else if(apiRequestCallId===this.favouriteBidCallID){
    this.setState((prev)=>{
      const updatedLancers=prev.freelancerList.map((item)=>{
        if(item.id===this.favouriteProfileId.toString()){
          return{
            ...item,
            attributes:{
              ...item.attributes,
              is_favourite_freelancer:!item.attributes.is_favourite_freelancer
            }
          }
        }
        return item;
      })
      return{
       freelancerList:updatedLancers,
       loading:false,
       loadingindex:""
      }
   })
   } else if(apiRequestCallId===this.getInvitedFreelancerListApiCallId){
    this.handleGetInvitedFreelancersApiResponse(responseJson)
   }
}
handleGetSingleProjectApiResponse=(responseJson:any)=>{
  if(responseJson?.data?.id){
    this.setState({
       project:responseJson?.data,
       getProjectLoading:false
    })
  }else{
    this.setState({getProjectLoading:false})
    toast.error("Project Not Found!!")
  }
}
handleGetFreelancersApiResponse = (responseJson: any) => {
  if (responseJson && responseJson?.profiles?.data?.length>0 && !this.state.noInvitedFreelancer && !this.state.isInvitedList) {
    this.setState({isInvitedList: false})
    this.setState((prev) => {
      let newFreelancerList: any[] = [];
      if (prev.currentPage === 1) {
        newFreelancerList = responseJson?.profiles?.data;
      } else {
        newFreelancerList = [...prev.freelancerList, ...responseJson.profiles.data];
      }
      return {
        freelancerList: newFreelancerList,
        getFreelancerLoading: false,
        totalFreelancer: responseJson?.pagination?.total || responseJson?.pagination?.total_data || 0,
      };
    });
  } else {
    this.setState({ getFreelancerLoading: false });
  }
};

handleGetInvitedFreelancersApiResponse = (responseJson: any) => {
  if(responseJson && responseJson.profiles && responseJson.profiles.data.length > 0){
    this.setState((prev) => {
      let newInvitedFreelancerList: any[] = [];
      if (prev.currentPage === 1) {
        newInvitedFreelancerList = responseJson.profiles.data;
      } else {
        newInvitedFreelancerList = [...prev.freelancerList, ...responseJson.profiles.data];
      }
      return {
        freelancerList: newInvitedFreelancerList,
        getFreelancerLoading: false, isInvitedList:true, noInvitedFreelancer:false,
        totalFreelancer: responseJson.pagination.total_data,
      };
    })
  } 
  if(responseJson.message === "No invited freelancers found for this project"){
    toast.error("No invited freelancers found for this project")
    this.setState({getFreelancerLoading:false, noInvitedFreelancer:true})
  }
};

handleSendInvitationApiResponse=(response:any)=>{
    this.setState({sendInvitationLoading:false,openSendInvitationModal:false});
    const responseJson=response?.invitation?.data;
    if(responseJson?.id){
      toast.success(response?.message);
      this.setState(prev=>({
         freelancerList:prev.freelancerList.map((item)=>{
          if(item.id===prev.selectedInviteFreelancer?.id){
            return{
               ...item,
               attributes:{
                ...item.attributes,
                invitation_sent:true,
                invitation_status:"sent",
                invitation_id:responseJson?.id
               }
            }
          }
           return item;
         }),
         selectedInviteFreelancer:null,
         clearPopupInput:true
      }))
    }else if(response?.error){
      toast.error(response.error)
    }else if(response?.errors && response?.errors?.length>0){
      toast.error(response?.errors[0])
    }
}

handleLike = (item: any,index:number) => {
  this.setState({ isFavouriteProfileID: item?.id , loadingindex:index }, () => {
    this.favourite(this.state.isFavouriteProfileID)
  })
}

favourite = async(isFavouriteProfileID:number) => {
  this.setState({loading:true})
  this.favouriteProfileId=isFavouriteProfileID;
  const token = await getStorageData("loginSuccessToken");
  const projectId=this.props.navigation.getParam("projectId");
  let body = {
    favourite: {
      lancer_profile_id: isFavouriteProfileID,
      project_id:projectId
    },
    project_id:projectId
  }
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token:token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.favouriteBidCallID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addAndRemoveFavouritesAPIEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}; 

handleDeclineInvitationApiResponse=(response:any)=>{
    this.setState({declineInvitationLoading:false,openDeclineInvitationModal:false});
    const responseJson=response?.invitation?.data;
    if(responseJson?.id){
      toast.success("Invitation Declined Successfully!");
      this.setState(prev=>{
        const isAllTabSelected=prev.tabOptions.find((item)=>item.isActive)?.value==="all";
        let updatedFreelancerList:IFreelancer[]=[];
        if(isAllTabSelected){
          updatedFreelancerList=prev.freelancerList.map((item)=>{
            if(item.id===prev.selectedInviteFreelancer?.id){
              return{
                 ...item,
                 attributes:{
                  ...item.attributes,
                  invitation_sent:true,
                  invitation_status:"decline"
                 }
              }
            }
             return item;
           })
        }else{
            updatedFreelancerList=prev.freelancerList.filter((item)=>item.id!==prev.selectedInviteFreelancer?.id);
        }
        return{
         freelancerList:updatedFreelancerList,
         selectedInviteFreelancer:null,
         clearPopupInput:true
        }
      })
    }else if(response?.error){
      toast.error(response.error)
    }
}


async componentDidMount() {
    this.getSingleProjectInfo();
    this.prepareFinalQuery();
}



getHeader=async()=>{
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token
  };
  return header;
}

freelancerSearchHandler = (searchText: string) => {
  this.setState({ freelancerList:[],currentPage: 1, freelancerSearchText: searchText }, () => {
    this.prepareFinalQuery();
  });
};
freelancerFilterHandler = (query: string) => {
  this.setState({ freelancerList:[],currentPage: 1, freelancerQueryString: query }, () => {
    this.prepareFinalQuery();
  });
};
freelancerCancelFilterHandler = () => {
  this.setState({freelancerList:[], currentPage: 1, freelancerQueryString: "" }, () => {
    this.prepareFinalQuery();
  });
};

prepareFinalQuery = () => {
  let baseQuery: string[] = [];
  const { freelancerQueryString, freelancerSearchText, currentPage,tabOptions } = this.state;
  const activeTab=tabOptions.find((tab)=>tab.isActive);

  if(activeTab?.value==="all"){
   baseQuery.push(`type=${activeTab?.value}`)
  }
  baseQuery.push(`current_page=${currentPage}`);
  baseQuery.push(`per_page=${FREELANCER_PER_PAGE}`);
  baseQuery.push(`project_id=${this.props.navigation.getParam("projectId")}`);
  if (freelancerSearchText) {
    baseQuery.push(`search=${freelancerSearchText}`);
  }
  if (freelancerQueryString) {
    baseQuery.push(freelancerQueryString);
    baseQuery.push(`filter=true`)
  }
  const newQueryText = baseQuery.join("&");
  this.getFreelancerLists(newQueryText);
  if(activeTab?.value==="invited_freelancers"){
    this.getInvitedFreelancers(newQueryText)
  }
};

handleProjectLoadMore = () => {
  const { getFreelancerLoading } = this.state;
  if (!getFreelancerLoading) {
    this.setState(
      (prev) => ({ currentPage: prev.currentPage + 1 }),
      () => {
        this.prepareFinalQuery();
      }
    );
  }
};

 getSingleProjectInfo=async()=>{
    this.setState({getProjectLoading:true})
    const header=await this.getHeader();
    const projectId=this.props.navigation.getParam("projectId");
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleProjectApiUrl}/${projectId}`,
      body: null,
      method: "GET",
    });

    this.getSingleProjectInfoApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }

 getFreelancerLists=async(query:string)=>{
  this.setState({getFreelancerLoading:true})
  const header=await this.getHeader();
  const queryParams = new URLSearchParams(query);
  const hasFilter = queryParams.get('filter') === 'true' || queryParams.get('search');
  const filterParams = this.state.freelancerQueryString;
  let baseEndpoint;
  let transformedQuery = query;
  if (!hasFilter) {
    baseEndpoint = "bx_block_profile/search_algorithms/freelancer_visibility_algo1";
  } else if (filterParams.includes('skills[]=') && this.hasOnlySkillsAndDefaultParams(query)) {
    transformedQuery = this.transformSkillsQueryFormat(query);
    baseEndpoint = "bx_block_profile/search_algorithms/freelancer_sorting_algo2";
  } else {
    baseEndpoint = "bx_block_auctionbidding/bids/list_all_freelancer";
  }
  const apiRequestMessage = CreateRestApiMessage({
    header,
    apiUrl: `${baseEndpoint}?${transformedQuery}`,
    body: null,
    method: "GET",
  });

  this.getFreelancerListApiCallId = apiRequestMessage.messageId;
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }

 getInvitedFreelancers = async(query:any) => {
  this.setState({getFreelancerLoading:true})
  const header = await this.getHeader();
  const apiRequestMessage = CreateRestApiMessage({
    header,
    apiUrl: `${configJSON.getInivitedFreelancersApiEndpoint}?${query}`,
    body: null,
    method: "GET",
  });

  this.getInvitedFreelancerListApiCallId = apiRequestMessage.messageId;
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }
 
 handleInviteNow=()=>{
     const allTabId=this.state.tabOptions.find((item)=>item.value==="all")?.id;
     this.handleTabOptionsChanged(allTabId as number);
 }
 handleTabOptionsChanged=(id:number)=>{
    if(id==1){
      this.setState({isInvitedList:false, noInvitedFreelancer:false})
    }
     this.setState((prev)=>{
      return{
         tabOptions:prev.tabOptions.map((item)=>{
           if(item.id===id){
             return{
               ...item,
               isActive:true
             }
           }
            return{
            ...item,
            isActive:false
          };
         }),
         freelancerList:[],
         currentPage:1,
      }
     },()=>this.prepareFinalQuery())
 }
 handleSelectInviteFreelancer=(freelancer:IFreelancer,type:"invite"|"decline")=>{
  const freelancerPhoto=freelancer.attributes.photo;
  const photoLink=freelancerPhoto?.length>0?freelancerPhoto[0]?.url:"";
  const state=freelancer.attributes.state;
  const country=freelancer.attributes.country;
  const skills=freelancer?.attributes?.skills?.map((item)=>item?.name)?.slice(0,3)?.join(" | ");
  this.setState({
     selectedInviteFreelancer:{
      id:freelancer.id,
      image:photoLink,
      location:`${state} , ${country}`,
      skills:skills,
      name:freelancer?.attributes?.full_name,
      invitationId:freelancer?.attributes?.invitation_id
     }
  },()=>{
     if(type==="invite"){
      this.handleOpenSendInvitationModal(true)
     }else{
      this.handleOpenDeclineInvitatinModal(true);
     }
  })
 }
 handleOpenSendInvitationModal=(open:boolean)=>{
    this.setState({
      openSendInvitationModal:open
    })
    if(!open){
      this.setState({selectedInviteFreelancer:null})
    }
 }
 handleOpenDeclineInvitatinModal=(open:boolean)=>{
   this.setState({
     openDeclineInvitationModal:open
   })
   if(!open){
    this.setState({selectedInviteFreelancer:null})
  }
 }

 handleSendMessage=(freelancerId:string)=>{
  const projectId=this.props.navigation.getParam("projectId");
  const inviteChatMessage: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  inviteChatMessage.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "Chat"
  );
  inviteChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
   
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    createConversation:true,
    userId:freelancerId,
    projectId:projectId,
    userType:"freelancer"
  });
  inviteChatMessage.addData(
    getName(MessageEnum.NavigationRaiseMessage),
    raiseMessage
  );
  this.send(inviteChatMessage);
 }

 handleDeclineInvitation=async(message:string)=>{
  const {selectedInviteFreelancer}=this.state;
  if(!selectedInviteFreelancer){
    toast.error("No Selected Freelancer Found!");
    return;
  }
  this.setState({declineInvitationLoading:true});
  const header=await this.getHeader();
  const apiRequestMessage = CreateRestApiMessage({
    header,
    apiUrl: `${configJSON.sendInvitationApiUrl}/${selectedInviteFreelancer?.invitationId}?reason=${message}`,
    body: null,
    method: "PUT",
  });
  this.declineInvitationApiCallId = apiRequestMessage.messageId;
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

 }
 handleSendInvitation=async(message:string)=>{
    //manage send invitation here
    const {selectedInviteFreelancer}=this.state;
    if(!selectedInviteFreelancer){
      toast.error("No Selected Freelancer Found!");
      return;
    }
    this.setState({sendInvitationLoading:true});
    const header=await this.getHeader();
    const projectId=this.props.navigation.getParam("projectId");
    const formData=new FormData();
    formData.append("freelancer_id",selectedInviteFreelancer?.id);
    formData.append("project_id",projectId);
    formData.append("message",message);
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.sendInvitationApiUrl}`,
      body: formData,
      method: "POST",
    });
    this.sendInvitationApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }
 navigateToHireFreelancer=(freelancer:IFreelancer)=>{
  this.props.navigation.navigate("PraposalDetails", { id: freelancer?.attributes?.bid_id });
 }
 handleGoBack=()=>{
   this.props.navigation.goBack();
 }
 isDefaultParameter = (param: string): boolean => {
  const defaultParams = ['type', 'current_page', 'per_page', 'project_id', 'filter'];
  return defaultParams.some(defaultParam => param.startsWith(`${defaultParam}=`));
 };
 hasOnlySkillsAndDefaultParams = (queryString: string): boolean => {
  const params = queryString.split('&');
  return params.every(param => 
    param.startsWith('skills[]=') || this.isDefaultParameter(param)
  );
 };
 transformSkillsQueryFormat = (query: string): string => {
  const skillsRegex = /skills\[]=([^&]+)/g;
  const matches = Array.from(query.matchAll(skillsRegex));
  if (matches.length === 0) return query;
  const skills = matches.map(match => decodeURIComponent(match[1]));
  let newQuery = query.replace(/skills\[]=([^&]+)(&|$)/g, '');
  newQuery = newQuery.replace(/&$/, '');
  const transformedSkills = `skills=[${skills.map(skill => encodeURIComponent(skill)).join(',')}]`;
  return newQuery ? `${newQuery}&${transformedSkills}` : transformedSkills;
 };
  // Customizable Area End
}