import React from "react";
// Customizable Area Start
import {
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Box,
  Container,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import GenericCardFilter from "../../../components/src/GenericCardFilter";
import "../assets/css/Search.css";
// Customizable Area End

// Customizable Area Start
import BrowseAllProjectLancer from "./BrowseAllProjectLancer.web";
import CompletedProjectLancer from "./CompletedProjectLancer.web";
import ProjectInProgressLancer from "./ProjectInProgressLancer.web";
import MyBidsLancer from "./MyBidsLancer.web";
import { createTheme, withStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import {
  browseAllProjectIcon,
  myBidsIcon,
  completedProjectIcon,
  projectInProgressIcon,
  filterIcon,
} from "./assets";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

let routeItemsArray = [
  {
    img: browseAllProjectIcon,
    name: "Browse All Projects",
    route: "browseAllProject",
    activeImg: browseAllProjectIcon,
  },
  {
    img: projectInProgressIcon,
    name: "Projects In-Progress",
    route: "projectInProgress",
    activeImg: browseAllProjectIcon,
  },
  {
    img: completedProjectIcon,
    name: "Completed Projects",
    route: "completedProject",
    activeImg: browseAllProjectIcon,
  },
  {
    img: myBidsIcon,
    name: "My Bids",
    route: "myBids",
    activeImg: browseAllProjectIcon,
  },
];

// Customizable Area End

import SearchBidController, {
  Props,
  configJSON,
} from "./SearchBidController.web";
import { ThemeProvider } from "react-native-elements";

interface CProps {
  response: any;
  pageNumber: any;
  projectLoading: any;
  totalCards: any;
  tabLables: any;
  projectCards: any;
  activeTab: any;
  handleProjectBidTabChange: any;
  navigate: any;
  handleProjectBidLoadMore: any;
  renderDetailsView: any;
}

const RenderDetailsViewComponent: React.FC<CProps> = ({
  renderDetailsView,
  response,
  pageNumber,
  projectLoading,
  totalCards,
  tabLables,
  projectCards,
  activeTab,
  handleProjectBidTabChange,
  navigate,
  handleProjectBidLoadMore,
}) => {
  const componentMapping: Record<string, JSX.Element> = {
    browseAllProject: (
      <BrowseAllProjectLancer
        projectCards={projectCards}
        activeTab={activeTab}
        handleProjectBidLoadMore={handleProjectBidLoadMore}
        response={response}
        pageNumber={pageNumber}
        projectLoading={projectLoading}
        totalCards={totalCards}
        tabLables={tabLables}
        handleProjectBidTabChange={handleProjectBidTabChange}
        navigate={navigate}
      />
    ),
    projectInProgress: (
      <ProjectInProgressLancer history={undefined} location={undefined} />
    ),
    completedProject: (
      <CompletedProjectLancer
        navigation={undefined}
        history={undefined}
        location={undefined}
      />
    ),
    myBids: <MyBidsLancer />,
  };

  if (componentMapping.hasOwnProperty(renderDetailsView)) {
    return componentMapping[renderDetailsView];
  }

  return null;
};

export default class Bid extends SearchBidController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const {
      response,
      filterOptions,
      projectLoading,
      searchQuery,
      projectBidSelectedOption,
      displayFilterSortCard,
      filterActiveTab,
      tabLables,
    } = this.state;
    const { classes } = this.props;
    console.log("List", this.state.projectCards);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
        <FreelancerHeader
                    navigation={this.props.navigation}
                    history={undefined}
                    location={undefined}
                    id={""}
                    route={""}
                />
          {response ? (
            <Container maxWidth="lg">
              <Typography
                variant="h5"
                className={classes.browseAllProjectHeading}
              >
                {configJSON.browseAllProjectTxt}
              </Typography>
              
              <Grid container spacing={5} className={classes.conatiner_a}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className={classes.sideRoutingBlock}
                >
                  <Box>
                    {routeItemsArray.map((item) => {
                      return (
                        <Box
                          className={classes.sideNavigationItem}
                          key={item.name}
                          onClick={() =>
                            this.navigateToSpecificDetailsPage(item.route)
                          }
                          data-testid={`navigateToSpecificPage-${item.route}`}
                        >
                          <Box className={classes.singleRouteBlock}>
                            <img
                              src={
                                this.state.renderDetailsView === item.route
                                  ? item.activeImg
                                  : item.img
                              }
                              alt={item.name}
                              className={classes.routeIcons}
                            />
                            <Typography
                              variant="h6"
                              className={
                                this.state.renderDetailsView === item.route
                                  ? classes.activeSpecificBlock
                                  : classes.textLabelLink
                              }
                            >
                              {item.name}
                            </Typography>
                          </Box>
                          <ChevronRightIcon
                            className={
                              this.state.renderDetailsView === item.route &&
                              classes.forwardIcon
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item md={9} sm={12} className={classes.rightSideItem} >
                  <Box className={classes.searchBarBoxBid}>
                    <TextField
                      data-test-id="searchInputId"
                      variant="outlined"
                      fullWidth
                      placeholder={configJSON.placeHolderNameTxt}
                      className="input-box"
                      style={{ width: "100%" }}
                      value={searchQuery}
                      onChange={this.handleProjectBidSearchInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchQuery ? (
                              <button
                                onClick={this.handleProjectBidClearSearch}
                                className={classes.clearIconButton}
                              >
                                <ClearIcon />
                              </button>
                            ) : null}
                            <button
                              onClick={this.handleProjectBidSearchClick}
                              disabled={projectLoading}
                              style={{
                                backgroundColor: "#EC952D",
                                borderRadius: "50%",
                                width: "46px",
                                height: "46px",
                                border: "none",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: projectLoading
                                  ? "notAllowed"
                                  : "pointer",
                                opacity: projectLoading ? 0.5 : 1,
                              }}
                            >
                              <SearchIcon />
                            </button>
                          </InputAdornment>
                        ),
                        style: { borderRadius: "35px", paddingRight: "5px" },
                      }}
                    />
                    <button
                      onClick={this.handleFilterSortCard}
                      style={webStyle.filterButton}
                    >
                      <span>
                        <img src={filterIcon} />
                      </span>
                      {configJSON.filterTxt}
                    </button>
                  </Box>
                  <GenericCardFilter
                    displayFilterSortCard={displayFilterSortCard}
                    labels={[
                      "Categories",
                      "Speciality",
                      "Language",
                      "Skills",
                      "Location",
                      "Experience",
                    ]}
                    selectedfilterOption={projectBidSelectedOption}
                    filterOptions={filterOptions}
                    loading={projectLoading}
                    activeTab={filterActiveTab}
                    tabLabels={tabLables.tabFilterLabels}
                    handleFilterSortCard={this.handleFilterSortCard}
                    handleOptionsChange={this.handleProjectBidOptionsChange}
                    handleClearSelectedOptions={this.handleClearSelectedOptions}
                    handleProjectApplyClick={this.handleProjectApplyClick}
                    handleTabChange={(e, val) =>
                      this.handleProjectBidTabChange(e, val, 0)
                    }
                    activeTabColor={"#EC952D"}
                  />
                  <Box>
                    <RenderDetailsViewComponent
                      renderDetailsView={this.state.renderDetailsView}
                      projectCards={this.state.projectCards}
                      activeTab={this.state.activeTab}
                      handleProjectBidLoadMore={this.handleProjectBidLoadMore}
                      response={response}
                      pageNumber={this.state.pageNumber}
                      projectLoading={projectLoading}
                      totalCards={this.state.totalCards}
                      tabLables={tabLables}
                      handleProjectBidTabChange={this.handleProjectBidTabChange}
                      navigate={this.props.navigation.navigate}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <div>{configJSON.somethingWentWrongTxt}</div>
          )}
          <DashboardFooter history={undefined} location={undefined} />
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const webStyle: any = {
  rightSideItem: {
    // marginTop: "-20px",
    paddingLeft: "20px"
  },
  filterButton: {
    color: "rgb(255, 255, 255)",
    borderRadius: "26px",
    fontSize: "14px",
    fontWeight: 600,
    padding: "0px 18px",
    background: "#EC952D",
    height: "46px",
    width: "104px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  clearIconButton: {
    backgroundColor: "#EC952D",
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    border: "none",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    cursor: "pointer",
  },
  conatiner_a: {
    padding: "15px",
  },
  browseAllProjectHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    color: "#222222",
    paddingTop: "40px",
    paddingBottom: "26px",
  },
  sideRoutingBlock: {
    padding: "20px",
    borderRadius: "14px",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    backgroundColor: "rgba(242, 242, 242, 1)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "218px",
    height: "13rem",
  },
  forwardIcon: {
    color: "#EC952D",
    // height:"20px",
    // width:"20px"
  },
  activeSpecificBlock: {
    color: "#EC952D",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    // color: "#222222",
    fontFamily: "Inter",
  },
  routeIcons: {
    margin: "0 10px 0",
  },
  textLabelLink: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#222222",
    fontFamily: "Inter",
  },
  singleRouteBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  sideNavigationItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 5px",
    cursor: "pointer",
  },

  searchBarBoxBid: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    gap: "30px",
  },
};
export const BidStyles = withStyles(webStyle)(Bid);
// Customizable Area End
