import React from "react";

// Customizable Area Start
import {
  Typography,
  InputLabel,
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Snackbar , 
} from "@material-ui/core";
import {
  Done as DoneIcon,
  Create as CreateIcon,
  VisibilityOff,
  Visibility
} from "@material-ui/icons";
import { withStyles, createTheme, Theme } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CircularProgress from "@material-ui/core/CircularProgress";



// Customizable Area End

// Customizable Area Start

import AccountDetailsController, {
  IsnackBarProps,
  Props,
  configJSON
} from "././AccountDetailsController.web";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

const CustomSnackbar = ({ snackBarStyle, isOpen, message, onCloseState }: IsnackBarProps) => {
  return (
    <Snackbar
      open={isOpen}
      message={message}
      ContentProps={{
        classes: {
          root: snackBarStyle
        }
      }}
      autoHideDuration={10000}
      onClose =  {onCloseState}
    />
  );
};

// Customizable Area End

export default class AccountDetails extends AccountDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  changePasswordEmailView(classes: any) {
    return (
      this.state.renderView === "changePasswordEmailView" && (
        <>
        <Box id="passwordBlock">
          <Box className={classes.currentPasswordBlock}>
            <Typography className={classes.passwordHeading}>
              {configJSON.psrdTxt}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.changePasswordTxtContainer}>
                <InputLabel
                  htmlFor="newpassword"
                  className={classes.inputLabelTxt}
                >
                  {configJSON.newPsrdwordTxt}
                </InputLabel>
                <TextField
                  id="password"
                  type={
                    this.state.PasswordVisibilityState.newPassword
                      ? "text"
                      : "password"
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.togglePasswordVisibility.bind(this, "newPassword")
                          }
                        >
                          {this.state.PasswordVisibilityState.newPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder={configJSON.newPasswordPlaceHolder}
                  onChange={(event) => this.handleOnChangeInputValues(event)}
                  name = 'newpassword'
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.changePasswordTxtContainer}>
                <InputLabel
                  htmlFor="confirmPassword"
                  className={classes.inputLabelTxt}
                >
                  {configJSON.confirmPasswordTxt}
                </InputLabel>
                <TextField
                  id="confirmPasswordd"
                  type={
                    this.state.PasswordVisibilityState.confirmPasswordVisible
                      ? "text"
                      : "password"
                  }
                  value={this.state.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.togglePasswordVisibility.bind(this,"confirmPasswordVisible" )
                          }
                        >
                          {this.state.PasswordVisibilityState
                            .confirmPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder={configJSON.newPasswordPlaceHolder}
                  onChange={event =>
                    this.handleOnChangeInputValues(event)
                  }
                  name = 'confirmpassword'
                />
              </Box>
            </Grid>
          </Grid>
          {this.state.passwordMismatchError  !== '' && <Typography className={classes.errorMsg}>{this.state.passwordMismatchError}</Typography>}
          <Box className={classes.changePasswordBottomBlock}>
            <Box className={classes.saveButtonBlock}>
              <Button
                type="button"
                className={classes.resendBtn}
                onClick={this.handleRenderView.bind(this,"passwordView")}
                id="cancelBtn1"
                
               
              >
                {configJSON.cancelBtnTxt}
              </Button>
              <Button
                className={classes.verifyBtn}
                onClick={this.handleChangePasswordOtp} >
                {configJSON.verifyBtnTxt}
              </Button>
            </Box>
          </Box>
        </Box>
     </>
          )
    );
  }

  renderverificationCodeView(classes: any) {
    return (
      this.state.renderEmailView === "verificationCodeView" && (
        <Box className={classes.emailVerificationCodeContainer}>
          <InputLabel className={classes.inputLabelTxt}>
            {configJSON.verificationCodeTxt}
          </InputLabel>
          <Box className={classes.verifyInputandBtnConatiner}>
            <TextField
              InputProps={{
                classes: {
                  input: classes.verificationCodeInputField
                }
              }}
              fullWidth
              placeholder={configJSON.verificationCodePlaceHolder}
              onChange={event =>
                this.handleOnChangeInputValues(event)
              }
              id="OTPInput1"
              name='verificationCode'
            />

            <Box
              className={classes.saveButtonBlock}
              id="btnthree"
            >
              <Button type="button" className={classes.resendBtn} onClick={this.handleEmailVerify} >
                {configJSON.resendBtnTxt}
              </Button>
              <Button
                className={classes.verifyBtn}
                onClick={this.verifyVerificationCode}
                id="btnfour">
                {configJSON.verifyBtnTxt}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    );
  }

  rendernewEmailView(classes: any) {
    return (
      this.state.renderEmailView === "newEmailView" && (
        <Box className={classes.emailVerificationCodeContainer}>
          <InputLabel className={classes.inputLabelTxt}>
            {configJSON.newEmailAddressTxt}
          </InputLabel>
          <Box className={classes.verifyInputandBtnConatiner}>
            <TextField
              InputProps={{
                classes: {
                  input: classes.verificationCodeInputField
                }
              }}
              fullWidth
              placeholder={configJSON.newEmailPlaceHolder}
              onChange={event =>
               this.handleOnChangeInputValues(event)
              }
              id="output2"
              name='newEmail'
            />
            <Box className={classes.saveButtonBlock}>
              <Button
                type="button"
                className={classes.resendBtn}
                onClick={() => this.handleEmailView("emailView")}
                id="cancelBtn">
                {configJSON.cancelBtnTxt}
              </Button>
              <Button
                className={classes.verifyBtn}
                onClick={() => this.handleEmailView("verificationCodeView")}
                id="btnfive">
                {configJSON.verifyBtnTxt}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    );
  }

  renderpasswordView(classes: any) {
    return (
      this.state.renderView === "passwordView" && (
        <>
          <InputLabel className={classes.inputLabelTxt}>
            {configJSON.psrdTxt}
          </InputLabel>
          <Box className={classes.textFieldContainer}>
            <Box>
              <Typography className={classes.accountDetailstextStyle}>
                ************
              </Typography>
            </Box>
            <CreateIcon
              className={classes.editIcon}
              onClick={() => this.handleRenderView("editPasswordView")}
              id="btnsix"
            />
          </Box>
        </>
      )
    );
  }

  renderEditMobileNoView = (classes: any) => (
    <Box className={classes.emailVerificationCodeContainer} id="editMobileNo">
      <InputLabel className={classes.inputLabelTxt}>Mobile Number</InputLabel>
      <Box className={classes.verifyInputandBtnConatiner}>
      <PhoneInput
          test-id="editmobile"
          inputStyle={{
          width:"100%",
          border:"none",
          borderRadius:"0px",
          borderBottom:"1px solid #e4e6e8",
          fontWeight:400,
          color:'#8083A3',
          }}
          value={this.state.currentUserMobileNumber.number}
          onChange={this.handlePhoneInputChange}
        />  
      </Box>
      {this.state.currentUserMobileNumber.phone_error && (
          <Typography style={{color:"red",fontSize:"12px"}}>{this.state.currentUserMobileNumber.phone_error}</Typography>
        )}
      <Box className={classes.forgotPasswordBottomBlock}>
        <Grid container className={classes.saveButtonBlock} spacing={1} style={{ display:"flex", justifyContent:"center", width: "auto !important"}}>
          <Grid item xs="auto" className="custom-width">
            <Button
              type="button"
              className={classes.resendBtn}
              id="currentPasswordBtn"
              data-test-id="currentPasswordBtn"
              onClick={this.resetForm}>
              {configJSON.cancelBtnTxt}
            </Button>
          </Grid>
          <Grid item xs="auto" className="custom-width">
            <Button
              disabled={Boolean(this.state.currentUserMobileNumber.phone_error)}
              className={classes.verifyBtn}
              onClick={this.handleUpdateBasicDetails}
              data-test-id = "basicdetail"
              id="btnseven">
              {configJSON.submitBtn}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

          
  renderForgotpassword = (classes: any)=>{
    return(
            <>
              <Typography className={`${classes.passwordHeading} ${classes.cursorPointer}`}>
                {configJSON.forgotPsrdswordTxt}
              </Typography>
              <Box className={classes.emailVerificationCodeContainer}>
                <InputLabel className={classes.inputLabelTxt}>
                  {configJSON.emailTxt}
                </InputLabel>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                  <TextField
                  className={classes.inputPlaceholderTxt}
                    InputProps={{
                      classes: {
                        input: classes.verificationCodeInputField
                      }
                    }}
                    fullWidth
                    disabled
                    placeholder={configJSON.forgotPasswordEmailplaceHolder}
                    // onChange={event =>
                    //   this.handleOnChangeInputValues(event)
                    // }
                    value={this.state.currentUserEmailId}
                    id="forgetpass"
                    name = 'forgotPasswordEmail'
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.saveButtonBlock}>
                    <Button
                      type="button"
                      className={classes.resendBtn}
                      onClick={this.resetForm}
                      id="cancelpassword">
                      {configJSON.cancelBtnTxt}
                    </Button>
                    <Button
                      className={classes.verifyBtn}
                      onClick={this.handleNewEmailVerificationCode}
                      id="btneight">
                   {  this.state.loader ?  <CircularProgress size={20}  style={{color:'black'}}/> : configJSON.verifyBtnTxt}
                    </Button>
                  </Grid>
                </Grid>
                  {this.state.forgotPsswordEmailError !== '' && <Typography className={classes.errorMsg}>{this.state.forgotPsswordEmailError}</Typography>}
              </Box>
            </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.mainContainer}>
          <Typography className={classes.inputLabelTxt} style={{display:"none"}}>
            {configJSON.emailTxt}
          </Typography>
          <Box className={classes.textFieldContainer} style={{display:"none"}}>
            <Box>
              <Typography className={classes.accountDetailstextStyle}>
                {this.state.currentUserEmailId}
                <span>
                  <DoneIcon className={classes.verifyIcon} />
                </span>
              </Typography>
            </Box>
            {this.state.renderEmailView === "emailView" && (
              <Box className={classes.verifyBtnContainer}>
                <CreateIcon
                  className={classes.editIcon}
                  onClick={() => this.handleEmailView("newEmailView")}
                  id="btntwo"
                />
                <Button
                  className={classes.mobileVerifyBtn}
                  onClick={() => this.handleEmailView("verificationCodeView")}
                  id="mobileVerifyBtn">
                  {configJSON.verifyBtnTxt}
                </Button>
              </Box>
            )}
          </Box>
          {this.renderverificationCodeView(this.props.classes)}
          {this.rendernewEmailView(this.props.classes)}

          {/* <hr /> */}
          {this.renderpasswordView(this.props.classes)}

          {this.state.renderView === "editPasswordView" && (
            <>
              <Box className={classes.currentPasswordBlock}>
                <Typography className={classes.passwordHeading}>
                  {configJSON.psrdTxt}
                </Typography>

                <InputLabel
                  htmlFor="currentpassword"
                  className={`${classes.inputLabelTxt} ${
                    classes.currentPasswordTxt
                  }`}
                >
                  {configJSON.currentPassword}

                </InputLabel>
                <TextField
                  id="currentpassword"
                  type={
                    this.state.PasswordVisibilityState.currentPassword
                      ? "text"
                      : "password"
                  }
                  value={this.state.currentPassword}
                  className={classes.inputPlaceholderTxt}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.togglePasswordVisibility.bind(this,"currentPassword") }
                          id="currentpassword1"
                        >
                          {this.state.PasswordVisibilityState
                            .currentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder={configJSON.newPasswordPlaceHolder}
                  fullWidth
                  onChange={event =>
                    this.handleOnChangeInputValues(event)
                  }
                  name='currentPassword'
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.changePasswordTxtContainer}>
                    <InputLabel
                      htmlFor="password1"
                      className={classes.inputLabelTxt}
                    >
                      {configJSON.newPsrdwordTxt}
                    </InputLabel>
                    <TextField
                      id="password1"
                      type={
                        this.state.PasswordVisibilityState.newPassword
                          ? "text"
                          : "password"
                      }
                      value={this.state.newPassword}
                      className={classes.inputPlaceholderTxt}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.togglePasswordVisibility.bind(this,"newPassword") }
                              id="newPassword1"
                            >
                              {this.state.PasswordVisibilityState
                                .newPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      placeholder={configJSON.newPasswordPlaceHolder}
                      onChange={event =>
                        this.handleOnChangeInputValues(event)
                      }
                      name='newpassword'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    className={classes.changePasswordTxtContainer}
                    id="confirmPassword2"
                  >
                    <InputLabel
                      htmlFor="confirmPassword"
                      className={classes.inputLabelTxt}
                    >
                      {configJSON.confirmPasswordTxt}
                    </InputLabel>
                    <TextField
                      id="confirmPassword"
                      type={
                        this.state.PasswordVisibilityState
                          .confirmPasswordVisible
                          ? "text"
                          : "password"
                      }
                      value={this.state.confirmPassword}
                      className={classes.inputPlaceholderTxt}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.togglePasswordVisibility.bind(this,"confirmPasswordVisible")
                              }
                              id="confirmPassword1"
                            >
                              {this.state.PasswordVisibilityState
                                .confirmPasswordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      placeholder={configJSON.newPasswordPlaceHolder}
                      onChange={event =>
                        this.handleOnChangeInputValues(event)
                      }
                      name='confirmpassword'
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container className={classes.forgotPasswordBottomBlock}>
                <Grid item xs={12} sm={12} md="auto" style={{marginTop: "10px"}}>
                  <Typography
                    className={`${classes.forgotPasswordBtn} ${classes.cursorPointer}`}
                    onClick={() => this.handleRenderView("forgotPasswordView")}
                    id="forgotPasswordBtn"
                  >
                    {configJSON.forgotPsrdswordTxt}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md="auto" >

                <Grid container className={classes.saveButtonBlock} spacing={1}>
                  <Grid item xs="auto" className="custom-width">
                  <Button
                    type="button"
                    className={classes.resendBtn}
                    id="currentPasswordBtn"
                    onClick={this.resetpasswordform} >
                    {configJSON.cancelBtnTxt}
                  </Button></Grid>
                  <Grid item xs="auto" className="custom-width">
                  <Button
                    className={classes.verifyBtn}
                    onClick={this.handleChangePasswordWithCurrentPassword}
                    id="btnseven">
                    {configJSON.verifyBtnTxt}
                  </Button>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
              {this.state.passwordMismatchError !== '' && <Typography className={classes.errorMsg}>{this.state.passwordMismatchError}</Typography>}
            </>
          )}
          {this.state.renderView === "forgotPasswordView" && (
           this.renderForgotpassword(this.props.classes)
          )}
          {this.state.renderView === "emailVerificationView" && (
            <>
              <Box className={classes.emailVerificationCodeContainer}>
                <InputLabel className={classes.inputLabelTxt}>
                  {configJSON.verificationCodeTxt}
                </InputLabel>
                <Grid container className={classes.verifyInputandBtnConatiner}>
                  <Grid item xs={12} sm={6}>

                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.verificationCodeInputField
                      }
                    }}
                    fullWidth
                    placeholder={configJSON.verificationCodePlaceHolder}
                    onChange={event =>
                      this.handleOnChangeInputValues(event)
                    }
                    id="emailverify"
                    name='emailverify'
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.saveButtonBlock}>
                    <Button
                      type="button"
                      className={classes.resendBtn}
                      onClick={this.handleEmailVerify}>
                      {configJSON.resendBtnTxt}
                    </Button>
                    <Button
                      className={classes.verifyBtn}
                      onClick={this.handleEmailView.bind(this,"")} >
                      {configJSON.verifyBtnTxt}
                    </Button>
                  </Grid>
                </Grid>
                {this.state.verificationCodeErrorMsg !== '' && <Typography className={classes.errorMsg}>{this.state.verificationCodeErrorMsg}</Typography>}
              </Box>
            </>
          )}
          {this.state.renderView === "forgotEmailVerificationView" && (
            <>
              <Box className={classes.emailVerificationCodeContainer} id='forgotemailVerification'>
                <InputLabel className={classes.inputLabelTxt}>
                  {configJSON.verificationCodeTxt}
                </InputLabel>
                <Box className={classes.verifyInputandBtnConatiner}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.verificationCodeInputField
                      }
                    }}
                    fullWidth
                    placeholder={configJSON.verificationCodePlaceHolder}
                    onChange={event =>
                      this.handleOnChangeInputValues(event)
                    }
                    name='emailverify'
                  />
                  <Box className={classes.saveButtonBlock}>
                    <Button type="button" className={classes.resendBtn}
                    >
                      {configJSON.resendBtnTxt}
                    </Button>
                    <Button
                      className={classes.verifyBtn}
                      onClick={ this.handleRenderView.bind(this,"changePasswordEmailView")
                      }
                      id="verifyBtn">
                      {configJSON.verifyBtnTxt}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {this.changePasswordEmailView(this.props.classes)}
          <hr />
          {this.state.renderMobileview ==="mobileView" &&(
            <Box className={classes.textFieldContainer}>
              <Box>
                <InputLabel className={classes.inputLabelTxt}>
                  {configJSON.mobileTxt}
                </InputLabel>
                <Typography className={classes.accountDetailstextStyle}>
                { this.maskPhoneNumber(this.state.currentUserMobileNumber.number)}
                </Typography>
              </Box>
              <CreateIcon className={classes.editIcon}   
                onClick={() => this.handleMobileView("editMobileNoView")}
                id="btnForMobile"
                data-test-id="btnForMobile"/>
            </Box>
          )}
          {this.state.renderMobileview === "editMobileNoView" && this.renderEditMobileNoView(this.props.classes)}

        </Box>

        <CustomSnackbar
          snackBarStyle={classes.snackBarStyle}
          message="Your Password changed successfully"
          isOpen={this.state.passwordChangedWithCurrentPassword}
          onCloseState = {() => this.handleCustomSnackBar('passwordChangedWithCurrentPassword')}
        />

        <CustomSnackbar
          snackBarStyle={classes.snackBarStyle}
          message="Your Email is verified successfully"
          isOpen={this.state.otpVerificationFlag}
          onCloseState = {() => this.handleCustomSnackBar('otpVerificationFlag')}
        />
        <CustomSnackbar
          snackBarStyle={classes.snackBarStyle}
          message="Your Password is changed successfully"
          isOpen={this.state.passwordChangedWithOtpStatus}
          onCloseState = {() => this.handleCustomSnackBar('passwordChangedWithOtpStatus')}
        />
        <CustomSnackbar
          snackBarStyle={classes.snackBarStyle}
          message="Your Mobile Number is changed successfully"
          isOpen={this.state.mobileChangedWithCurrentMobile}
          data-test-id="snackbar"
          onCloseState = {() => this.handleCustomSnackBar('mobileChangedWithCurrentMobile')}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  mainContainer: {
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 20,
    marginBottom: "200px"
  },
  verifyIcon: {
    background: "#EC952D",
    borderRadius: "50%",
    color: "#fff",
    fontSize: 20,
    fontWeight: 700
  },
  accountDetailstextStyle: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#8083A3",
    marginTop: 10
  },
  editIcon: {
    color: "#EC952D",
    padding: 10,
    borderRadius: "50%"
  },
  inputLabelTxt: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
    '& .MuiInputBase-input':{
      fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    }
  },
  inputPlaceholderTxt: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    '& .MuiInputBase-input':{
      fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
    }
  },
  mobileVerifyBtn: {
    width: "100px",
    "& .MuiButton-label": {
      width: "unset",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      padding: 7,
      minWidth: "80px"
    }
  },
  textFieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  verifyBtnContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "13rem"
  },
  emailVerificationCodeContainer: {
    marginTop: 30
  },
  verifyInputandBtnConatiner: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    maxWidth: 700
  },
  verifyBtn: {
    cursor:'pointer',
    minWidth: '140px',
    borderRadius: '23px',
    padding: '9px 30px',
    fontSize: '14px',
    fontWeight:600,
    fontFamily:'Inter',
    margin: '0 15px',
    textTransform:'none' as const,
    height:'42px',
    color:'#f2f2f2',
    backgroundColor: "#ec952d",
    '&:hover': {
      backgroundColor: "#ec952d",
    },
   },
  resendBtn: {
      fontFamily: "Inter",
      minWidth: '140px',
      borderRadius: '20px',
      background: "#f2f2f2",
      padding: '10px 30px',
      color: '#000',
      fontSize: '14px',
      margin: '0 8px',
      textTransform:'none' as const,
      height:'42px',
    
    '&:hover': {
      backgroundColor: "#f2f2f2",
    },
  },
  verificationCodeInputField: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#222222",
    width: "300px"
  },
  changePasswordTxtContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column" as "column",
    marginTop: 30
  },
  currentPasswordBlock: {
    marginTop: 30
  },
  passwordHeading: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "18px",
    color: "#222222",
    marginTop: 30,
  },
  cursorPointer:{
    cursor:'pointer'
  },
  forgotPasswordBtn: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    textDecoration: "underline",
    color: "#EC952D",
    margintTop: "15px",
  },
  currentPasswordTxt: {
    marginTop: 20
  },
  forgotPasswordBottomBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  changePasswordBottomBlock: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "1.5rem"
  },
  saveButtonBlock: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  saveButtonBlock1: {
    width: "auto !important"
  },
  snackBarStyle: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#16A700",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25098)",
    borderRadius: "32px"
  },
  errorMsg:{
    color: '#D63301',
    fontSize:"14px",
    fontFamily:"Inter",
    marginTop:20
  }
});

export const AccountDetailsStyle = withStyles(styles)(AccountDetails);
// Customizable Area End
