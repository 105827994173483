import React from "react";
// Customizable Area Start
import {
    Box, Typography, Button,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import FavLancer from "../../../components/src/FavLancer.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";

// Customizable Area End

import FavouritesFreelancerController,
{ Props, configJSON }
    from "./FavouritesFreelancerController.web";

export default class FavouritesFreelancer extends FavouritesFreelancerController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        // Customizable Area Start
        const { favLancerCards, isLoading, totalProjects } = this.state;
        

        // Customizable Area Start
        return (

            // Customizable Area Start
            <>
                <style>
                    {`
                        @media(max-width: 767px) {
                            .resMainDiv {
                                max-width: 90% !important;
                            }
                        }
                    `}
                </style>
                <Sponserheader navigation={this.props.navigation} id={""} route={"favourites"} />
                <div style={webStyle.mainDiv} className="resMainDiv">
                    <Box style={webStyle.favBox}>
                        <Typography style={webStyle.favText} >
                            {configJSON.headerFavouriteText}
                        </Typography>
                    </Box>
                    <span style={webStyle.popUpMsg}>{configJSON.popUpTextMessage}</span>
                    <Box style={{ marginTop: "10px" }}>
                        <ProjectFilterWeb
                            onSearch={this.spocorSearchHandler}
                            onFilter={this.spocorFilterHandler}
                            onCancelFilter={this.spocorCancelFilterHandler}
                            activeTabColor="#00A7A2"
                            sortInOutside={true}
                            disableSort={true}
                        />
                    </Box>

                    <Box marginTop={1.5} >
                        {favLancerCards?.length === 0 && !isLoading && (
                            <Box sx={{ mt: 4 }}>
                                <Typography>No Favourite Found</Typography>
                            </Box>
                        )}

                        {favLancerCards?.map((item: any, index: number) => (

                            <FavLancer
                                favcards={item}
                                key={index}
                                navigation={(id: any) => this.navigateToProfilepage(id)}
                                instance={this}
                                removeFavourite={this.handleRemoveFavourites} />
                        ))}

                    </Box>
                    <Box style={webStyle.buttonbox}>
                        {isLoading && (
                            <Box>
                                <CircularProgress />
                            </Box>
                        )}
                        {totalProjects > favLancerCards?.length && !isLoading ? (
                            <Button
                                data-test-id="showMore"
                                style={{ width: '137px', borderRadius: '30px', backgroundColor: '#F2F2F2', textTransform: 'capitalize' }}
                                variant="contained"
                                endIcon={<ChevronRightSharpIcon />}
                                onClick={this.handleProjectLoadMore}
                            >
                                Show More
                            </Button>
                        ) : null}
                    </Box>
                </div>
                <Sponserfooter navigation={undefined} id={""} />
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const webStyle = {
    buttonbox: {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    abc: {
        minWidth: 120,
    } as const,
    sortText: {
        marginBottom: '0px',
        marginLeft: "22px"
    } as const,

    favText: {
        marginTop: "5px",
        fontWeight: "bold",
        fontSize: "28px",
        color: "#222"
    } as const,
    favBox: {
        textAlign: "left",
    } as const,
    mainDiv: {
        maxWidth: "80%",
        margin: "0 auto"
    },
    sortDiv: {
        display: 'flex',
        alignItems: 'center'
    } as const,
    popUpMsg: {
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: "14px"
    } as const,

};
// Customizable Area End
