import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment, Box, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { IConversation } from "../../../blocks/chat/src/ChatController";
import {avatarImg} from '../../../blocks/chat/src/assets'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    conversation_section: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    conversation_search: {
      minHeight: "80px",
      borderBottom: "1px solid #e4e6e8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    searchInputContainer: {
      background: "white",
      borderRadius: "50px",
      padding:"3px 10px",
      border: "1px solid #e4e6e8",
      "& ::placeholder": {
        color: "gray",
        fontSize:"13px"
      },
    },
    search__button: {
      color: "white",
      height: "40px",
      width: "40px",
      "&:hover": {
        background: "inherit",
      },
    },
    conversation_list_container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
      flexGrow: 1,
      marginBottom:"20px"
    },
    conversation_item_container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "15px 15px",
      cursor: "pointer",
      transition: ".5s",
      position:"relative",
      gap: "10px",
      "&:hover": {
        background: "white",
      },

      borderBottom: "1px solid #e4e6e8",
    },
    conversation_item_user_img_container: {
      height: "50px",
      width: "50px",
      position: "relative",
    },
    conversation_item_user_img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
    conversation_item_user_active: {
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      position: "absolute",
      right: "0px",
      bottom: "0px",
      background: "green",
    },
    conversation_item_user_details: {
      display: "flex",
      flexDirection: "column",
      gap: "3px",
    },
    conversation_item_user_name: {
      fontSize: "13px",
      fontWeight: "bolder",
    },
    conversation_item_chat_description: {
      fontSize: "11px",
    },
    conversation_active_item: {
      background: "white",
    },
    unread_count:{
      position:"absolute",
      right:"10px",
      top:"40%",
      height:"20px",
      width:"20px",
      background:"red",
      borderRadius:"50%",
      fontSize:"10px",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      color:"white"

    }
  })
);
interface Props {
  selectedConversation: IConversation | null;
  conversationList: IConversation[];
  handleSelectConversation: (conv: IConversation) => void;
  color?:string;
  searchText:string;
  setSearchText:(value:string)=>void;
  isSponsor?:boolean;
}

const ConversationList: React.FC<Props> = ({
  selectedConversation,
  conversationList,
  handleSelectConversation,
  searchText,
  setSearchText,
  color,
  isSponsor
}) => {

  const activeColor = color || "#EC952D";
  const classes = useStyles();

  return (
    <div className={classes.conversation_section}>
      <div className={classes.conversation_search}>
        <div style={{ width: "80%" }}>
          <InputBase
            className={classes.searchInputContainer}
            placeholder={`Search ${isSponsor?"freelancer":"sponsor"}, chats`}
            value={searchText}
            onChange={(e)=>setSearchText(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton style={{background:activeColor}} className={classes.search__button}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      </div>
      <div
        className={`${classes.conversation_list_container} custom_scrollbar`}
      >{
        !conversationList.length 
        ? <Typography variant="h6" style={{fontSize: '1rem', paddingTop: '20px'}}>No Results Found</Typography> 
        : conversationList.map((item, index) => {
           const participent=item?.attributes?.participants[0];
           const isAdmin=participent?.userType==="Admin";
           return(
            <div
              key={item.id}
              onClick={() => handleSelectConversation(item)}
              className={`${classes.conversation_item_container} ${
                item?.id === selectedConversation?.id &&
                classes.conversation_active_item
              }`}
            >
              <Box className={classes.conversation_item_user_img_container}>
                {
                  item?.attributes?.participants[0]?.profile_image?.url?(
                    <img
                    className={classes.conversation_item_user_img}
                    src={item?.attributes?.participants[0]?.profile_image?.url || avatarImg}
                    alt={"User Image"}
                  />
                  ):(
                    <AccountCircleIcon className={classes.conversation_item_user_img}/>
                  )
                }
              <div className={classes.conversation_item_user_active} style={{backgroundColor: item?.attributes?.participants[0]?.availability ? "green" : "orange"}}/>
              </Box>
              <Box className={classes.conversation_item_user_details}>
                <Typography
                  className={classes.conversation_item_user_name}
                  component={"h3"}
                >
                  {isAdmin?"Admin":item?.attributes?.participants[0]?.full_name}
                </Typography>
                <Typography
                  className={classes.conversation_item_chat_description}
                  component="p"
                >
                  {
                    item?.id !== selectedConversation?.id && item?.isEdited ? (
                      <Typography style={{fontWeight:"bolder"}} className={classes.conversation_item_chat_description}>Message Edited</Typography>
                    ):(
                      <>
                        {isAdmin?"Lancerscape Admin":item?.attributes?.project_name}
                      </>
                    )
                  }
                </Typography>
                {
                   item?.id !== selectedConversation?.id && item?.new_message_count && item?.new_message_count>0 ? (
                    <Box className={classes.unread_count}>{item.new_message_count}</Box>
                   ):null
                }
              </Box>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ConversationList;
