import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import {toast} from 'react-toastify'
import * as Yup from "yup"
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { CURRENT_STATE_KEY } from "../../rolesandpermissions/src/AuthProviderController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation:any;
}

export interface S {
  enableField: boolean;
  // Customizable Area Start
  email: string;
  password: any;
  FieldError: any;
  error: boolean;
  emailError: any;
  passwordError: any;
  errorEmailSelect: any;
  errorPwdSelect: any;
  email_not_found:boolean;
  facebookSignupLoading:boolean;
  signinVisible:boolean;
  signinLoading:boolean;
  signinErrors:any;
  linkedinSignInLoading:boolean;
  googleSignInLoading:boolean;
  openDialog:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SignInController extends BlockComponent<
Props, S, SS
> {

    // Customizable Area Start
    LoginCallId: any
    facebookApiCallId:string="";
    linkedinLoginApiCallId:string="";
    googleSigninApiCallId:string="";
    getGoogleProfileInfoApiCallId:string="";
   // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

     // Customizable Area Start

     this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.LoginUserMessage),
      ];

      this.state = {
        enableField: false,
        // Customizable Area Start
        email: '',
        password: '',
        FieldError: '',
        error: false,
        emailError: '',
        passwordError: '',
        errorEmailSelect: false,
        errorPwdSelect: false,
        email_not_found:false,
        facebookSignupLoading:false,
        signinVisible:false,
        signinLoading:false,
        signinErrors:{},
        linkedinSignInLoading:false,
        googleSignInLoading:false,
        openDialog:false,
        // Customizable Area End
      };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
       // Customizable Area Start

   // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') || "";
    this.setState({email:email})
   // Customizable Area End
  }

   // Customizable Area Start
   async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user:IProfile = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      if(user){
        this.handleUserAutoNavigate(user?.attributes?.role_name);
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.LoginCallId) {
        this.handlePostApi(responseJson);
      }else if(apiRequestCallId===this.facebookApiCallId){
        this.handleFacebookSigninApiPayload(responseJson)
     }else if(apiRequestCallId===this.linkedinLoginApiCallId){
        this.setState({linkedinSignInLoading:false});
        this.handleFacebookSigninApiPayload(responseJson)
     }else if(apiRequestCallId===this.googleSigninApiCallId){
        this.setState({googleSignInLoading:false});
        this.handleFacebookSigninApiPayload(responseJson)
     }else if(apiRequestCallId===this.getGoogleProfileInfoApiCallId){
        this.handleGoogleProfileInfoApiResponse(responseJson)
     }
     
    }
  // Customizable Area End
  }

  handleGoogleProfileInfoApiResponse=async(responseJson:any)=>{
    const lastName=responseJson?.family_name || "";
    const firstName=responseJson?.given_name || "";
    const data= {
      profilephoto: null,
      last_name: lastName,
      first_name: firstName,
      gender: "",
      country: "",
      time_zone: "",
      city: "",
      state: "",
      mobileNumber: "",
      address: "",
      address_line_2: "",
      postal_code: "",
      date_of_birth: "",
    };
    await setStorageData("Basicdetailarray",JSON.stringify(data));

  }
  handleUserAutoNavigate=async(roleName:string)=>{
    if(!roleName){
      return;
    }
    let updatedRoleName=roleName;
    if(roleName?.toLowerCase()?.includes("both")){
      updatedRoleName=await getStorageData(CURRENT_STATE_KEY) || "Sponsor";
    }
    this.handleSigninNavigate({
      meta:{
        role_name:updatedRoleName
      }
    })
  }
  handleFacebookSigninApiPayload=async(responseJson:any)=>{
    this.setState({facebookSignupLoading:false})
    if(responseJson?.data && responseJson?.data?.id){
      await removeStorageData("currentState")
      localStorage.setItem("loginSuccessToken", responseJson?.meta?.token);
      localStorage.setItem('userID', responseJson?.meta?.id)
      this.sendResetProfileInfoMessage();
      this.handleSigninNavigate(responseJson);
      return;
    }
    
    if(responseJson?.errors?.length>0){
      const key=Object.keys(responseJson?.errors[0])[0];
      toast.error(responseJson?.errors[0][key])
    }
  }
  SignUpOnclick=()=>{
    this.props.navigation.navigate("SignUp") 
  }

  handlePostApi = async (responseJson: any) => {

    this.setState({signinLoading:false})

    if (!responseJson.errors) {
      localStorage.setItem("loginSuccessToken", responseJson?.meta?.token);
      await setStorageData('userID', responseJson?.meta?.id)
      await setStorageData('subRoleName', responseJson.meta.sub_role_name)
      await removeStorageData("currentState")
      this.sendResetProfileInfoMessage();
      this.handleSigninNavigate(responseJson);
      
    }
    else {
        if(responseJson?.errors[0]?.failed_login){
        toast.error(responseJson?.errors[0]?.failed_login)
      }
    }
  }
 handleSigninNavigate=async(responseJson:any)=>{
  const roleName=responseJson?.meta?.role_name as string;
  const isSponsor=roleName?.toLowerCase().includes("sponsor") || roleName?.toLowerCase().includes("both");
  const isFreelancer=roleName?.toLowerCase().includes("freelancer");
  const redirectEndpoint = await getStorageData("redirect_endpoint");
  if(isSponsor){
    if(redirectEndpoint) {
      await removeStorageData("redirect_endpoint")
      window.location.href = window.location.origin + redirectEndpoint;
    } else {
      this.props.navigation.navigate('SponsorDashboard');
    }
  }
  else if(isFreelancer){
    if(redirectEndpoint) {
      await removeStorageData("redirect_endpoint")
      window.location.href = window.location.origin + redirectEndpoint;
    } else {
      this.props.navigation.navigate('FreelancerDashboard');
    }
  }else{
    this.props.navigation.navigate('Accountinfo');
  }
 }

  sendResetProfileInfoMessage=()=>{
    const msg: Message = new Message(getName(MessageEnum.ResetProfileMessage));
    this.send(msg)
  }

 handleLinkedinLoginResponse=(loginCode:string)=>{
    this.handleLoginWithLinkedin(loginCode)

 }

 handleLoginWithLinkedin=(accessToken:string)=>{
  this.setState({linkedinSignInLoading:true})
  const header = {
    "Content-Type": "application/json",
  };
  const httpBody={
      data:{
        type:"linkedin",
        action:"signin",
        attributes:{
          access_token:accessToken
        }
      }
   }
   const requestMessage=CreateRestApiMessage({
    apiUrl:`bx_block_login/social_logins`,
    body:JSON.stringify(httpBody),
    header:header,
    method:"POST"
   })
  this.linkedinLoginApiCallId=requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }
 handleLinkedinLoginError=(error:{
  error:string,
  errorMessage:string
 })=>{
  if(error?.error!=="user_closed_popup"){
    toast.error(error?.errorMessage)
  }
 }
  handleFacebookLoginResponse=(res:any)=>{
    if(res?.accessToken){
      this.handleFacebookSignin(res?.accessToken);
    }
 }
 onGoogleLoginSuccess=(res:{access_token:string})=>{
  this.setState({googleSignInLoading:true})
  this.retriveGoogleProfileResponse(res?.access_token);
  const header = {
    "Content-Type": "application/json",
  };
  const httpBody={
      data:{
        type:"google",
        action:"signin",
        attributes:{
          access_token:res?.access_token
        }
      }
   }
   const requestMessage=CreateRestApiMessage({
    apiUrl:`bx_block_login/social_logins`,
    body:JSON.stringify(httpBody),
    header:header,
    method:"POST"
   })
  this.googleSigninApiCallId=requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 retriveGoogleProfileResponse=(access_token:string)=>{
  const header = {
    "Authorization":`Bearer ${access_token}`
  };
   const requestMessage=CreateRestApiMessage({
    apiUrl:configJSON.googleProfileApiEndPoint,
    body:null,
    header:header,
    method:"GET"
   })
  this.getGoogleProfileInfoApiCallId=requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 onGoogleLoginError=(error:any)=>{
  runEngine.debugLog("Google Error",error)
 }

 navigateToForgotPassword=()=>{
    this.props.navigation.history.push(`/ForgetPassword?email=${this.state.email}`)
 }

 handleFacebookSignin= (accessToken:string) => {

   let token = localStorage.getItem("loginSuccessToken")
   this.setState({facebookSignupLoading:true})
   const header = {
     "Content-Type": "application/json",
     "token": token
   };
   const httpBody={
       data:{
         type:"facebook",
         action:"signin",
         attributes:{
           access_token:accessToken
         }
       }
    }
   const requestMsg = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   this.facebookApiCallId = requestMsg.messageId;
   requestMsg.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_login/social_logins`
   );

   requestMsg.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMsg.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(httpBody)
   );
   requestMsg.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
   );
   runEngine.sendMessage(requestMsg.id, requestMsg);
 }
handleEmailChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({ email: event.target.value, emailError: '', errorEmailSelect: '',
  signinErrors:{...this.state.signinErrors,email:""}
   })
}

handlePasswordChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({password: event.target.value, passwordError: '',
  signinErrors:{...this.state.signinErrors,password:""}
   })
  }

handleValidate=(e:any)=>{
  e?.preventDefault();
  const {email,password}=this.state;
  let userSchema = Yup.object({
    email:Yup.string().required(configJSON.emailRequired).email(configJSON.invalidEmail),
    password:Yup.string().required(configJSON.passwordRequired)
  });

  userSchema.validate({
    password:password,
    email:email
 }, { abortEarly: false }).then((value)=>{
      this.setState({signinErrors:{}})
      this.loginSubmit();
 }).catch((err)=>{
   let newError:any={};
   err.inner?.forEach((item:any)=>{
        newError[item.path]=item.message
   })
  if(!password){
    newError.password= configJSON.passwordRequired
  }
   this.setState({signinErrors:newError})
 })


}

   loginSubmit = async () => {
    this.setState({signinLoading:true})
    const header = {
      "Content-Type": "application/json",
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `log_in`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }

  handleSigninVisibility=()=>{
    this.setState({signinVisible:!this.state.signinVisible})
 }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

   // Customizable Area End


}
