import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import '../assets/css/notification.web.css';
import { Link } from "react-router-dom";
// Customizable Area End
import NotificationController, { Props } from "./NotificationController.web";

export default class Notificationbox extends NotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  LancerNavigationButtons = (notificationType: string | null, url: string | null,projectId?:string) => {
    if(notificationType==="New project created"){
      return (
        <Link target="_blank" to={`/QuoteProjectDetails/${projectId}`}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Project
          </span>
        </Link>)
    }
    if (notificationType === "Invitation") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{
            ...webStyle.acceptButton,
            color: "#333333",
            background: "#f2f2f2",
            fontWeight: 700,
            padding: "10px 25px"
          }}>
            View Details
          </span>
        </Link>
      )
    }
    if (notificationType === "Updates") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Project
          </span>
        </Link>)

    }
    if (notificationType === "Bid") {
      const newUrl = `/QuoteDetails/${url?.split("/")[1]}`
      return (
        <>
          <Link target="_blank" to={newUrl}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Quote detail
          </span>
        </Link>
        </>

      )
    }
    if (notificationType === "Certificate") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Project
          </span>
        </Link>

      )
    }
    if (notificationType === "Disputes") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Project
          </span>
        </Link>
      )
    }
    if (notificationType === "Message") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{ ...webStyle.acceptButton,color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer" }}>
            Message
          </span>
        </Link>
      )
    }
    if (notificationType == "Proposals") {
      const newUrl = `/QuoteDetails/${url?.split("/")[1]}`
      return (
        <Link target="_blank" to={`${newUrl}`}>
          <span style={{ ...webStyle.acceptButton,color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer" }}>
            View Quote
          </span>
        </Link>
      )
    }
    if (notificationType === "Certificate Request Update") {
      return (
        <Link target="_blank" to={`/CompletedPrjSummary/${projectId}`}>
          <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "rgb(236, 149, 45)", cursor: "pointer"}}>
            Go to Project
          </span>
        </Link>
      )
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    // Customizable Area End

    return (
      // Customizable Area Start
      <div className="notification-section">
        <div className="notification-arrow"></div>
        <Container className="notification-container" style={{padding:"0px",margin:"0px"}}>
        

          <Box style={webStyle.main_Boxa} className="notall">
          {this.state.loading ?(
               <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , minHeight:'60px'}}>
               <CircularProgress /></Box>
            ) : (
            <Box>
              {this.state.notificationdata &&  this.state.notificationdata.length > 0 && this.state.notificationdata.slice(0, 3).map((data:any, index:any)=>{

             return (
              <Box key={index} style={{ marginTop: "1px" }}>
                 <Card
                   square={true}
                   style={{
                     borderTopLeftRadius: "10px",
                     borderTopRightRadius: "10px",
                   }}
                 >
                   <CardContent style={{ paddingBottom: '15px' }}>
                     <Box style={{ display: 'flex', alignItems: "flex-start", gap: "10px" }}>
                       <Box style={webStyle.image_Itemu}>
                         <Avatar src={data.attributes.profile_photo?.url} />
                       </Box>
                       <Box id="lancer" >
                         <Typography id="lancernotification" className="htmlcontent" style={
                              data?.attributes.is_read?{
                                 flexGrow: 1, 
                                 marginBottom: "10px",
                                 fontSize: '12px'
                                }:{
                                  flexGrow: 1, 
                                  marginBottom: "10px",
                                  fontSize: '13px',
                                  fontWeight:"bolder"
                               }
                          }
                           dangerouslySetInnerHTML={{ __html: data.attributes.content }}
                         />
                           {this.LancerNavigationButtons(data.attributes.notification_for, data.attributes.link,data.attributes.project_id)}
                         
                       </Box>
                       <Typography style={{ fontSize: '10px', display: "block ruby" ,whiteSpace: "nowrap" }}>{data.attributes.created_at}</Typography>
                     </Box>
                   </CardContent>
                 </Card>
              </Box>
            )})}
             {this.state.notificationdata && this.state.notificationdata.length == 0 &&
                    <Box id="ttt" style={{ marginTop: "1px" }}>
                      <Card
                        square={true}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <CardContent id="cc" style={{ paddingBottom: '15px' }}>
                          <Box style={{ display: "flex", alignItems: "center" , justifyContent:"center" }}>
                              No notifications found
                          </Box>
                        </CardContent>

                      </Card>
                    </Box>}
            </Box>
            )}
              <Box style={{ marginTop: "1px" }}>
                <Card
                id="cardbox"
                  square={true} 
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                >
                 {this.state?.notificationdata?.length > 0  && <CardContent id="contb">
                  <CardActions id="btnnon" onClick={() => {this.props.navigation.navigate('Notificationlancer')}} >

                      <Typography id="textp" style={{ fontWeight: "bold", fontSize: '12px',cursor:'pointer' }}>
                        View All Notifications{"  >"}
                      </Typography>

                    </CardActions>
                  </CardContent>}
                </Card>
              </Box>
            </Box>
          

        </Container>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  main_Boxa: {
    width: "28rem",
    maxHeight: "435px",
    borderRadius:"12px",
  },
 
  image_Itemu: {
    // marginRight: "14px",
    // flex:"1"
  },
  acceptButton: {
    borderRadius: "18px",
    textTransform: "none",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
    marginTop : "5px",  
    padding : "7px 13px"
} as const,
 
};

// Customizable Area End
