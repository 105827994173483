import React from "react";
import { Box, TextField, Popover ,InputAdornment} from "@material-ui/core";
import NegotiationItemController from "./NegotiationItemController.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCostDisplayElement } from "./MyBidNegotition.web";
export default class NegotiationItem extends NegotiationItemController {

    render() {
        const {negotiation:negotiationItem,isFixedPrice,isLastItem,profileCurrency}=this.props;
        const {bidAmount,bidAmountError,sendNewBidLoading,rejectBidLoading,acceptBidLoading}=this.state;
        const isFromClientEnd = this.isNegotiationsFromSponsor(negotiationItem?.role);
        const isRejected = negotiationItem?.status === "rejected";
        const isAccepted = negotiationItem?.status === "accept";
        const isSentToSponsor = negotiationItem?.status === "sent_to_sponsor";
        const isFromSponsor=negotiationItem?.status==="sent_to_freelancer";
        return (
          <>
            <Box className="aboutTheSponsor_Box project_common_container">
              <Box className="bid_negititation">
                <Box className="price_negotiation_outer_wrapper">
                  <Box className="price_negotiation">
                    {isFromClientEnd ? "New Quote from Client" : "New Quote Sent by You"}
                  </Box>
                  <Box style={{color:"black",display:"flex",alignItems:"center",gap:"5px"}}  className="price_cantainer">
                    {getCostDisplayElement(negotiationItem?.currency_conversion?.freelancer,negotiationItem?.currency_conversion?.sponsor,!isFixedPrice)}
                  </Box>
                </Box>
      
                <Box className="price_negotiation_outer_wrapper">
                  <Box className="price_negotiation">
                    {isFromClientEnd ? "Received On" : "Sent On"}
                  </Box>
                  <Box style={{color:"black"}}  className="price_cantainer">{this.formateDate(negotiationItem?.created_at)}</Box>
                </Box>
                {isRejected && (
                  <Box className="price_negotiation_outer_wrapper">
                    <Box className="price_negotiation">Rejected on</Box>
                    <Box style={{color:"black"}}  className="price_cantainer">{this.formateDate(negotiationItem?.updated_at)}</Box>
                  </Box>
                )}
              </Box>
              {isRejected && (
                <button className="btn-common-color reject_btn2" type="submit">
                  Rejected
                </button>
              )}
              {isAccepted && (
                <button
                  className="btn-common-color accept_btn"
                  type="submit"
                  data-testid="acceptNegotion"
                >
                  Accepted
                </button>
              )}
              {isFromSponsor && isFromClientEnd && (
                <Box className="resActionBtn">
                  <div>
                    <button
                      data-testid="sendNewBid"
                      className="btn-common-color sendNewBid"
                      type="submit"
                      onClick={this.handleTooltipOpen}
                    >
                      Send New Quote
                    </button>
      
                    <Popover
                      id="1"
                      open={this.state.isOpen}
                      anchorPosition={{ top: 50, left: 680 }}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleTooltipClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box className="input_bid_amount">
                        <TextField 
                          placeholder="Enter New Quote"
                          value={bidAmount}
                          onChange={this.handleChangeAmount}
                          error={Boolean(bidAmountError)}
                          helperText={bidAmountError}
                          data-testid="amountEt"
                          InputProps={{
                            startAdornment:(
                              <InputAdornment position="start">{profileCurrency}</InputAdornment>
                            )
                          }}
                          
                           />
                        <button
                          className="btn-common-color1 accept_btn"
                          type="submit"
                          onClick={this.sendNewBid}
                          data-testid="sendBid"
                        >
                           {
                            sendNewBidLoading?<CircularProgress size={20}/>:"Send"
                          }
                          
                        </button>
                      </Box>
                    </Popover>
                  </div>
      
                  <button
                    className="btn-common-color reject_btn"
                    type="submit"
                    onClick={()=>this.rejectBid(negotiationItem?.id?.toString())}
                    data-testid="rejectNegotion"
                  >
                     {
                        rejectBidLoading?<CircularProgress size={20}/>:"Reject"
                     }
                  </button>
                  <button
                    className="btn-common-color accept_btn"
                    type="submit"
                    onClick={()=>this.acceptBid(negotiationItem?.id?.toString())}
                    data-testid="acceptNegotion"
                  >
                     {
                        acceptBidLoading?<CircularProgress size={20}/>:"Accept"
                     }
                  </button>
                </Box>
              )}
              {isSentToSponsor && !isFromClientEnd && (
                <button className="Sent_to_Sponsor_btn" type="submit">
                  Sent to Sponsor
                </button>
              )}
            </Box>
            {!isLastItem && <Box className="project_container1"></Box>}
          </>
        );
    }
}
