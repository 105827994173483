import React from "react";

// Customizable Area Start
import { deleteIcon, editIcon , download} from "./assets";
import {
  Typography,
  InputLabel,
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  IconButton
} from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles, createTheme } from "@material-ui/core/styles";
import CreateUniversityModal from "../../../components/src/utill/CreateUniversityModal.web";
// Customizable Area End
import { Formik, Field, Form, ErrorMessage } from "formik";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../assets/css/Basic_details_customizable.css";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area Start

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
  AddWorkExpValidation,
  AddEduValidation
} from "./Customisableuserprofiles2Controller";
import CustomSelect from "../../../components/src/utill/CustomSelect.web";

export default class PersonalInfoPage extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderSkills = (leftSkill: any) => {
    const { classes } = this.props;
    const skillsWithNoSkillOption = leftSkill.length > 0 
  ? [...leftSkill]
  : [{id: 3, name: "No skills left", selected: false }];
    return <Box className={classes.mainContainer} >
      <Typography className={classes.skillsHeading}>
        {configJSON.skillsTitile}
      </Typography>

      <Grid container className={classes.skillsTxtFieldContainer}>
        <Grid item xs={12} className={classes.subContainer}>
          <div className="form-group">
            <SearchIcon style={{ marginTop: '30px' , marginRight:"15px" }} />
            </div>
            <CustomSelect
              value={""}
              data-testid='selectedSkill'
              onChange={this.selectNewElement}
              label=  {configJSON.primarySkills}
              renderItem={(item, props) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  {...props}
                >
                  {item.name}
                </MenuItem>
              )}
              name="selectedSkill"
              tabIndex={3}
              data={skillsWithNoSkillOption}
              placeholder=  {leftSkill.length == 0 ? configJSON.primaryNoSkillsPlaceHoldertxt : configJSON.primarySkillsPlaceHoldertxt}
            />
     
          <Box className={classes.skillsSubContainer}>

            {this.getSkillsListing && this.getSkillsListing.map((skill, index) => (
              <Box className={classes.skillWrapper} key={skill.id}>
                <Typography className={classes.addedSkillName}>
                  {skill.name}
                </Typography>
                <ClearIcon
                  className={classes.crossIcon}
                  onClick={this.deleteSelected.bind(this, skill.id)}
                  data-testid={`deleteSkill-${index}`}
                />
              </Box>
            ))}
          </Box>
          <Box>
            {
              (this.state.error.skills.length > 0)
              && <Typography variant="caption" className={classes.errorStyle}>{this.state.error.skills}</Typography>
            }
          </Box>
        </Grid>
      </Grid>

    </Box>


  }

  renderCategories = (leftCategory: any) => {
    const { classes } = this.props;
    const categoriesWithNoCategoryOption = leftCategory.length > 0 
  ? [...leftCategory]
  : [{id: 3, name: "No category left", selected: false }];
    return <Box className={classes.mainContainer} style={{marginTop:"30px"}}>
      <Typography className={classes.skillsHeading}>{configJSON.categoryTitle}</Typography>

      <Grid container className={classes.skillsTxtFieldContainer}>
        <Grid item xs={12} className={classes.subContainer}>
          <div className="form-group">
            <SearchIcon style={{ marginTop: '30px' , marginRight:"15px"  }} />
            </div>
            <CustomSelect
              value={""}
              data-testid='selectedCategory'
              onChange={this.selectNewCategory}
              label={configJSON.categoryInterest}
              renderItem={(item, props) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  {...props}
                >
                  {item.name}
                </MenuItem>
              )}
              name="selectedCategory"
              tabIndex={3}
              data={categoriesWithNoCategoryOption}
              placeholder={leftCategory.length == 0 ? configJSON.categoryNoPlaceHoldertxt : configJSON.categoryPlaceHoldertxt}
            />
      
          <Box className={classes.skillsSubContainer}>

            {this.getCategoryListing && this.getCategoryListing.map((cat, index) => (
              <Box className={classes.skillWrapper} key={cat.id}>
                <Typography className={classes.addedSkillName}>
                  {cat.name}
                </Typography>
                <ClearIcon
                  className={classes.crossIcon}
                  onClick={this.deleteSelectedCategory.bind(this, cat.id)}
                  data-testid='deleteCat'
                />
              </Box>
            ))}
          </Box>
          <Box>
            {
              (this.state.error.category.length > 0)
              && <Typography variant="caption" className={classes.errorStyle}>{this.state.error.category}</Typography>
            }
          </Box>
        </Grid>
      </Grid>

    </Box>


  }

  renderFreelancingExp = () => {
    const { classes } = this.props;
    return <Box className={`${classes.mainContainer} ${classes.cardMarginContainer}`} >
      <Typography className={classes.skillsHeading}> {configJSON.freelancingExperience} </Typography>

      <Box className={classes.subContainer}>
        <label htmlFor="freelanceexperience">{configJSON.noOfYears}</label>
        <input
          type="number"
          value={this.state.freelanceexperience}
          onChange={(event)=>this.handleExperienceChange(event)}
          data-testid="experience"
          name="freelanceexperience"
          tabIndex={3}
          placeholder={configJSON.freelancingExperiencePlaceholder}
          min="0"
        />
        
      </Box>
      <Box>
        {
          (this.state.error.yearsOfExperience.length > 0)
          && <Typography variant="caption" className={classes.errorStyle}>{this.state.error.yearsOfExperience}</Typography>
        }
      </Box>
    </Box>

  }

  renderresumesAndCertifications = () => {
    const { classes } = this.props;
    return <Box>
      <Box className={`${classes.mainContainer} ${classes.cardMarginContainer}`}>
        <Box className={classes.resumeContainer}>
          <Typography className={classes.resumeUploadBlock}> {configJSON.resumeAndcertfifcates}</Typography>
          <Box className={classes.uploadBoxStyle}>

            <Typography className={classes.uploadTxtStyle}> {configJSON.uploadResume}</Typography>

            <input type="file"
              multiple
              onChange={this.handleUploadResume}
              value=""
              className={classes.uploadInputStyle}
              data-testid="dropzone"
            />
          </Box>
        </Box>

        <Grid container spacing={2} className={classes.pdfItemsContainer}>
          {this.state.uploadedResume && this.state.uploadedResume.map((resume: any, index) => (
            <Grid key={resume.blob_id} item xs={12} sm="auto">
              <Box className={classes.resumeDetailsContainer} >
                <Box className={classes.resPdfTextLeft}>
                  <Typography className={classes.pdfLabel}>{configJSON.pdfLabel}</Typography>
                </Box>
                <Grid container spacing={1} className={classes.resumeGrid}>
                  <Grid item xs={12} sm="auto">
                    <Box className={classes.resumeNameBoxStyle}>
                      <Typography className={classes.resumeTitile}> {this.truncateResumeName(resume.name)}</Typography>
                      <Typography className={classes.resumeSizeUpdatedTime}>
                        ({this.onConvertResumeFileSize(resume.size)}){configJSON.uploadLabel}{resume.created_at}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm="auto" style={{ display: "flex", justifyContent: "center"}}>
                <Box className={classes.iconsContainer}>
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className={classes.deleteIcon2}
                    data-testid={`deleteResume-${index}`}
                    onClick={this.removeDocuments.bind(this, resume.blob_id, index)}
                  />
                  <a
                   className={classes.resumeDwonloadIcon}
                    href={resume.url}
                    onClick={this.handleDownloadResume.bind(this, resume.url, resume.name)}
                    target="_blank"
                    data-testid={`downloadResume-${index}`}
                  >
                    <img src={download} alt="download" />
                  </a>
                </Box>
                    </Grid>
                  </Grid>
              </Box>
            </Grid>
          ))}
          <Box>
            {
              (this.state.error.resumes.length > 0)
              && <Typography variant="caption" className={classes.errorStyle}>{this.state.error.resumes}</Typography>
            }
          </Box>
        </Grid>
        <hr />

        <Box className={classes.certificationsBlock}>
          <InputLabel
            className={classes.inputLabelTxt}
            htmlFor="certifications"
          >
            {configJSON.certifications}
          </InputLabel>
          <TextField
            id="certifications"
            fullWidth
            InputProps={{
              classes: {
                input: classes.educationDetailsTextField,
              },
            }}
            placeholder={configJSON.addCertificationsPlaceholder}
            onChange={this.onCertificationChange}
            onKeyPress={this.handleAddCertifications}
            data-testid='addCertification'
            value={this.state.certificateName}
          />
        </Box>
        <Box className={classes.certificationsBlockNew}>
          {this.state.certifications && this.state.certifications.map((certificate, index) => (
            <Box className={classes.specificSkillContainer} key={certificate.name}>
              <Typography className={classes.addedSkillName}>
                {certificate.name}
              </Typography>
              <ClearIcon className={classes.crossIcon}
                onClick={this.deleteCertification.bind(this, index)}
                data-testid={`deleteCertification-${index}`}
              />
            </Box>
          ))}
        </Box>
        <Box>
          {
            (this.state.error.certification.length > 0)
            && <Typography variant="caption" className={classes.errorStyle}>{this.state.error.certification}</Typography>
          }
        </Box>
      </Box>
    </Box>

  }

renderStateGrid = (setFieldValue:any,values:any,errors:any) =>{
  const{classes}=this.props;
  return <Grid item xs={12} sm={6} className=""  >
 
  {!this.state.isLoadingStates ?
  <>
  {this.state.getStatesData.length== 0 ?
  <>
   <InputLabel className={classes.inputLabelTxt}> {configJSON.stateTxt} </InputLabel>
   <Field
   fullWidth
   displayEmpty
   placeholder={configJSON.placeholderState}
   data-testid="stateTxt"
   name="state"
   disabled={!values.country}
   onChange={(e:any)=>{
    setFieldValue(`state`,e.target.value);
    setFieldValue(`city`,"");
    this.removeCitites()
  }}
   />
   <ErrorMessage
    name="state"
    component="div"
    className="error-class"
  />
   </>
 :
 
  <>
    <CustomSelect
            value={values.state}
            disabled={!values.country}
            onChange={(e:any)=>{
              setFieldValue(`state`,e.target.value);
              setFieldValue(`city`,"");
              this.removeCitites()
              this.getCities(e.target.value)
            }}
            label={configJSON.stateTxt}
            data-testid="state"
            renderItem={(item, props) => (
              <MenuItem key={item} value={item} {...props}>
                {item}
              </MenuItem>
            )}
            labelStyle={{ marginBottom: "3px" }}
            rootStyle={{ paddingRight: "20px" }}
            name="state"
            data={this.state.getStatesData}
            placeholder=" Select your state"
            marginBottom="0px"
          />
          {!values.state && <ErrorMessage
                 name="state"
                 component="div"
                 className="error-class"
               />}
  </>
  }</>
 :<p data-testid="statesLoadingText" className={classes.loaderTxtStyle}>{configJSON.loaderTxt}</p>
}
  
</Grid>
}
  renderWorkExpFormik= ()=>{
    const{classes}=this.props;
    return  <Formik
    enableReinitialize={true}
      initialValues={this.state.addWorkExpData}
      initialErrors={{
        jobTitle: 'Please enter job title',
        employerName: 'Please enter employer name',
        country: 'Please select country',
        state: 'Please enter or select state',
        city: 'Please enter or select city',
        jobDecritption: 'Please enter job description'
      }}
      initialTouched={{
        jobTitle: false,
        employerName: false,
        country: false,
        state: false,
        city: false,
        jobDecritption: false
      }}
      onSubmit={values => {
   
             this.state.editExperianceClicked
            ? this.handleUpdateCarrerexperienceApi(values)
            : this.handleAddCarrerExperience(values)
      }}
      
      validationSchema={AddWorkExpValidation}
      data-testid = 'formik'
    >
      {({setFieldValue,values,errors,setTouched}) => (
        <Form translate={undefined}>
   
          <Grid container spacing={1} className={classes.workexperienceAddContainer}
          >
            <Typography className={classes.jobTitle}> {configJSON.addNewexperience}</Typography>
            <Grid container spacing={2} style={{marginTop: "10px"}}>
            <Grid item xs={12} sm={6}>
   
                <InputLabel className={classes.inputLabelTxt}> {configJSON.jobTitle} </InputLabel>
                <Field
                fullWidth
                  id="jobTitle"
                  name="jobTitle"
                  placeholder={configJSON.selectYourJobTitle}
                  displayEmpty
                  data-testid = 'jobTitle'
                  onChange={(e: { target: { value: any; }; }) => {
                    const value = e.target.value;
                    setFieldValue('jobTitle', e.target.value);
                    if (value.trim()) {
                      setTouched({
                        jobTitle: true,
                        employerName: true,
                        country: true,
                        state: true,
                        city: true,
                        jobDecritption: true
                      }, false);
                    } else {
                      setTouched({
                        jobTitle: true,
                        employerName: false,
                        country: false,
                        state: false,
                        city: false,
                        jobDecritption: false
                      }, false);
                    }
                  }}
               />
               <ErrorMessage
                 name="jobTitle"
                 component="div"
                 className="error-class"
               />
             </Grid>
   
             <Grid item xs={12} sm={6} className="" >
                <InputLabel className={classes.inputLabelTxt}>   {configJSON.employerName} </InputLabel>
                <Field
                fullWidth
                displayEmpty
                name="employerName"
                placeholder={configJSON.employerNamePlaceholder}
                data-testid = 'employerName'
               />
               <ErrorMessage
                 name="employerName"
                 component="div"
                 className="error-class"
               />
             </Grid>
   
                 <Grid item xs={12} sm={6}>
                <CustomSelect
                  value={values.country}
                  onChange={(e: any) => {
                    setFieldValue(`country`, e.target.value);
                    setFieldValue(`state`, "");
                    setFieldValue(`city`, "");
                    this.removeStates()
                    this.removeCitites()
                    this.getStates(e.target.value)
                  }}
                  label={configJSON.country}
                  data-testid="country"
                  renderItem={(item, props) => (
                    <MenuItem
                      key={item}
                      value={item}
                      {...props}
                    >
                      {item}
                    </MenuItem>
                  )}
                  labelStyle={{
                    marginBottom: "3px"
                  }}
                  rootStyle={{
                    paddingRight: "20px"
                  }}
                  name="country"
                  data={this.state.getCountriesData}
                  placeholder=" Select your Country"
                  marginBottom="0px"
                />
                {!values.country && <ErrorMessage
                 name="country"
                 component="div"
                 className="error-class"
               />}
                </Grid>
   
                      {this.renderStateGrid(setFieldValue,values,errors)}
   
                <Grid item xs={12} sm={6} className=""  >
                  
                  {this.state.cityData.length== 0 ?
                  <>
                  <InputLabel className={classes.inputLabelTxt}> {configJSON.cityTxt} </InputLabel>
                   <Field
                   fullWidth
                   displayEmpty
                   placeholder={configJSON.placeholderCity}
                   data-testid="city"
                   disabled={!values.state}
                   name="city"
                   />
                     <ErrorMessage
                                name="city"
                                component="div"
                                className="error-class"
                              />
                   </>
                 :
                 
                    <>
                      <CustomSelect
                    id="personalcity"
                    data-testid="city"
                         value={values.city}
                         disabled={!values.state}
                         onChange={(e:any)=>setFieldValue("city",e.target.value)}
                         label={configJSON.city} 
                         renderItem={(item, props) => (
                           <MenuItem
                             key={item}
                             value={item}
                             {...props}
                           >
                             {item}
                           </MenuItem>
                         )}
                         labelStyle={{
                           marginBottom:"3px"
                      }}
                      rootStyle={{
                          paddingRight:"20px"
                      }}
                      data={this.state.cityData}
                      // error={errors["city"] as string}
                      name="city"
                         placeholder=" Select your city"
                       />
                       <ErrorMessage
                                name="city"
                                component="div"
                                className="error-class"
                              />
                    </>
                 }
                 
                </Grid>
           
                 <Grid item xs={12}>
                  <InputLabel className={classes.inputLabelTxt}>{configJSON.jobDescription}</InputLabel>
                  <Field
                    as="textarea"
                    rows={4}
                    name="jobDecritption"
                    placeholder={configJSON.jobDecritptionPlaceholder}
                    data-testid = 'jobDecritption'
                  />
                  <ErrorMessage
                    name="jobDecritption"
                    component="div"
                    className="error-class"
                  />
                </Grid>
                </Grid>
   
            <Box className={classes.cancelBtnBlock}>
              <Button
               variant = "contained"
               disableElevation
               onClick={this.resetWorkExpFormValues}
               data-testid="cancelEducationBtn"
              className={classes.addNewCancelBtn}>
                {configJSON.cancelBtnTxt}
              </Button>
              <Button
                 variant = "contained"
                 disableElevation
                type="submit"
                className={classes.addNewSaveBtn}
                data-testid="addCarrerExperienceBtn"
              >
                {configJSON.saveBtnTxt}
              </Button>
            </Box>
          </Grid>
   
      </Form>
      )}
      </Formik>
  }

  renderWorkExperience= ()=>{
    const{classes}=this.props;
    return            <Box> 
    <Box  className={`${classes.mainContainer} ${classes.cardMarginContainer}`}>
   <Box>
     <Box className={classes.experienceHeadingBlock}>
       <Typography className={classes.skillsHeading}>
         {configJSON.workExperience}
       </Typography>
       <Button 
          disabled={this.state.freelanceexperience == "0" || this.state.freelanceexperience == ""}
          className={classes.addAotherexperienceBtn}
          onClick={this.addAnotherExperinceBtnClicked}
          data-testid = 'addAnotherExperince'
        >
         {configJSON.addAnotherExperianceBtn}
       </Button>
     </Box>
     { this.state.error.workExperience != "" ? <Typography variant="caption" className={classes.errorStyle}>{this.state.error.workExperience}</Typography> : null }
     { !this.state.experiencesData && !this.state.isRecordFound? (
     <Typography className={classes.noDataStyle}>{configJSON.noWorkExpFound}</Typography>
) : (
     this.state.freelanceexperience != "0" && this.state.experiencesData && this.state.experiencesData.map((experience) => (
     <Box className={classes.workexperienceSubContainer} key={experience.data.id}>
       <Box className={classes.expHeadingContainer}>
         <Typography className={classes.jobTitle}>
           {experience.data.attributes.job_title}
         </Typography>
         <Box className={classes.expIconsContainer}>
           <img
             src={editIcon}
             alt="editIcon"
             className={classes.deleteIcon}
             onClick={this.updateCarrerexperience.bind(this,experience)}
             data-testid="updateexperience"
           />
           <img
             src={deleteIcon}
             className={classes.deleteIcon}
             onClick={this.deleteCarrerexperience.bind(this,experience.data.attributes.id)}
             data-testid="deleteexperience"
           />
         </Box>
       </Box>
       <Typography className={classes.workLocation}>
         {experience.data.attributes.employer_name}
       </Typography>
       <Typography className={classes.workDescrition}>
         {experience.data.attributes.description}
       </Typography>
     </Box>
   ))
  )}

 {this.state.isAddAnotherexperienceBtnClicked && (
    this.renderWorkExpFormik()
     )}
   </Box>
 </Box>
</Box>

 
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    this.getSkillsListing = this.state.skillList.filter( (item) => item.selected);
     const leftSkill = this.state.skillList.filter((item) => !item.selected);
     this.getCategoryListing = this.state.categoryList.filter( (item) => item.selected);
    const leftCategory = this.state.categoryList.filter((item) => !item.selected);
    // Customizable Area End
    return (
      // Customizable Area Start
      <>

       {this.renderSkills(leftSkill)}



        {this.renderCategories(leftCategory)}


             <Box  className={`${classes.mainContainer} ${classes.cardMarginContainer}`}>
            <Box>
              <Box className={classes.experienceHeadingBlock}>
                <Typography className={classes.skillsHeading}> {configJSON.education}</Typography>
                <Typography
                  className={classes.addAotherexperienceBtn}
                  onClick={this.addAnotherEduBtnClicked}
                  data-testid = 'addAnotherEducation'
                >
                  {configJSON.addAnotherEduBtnTxt}
                </Typography>
              </Box>
              {!this.state.educationQualificationData && !this.state.isEducationRecordFound? (
     <Typography className={classes.noDataStyle}>{configJSON.noEducationFound}</Typography>
) : (
              this.state.educationQualificationData && this.state.educationQualificationData.map((edu) => (
          <Box className={classes.workexperienceSubContainer} key={edu.data.attributes.id}>
            <Box className={classes.expHeadingContainer}>
              <Typography className={classes.jobTitle}>
                {edu.data.attributes.education}
              </Typography>
              <Box className={classes.expIconsContainer}>
                <img
                  src={editIcon}
                  alt="editIcon"
                  className={classes.deleteIcon}
                  onClick={this.updateEducation.bind(this,edu)}
                  data-testid="updateexperience"
                />
                <img
                  src={deleteIcon}
                  className={classes.deleteIcon}
                  onClick={this.deleteEducation.bind(this,edu.data.attributes.id)}
                  data-testid="deleteEducation"
                />
              </Box> 
            </Box>
            <Box className={classes.designBlock}>
              <Box className={classes.universityBlock}>
                <Typography className={classes.jobDescrition}>
                  University
                </Typography>
                <Typography className={classes.jobLocation}>
                  {edu.data.attributes.university_college}
                </Typography>
              </Box>
              <Box className={classes.universityBlock}>
                <Typography className={classes.jobDescrition}>
                  Field Of Study
                </Typography>
                <Typography className={classes.jobLocation}>
                  {edu.data.attributes.field_study}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))
      )}

          {this.state.isAddAnotherEduBtnClicked && (
          <Formik
            enableReinitialize={true}
            innerRef={this.formikRefEducation}
            initialValues={this.state.addEducationData}
            initialErrors={{
              education: 'Please select education',
              university: 'Please select university',
              fieldOfStudy: 'Please select field of study'
            }}
            initialTouched={{
              education: false,
              university: false,
              fieldOfStudy: false
            }}
            onSubmit={values => {
  
                   this.state.editEduClicked
                  ? this.handleUpdateEducation(values)
                  : this.handleAddEducation(values)
            }}
            
            validationSchema={AddEduValidation}
            data-testid = 'formik'
          >
            {({ values ,setFieldValue, setTouched}) => (
              <Form translate={undefined}>
                <Grid container className={classes.workexperienceAddContainer}
                >
                  <Typography className={classes.jobTitle}> {configJSON.addNewEducation}</Typography>

                  <Grid container spacing={1} style={{marginTop: "10px"}}>
                       <Grid item xs={12} sm={6} className="">
                          <CustomSelect
                            value={values.education}
                            onChange={(e:any)=>{
                              const value = e.target.value;
                              setFieldValue("education",e.target.value);
                              if (value) {
                                setTouched({
                                  education: true,
                                  university: true,
                                  fieldOfStudy: true,
                                }, false);
                              } else {
                                setTouched({
                                  education: true,
                                  university: false,
                                  fieldOfStudy: false,
                                }, false);
                              }
                            }}
                            data-testid='education'
                            label={configJSON.educationTxt}
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item.attributes.id}
                                value={item.attributes.id}
                                {...props}
                              >
                                {item.attributes.education}
                              </MenuItem>
                            )}
                            name="education"
                            tabIndex={3}
                            data={this.state.educationData}
                            placeholder="Select your education"
                          />
                          <ErrorMessage
                            component="div"
                            name="education"
                            className="error-class"
                           />
                      </Grid>

                      <Grid item xs={12} sm={6} className="">
                        <CustomSelect
                            value={values.university}
                            onChange={(e:any)=>setFieldValue("university",e.target.value)}
                            data-testid='university'
                            label={configJSON.universityTxt}
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item.attributes.id}
                                value={item.attributes.id}
                                {...props}
                              >
                                {item.attributes.name}
                              </MenuItem>
                            )}
                            name="university"
                            tabIndex={3}
                            data={this.state.userUniversityData}
                            placeholder="Select your university"
                            labelSuffix={
                              <IconButton style={{padding:0,marginLeft:"12px",marginBottom:"-2px"}} onClick={()=>this.handleCreateUniversityModalOpen(true)}>
                                <AddOutlined/>
                              </IconButton>
                            }
                          />
                          <CreateUniversityModal
                              open={this.state.openCreateUniversityModal}
                              setOpen={this.handleCreateUniversityModalOpen}
                              createUniversityLoading={this.state.createUniversityLoading}
                              onCreateUniversity={this.createNewUniverisy}
                          />
                          <ErrorMessage
                            component="div"
                            name="university"
                            className="error-class"
                           />
                      </Grid>

                      <Grid item xs={12} sm={6} className="">
                        <CustomSelect
                            value={values.fieldOfStudy}
                            onChange={(e:any)=>setFieldValue("fieldOfStudy",e.target.value)}
                            data-testid='fieldOfStudy'
                            label={configJSON.fieldStudyTxt}
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item.attributes.id}
                                value={item.attributes.id}
                                {...props}
                              >
                                {item.attributes.field_of_study}
                              </MenuItem>
                            )}
                            name="fieldOfStudy"
                            tabIndex={3}
                            data={this.state.fieldOfStudyData}
                            placeholder="Select your fieldOfStudy"
                          />
                          <ErrorMessage
                            component="div"
                            name="fieldOfStudy"
                            className="error-class"
                           />
                      </Grid>
                   </Grid>
                 

                  <Box className={classes.cancelBtnBlock}>
                    <Button
                     variant = "contained"
                     disableElevation
                    className={classes.addNewCancelBtn}
                    onClick={this.resetEducationFormValues}
                    data-testid="cancelEducationBtn"
                    >
                      {configJSON.cancelBtnTxt}
                    </Button>
                    <Button
                       variant = "contained"
                       disableElevation
                      type="submit"
                      className={classes.addNewSaveBtn}
                      data-testid="addEducationBtn"
                    >
                      {configJSON.saveBtnTxt}
                    </Button>
                  </Box>
                </Grid>

            </Form>
            )}
            </Formik>
              )}
            </Box>
          </Box>
 

         {this.renderresumesAndCertifications()}


            {this.renderFreelancingExp()}

            {this.renderWorkExperience()}

        <Box className={classes.saveBtnBlock}>
          <Button type="button" className={classes.addNewCancelBtn} 
          disabled={this.state.isDisable}
          data-testid = 'cancelPersonalInfo'
          onClick={ this.handleResetData}
          >
            {configJSON.cancelBtnTxt}
          </Button>
          <Button
            type="submit"
            className={classes.addNewSaveBtn}
            onClick={ this.handleSavePersonaInfoDetails}
            data-testid="savePersonalInfo"
            disabled={this.state.isDisable}
          >
            {this.state.isLoadingPersonalInfo?<CircularProgress size={24} className={classes.loadingStyle} />:configJSON.saveBtnTxt}
          </Button>
        </Box>
        
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = () => ({
  mainContainer: {
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 20,
  }, 
  skillsHeading: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    color: "#222222",

    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  resumeUploadBlock:{
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    color: "#222222",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  inputLabelTxt: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083A3",
  },
  skillsTxtFieldContainer: {
    marginTop: 20,
    display: "flex",
    gap: 20,
    borderRadius: "14px",
  },
  addWorkExpFieldGridStyle: {
     display: "flex",
    // gap: 20,
    borderRadius: "14px",
 flexDirection: 'column' as const,
 padding: '20px',
  },
  textForAutoSelect: {
    "&::placeholder": {
      fontWeight: 800,
      color: "red",
    },
  },
  specificSkillContainer: {
    display: "flex",
    padding: 7,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F6F6F7",
    borderRadius: "20px",
    color: "#8083A3",
    
  },
  educationDetailsTextField: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
  },
  addedSkillName: {
    color: "#222222",
  },
  crossIcon: {
    color: "#fd7235",
    cursor:'pointer'
  },
  pdfLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#3f3f3f",
    background: "#e4e6e8",
    border: "1px solid #e4e6e8",
    borderRadius: "10px 0px 0px 10px",
    padding: "15px",
    textAlign: "right" as const,
    [theme.breakpoints.down("xs")]:{
      padding: "20px 15px"
    }
  },
  resumeTitile: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#000000",
    marginLeft: 20,
  },
  resumeSizeUpdatedTime: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "10px",
    marginLeft: 20,
    color: "rgba(128, 131, 163, 0.760784)",
    padding:"5px 0 5px 0",
    [theme.breakpoints.down("xs")]:{
      padding: "1px 0"
    }
  },
  resumeTitleBlock: {
    marginTop: 10,
  },
  deleteIcon: {
    cursor:'pointer',
    color: "red",
    borderRadius: "50%",
    margin: "5px",
    border: "1px solid #e4e6e8",
    width:"26px"
  },
  deleteIconEdu: {
    display: "none",
  },
  deleteIcon2: {
    color: "red",
    borderRadius: "50%",
    margin: "5px",
    border: "1px solid #e4e6e8",
    width: '26px',
    cursor:'pointer',
    [theme.breakpoints.down("xs")]: {
      borderRadius: "5%",
      width: "80px",
      height: "26px",
      margin: "0"
    }
  },
  resumeDwonloadIcon: {
    borderRadius: "50%",
    margin: "5px",
    border: "1px solid #e4e6e8",
    width: '26px',
    cursor:'pointer',
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      borderRadius: "5%",
      width: "80px",
      height: "26px",
      margin: "0"
    }
  },
  resumeDetailsContainer: {
    display:'flex',
    borderRadius: '10px',
    border: '1px solid #e4e6e8',
    backgroundColor:'#ffffff',
    justifyContent:'space-between',
    marginBottom:'1vw',
    
  },
  resumeNameBoxStyle:{
    display: "flex",
    flexDirection: "column" as const,
    alignItems: 'flex-start',
    flex: 2,
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
    [theme.breakpoints.down("xs")]:{
      marginBottom: "2px",
      gap: "10px"
    }
  },
  pdfItemsContainer: {
    display:'flex',
    alignItems:'center'
  },
  subContainer: {
    flex: 1,
  },
  uploadBtn: {
    borderRadius: "23px",
    minWidth: "140px",
    color: "#fff",
    display: "inline-block",
    fontSize: "14px",
    margin: "0 15px",
    border: "none",
    background: "#ec952d",
    "& .MuiButton-label": {
      width: "unset",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      padding: 0,
    },
    "&:hover": {
      backgroundColor: "#ec952d",
    },
  },
  uploadInputStyle:{
    position:"absolute" as const,
    top:0,
    left:0, 
    height:'100%',
    width:'100%',
    opacity:0, 
    cursor:"pointer",
    },
    uploadTxtStyle:{
      fontSize:'14px',
      fontWeight: 600,
      display: 'flex',
      color: '#fff',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },

   },
   uploadBoxStyle: {
    position:'relative'as const,
    borderRadius: "18px",
    minWidth: "140px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    padding: "5px",
    background: "#ec952d",
    color: "#fff",
    display: "inline-block",
    margin: "0 15px",
    border: "none",
    cursor:'pointer',
    height: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
      minWidth: "100px",
      fontSize: "8px",
    },
  },

  workexperienceSubContainer: {

    backgroundColor: "#f1f1f1",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 24,
    marginTop: 18,
  },
  workexperienceAddContainer: {
    backgroundColor: "light yellow",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 24,
    marginTop: 18,
  },
  jobTitle: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#222222",
  },
  jobLocation: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#000000",
    marginTop: 0,
    marginBottom: 10,
    paddingBottom: 8,
    borderBottom: "1px solid #e0e0e0"
  },
  jobDescrition: {
    marginTop: 12,
    marginBottom: 8,
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "gray",
  },
  workLocation: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    color: "#000000",
    marginTop: 0,
    marginBottom: 10
  },
  workDescrition: {
    marginTop: 12,
    marginBottom: 3,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
  },
  jobDuration: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "10px",
    color: "rgba(128, 131, 163, 0.760784)",
  },
  addAotherexperienceBtn: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#EC952D",
    cursor:'pointer',
    'text-transform': 'capitalize',
    '&.Mui-disabled': { 
        color: '#AEAEAE',
        cursor: 'not-allowed'
    },
    
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  experienceHeadingBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap" as const,
  },
  expIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
  expHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  saveBtn: {
    "& .MuiButton-label": {
      width: "unset",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      padding: 10,
    },
  },

  cancelBtn: {
    "& .MuiButton-label": {
      width: "unset",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      padding: 10,
      backgroundColor: "#F2F2F2",
      color: "#000",
    },
  },
  btnCancel: {
    background: "#ec952d",
    color: "#fff",
  },
  cardMarginContainer: {
    marginTop: 30,
  },
  certificationsBlock: {
    marginBottom: '40px',

  },
  addNewSaveBtn: {
      width: "100px",
      height:'30px',
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      padding: 10,
      backgroundColor: "#ec952d",
      borderRadius: '23px',
      color:"#fff",
      textTransform:'none' as const,
      border:'none',
      '&:hover': {
        backgroundColor: "#ec952d",
      },
  },
  resumeContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:'16px',
    flexWrap: "wrap" as const
  },
  certificationsBlockNew: {
     display: "flex",
     flexWrap:'wrap' as const,
     gap: "3px"
  },
  cancelBtnBlock:{
    display: "flex",
    justifyContent: "flex-end",
    width:'95%',
    marginTop: "15px",
  },
  eductionDetailsBlock:{
    display: "flex",
    marginTop: 30,
    gap: "12px" 
  },
  skillsSubContainer:{
    display: "flex",
    flexWrap: 'wrap' as const,
    gap: "3px"
  },
  saveBtnBlock:{
    display: "flex",
    justifyContent: "center",
    marginTop: '3rem',
    gap: "10px"
  },
  freelanceexperienceInput :{
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    color: '#8083A3',
    width:"100%"
  },
  freelanceexperienceLabel:{
    marginTop: 20
  },
  uploadResume:{
    display: "flex",
    justifyContent: "flex-end",
  },
  snackBarStyle: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#16A700",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.25098)",
    borderRadius: "32px",
  },
  skillWrapper: {
    display: "flex",
    padding: 7,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F6F6F7",
    margin: "8px 0px",
    borderRadius: "20px",
    color: "#8083A3",
  },
  addNewCancelBtn: {
    width: "100px",
    height:'30px',
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    padding: 10,
    color: "#000",
    backgroundColor: "#f2f2f2",
    borderRadius: '23px',
    textTransform:'none' as const,
    border:'none',
  },
  errorStyle:{
    color:"red",
    fontSize: '11px',
   },
   loaderTxtStyle:{
    color:"#8083a3"
  },
  loadingColorStyle:{
    color:'#8083a3'},
    noDataStyle: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      color: "#8083a3",
      marginTop:'10px'
    },
  resumeGrid: {
    display: "flex",
    justifyContent: "space-between"
  },
  resPdfTextLeft:{
    backgroundColor:"#e4e6e8",
    "@media(max-width: 480px)": {
      borderRadius: "8px 0 0 8px",
      display: "flex",
      alignItems: "center",
    } as const,
  },
  designBlock: {
    display: "flex",
    justifyContent: "space-between"
  },
  universityBlock: {
    width: "45%"
  }
});

export const PersonalInfoPageStyles = withStyles(styles)(PersonalInfoPage);
// Customizable Area End