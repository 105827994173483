import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    withStyles,
    Container,
    Typography,
    Chip,
} from "@material-ui/core";
import SponsorInviteFreelancerController ,{Props} from './SponsorInviteFreelancerController.web';
import SponserHeader from "../../dashboard/src/Sponserheader.web";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SendInvitationModal from "../../../components/src/utill/SendInvitationModal.web";
import DeclineInvitationModal from "../../../components/src/utill/DeclineInvitationModal.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import InviteFreelancerItem from "../../../components/src/InviteFreelancerItem.web";
import {notFoundImg} from './assets'
// Customizable Area End

export class SponsorInviteFreelancer extends SponsorInviteFreelancerController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
    
       // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes}=this.props;
        const {tabOptions,getProjectLoading,project,freelancerList,totalFreelancer,getFreelancerLoading, isInvitedList}=this.state;
        const isShowMoreEnable = totalFreelancer > freelancerList?.length && freelancerList?.length>0;
        // Customizable Area End
        return (

            // Customizable Area Start
             <Box>
                <SponserHeader navigation={this.props.navigation}/>
                <Container>
                    <Box className={classes.row}>
                        <Button data-testid="gobackBtn" onClick={this.handleGoBack} className={classes.back_button} startIcon={<KeyboardBackspaceIcon/>}>
                            Back
                        </Button>
                        {
                            getProjectLoading?<CircularProgress size={18} color="primary"/>:(
                            <Typography component={"h1"} variant="h4" className={classes.page_heading}>
                                {project?.attributes?.project_name} 
                            </Typography>
                            )
                        }
                       
                    </Box>
                    <br/>
                    <ProjectFilterWeb
                            onFilter={this.freelancerFilterHandler}
                            onSearch={this.freelancerSearchHandler}
                            onCancelFilter={this.freelancerCancelFilterHandler}
                            activeTabColor="#00A7A2"
                            searchPlaceholder="Search Freelancer"
                    />
                    <br/>
                    <Box className={classes.row}>
                        {
                            tabOptions.map((item,index)=>(
                                <Chip
                                    data-testid="tabOption"
                                    key={item.id}
                                    label={item.label}
                                    style={{
                                        backgroundColor: item.isActive ? "#00A7A2" : "white",
                                        color: item.isActive ? "#fff" : "#222",
                                        padding:"25px 30px",
                                        fontSize:"14px",
                                        fontWeight:"bold",
                                        borderRadius:"25px"
                                    }}
                                    onClick={() => this.handleTabOptionsChanged(item.id)}
                                    clickable
                                />
                            ))
                        }
                    </Box>
                    <Box className={classes.freelancer_list_container}>
                        {
                            freelancerList && freelancerList.map((item,index)=>(
                                <InviteFreelancerItem
                                    key={item.id}
                                    freelancer={item}
                                    index={index}
                                    handleSelectInviteFreelancer={this.handleSelectInviteFreelancer}
                                    handleSendMessage={this.handleSendMessage}
                                    navigateToHireFreelancer={this.navigateToHireFreelancer}
                                    handleLike={this.handleLike}
                                    loading={this.state.loading}
                                    loadingindex={this.state.loadingindex}
                                    isInvitedList={isInvitedList}
                                />
                            ))
                        }
                    </Box>
                    <br/>
                    <br/>
                    {
                        !getFreelancerLoading && freelancerList?.length===0 && (
                            <Box className={classes.not_found_container}>
                                <img className={classes.not_found_image} src={notFoundImg} alt="not found image"/>
                                <Typography component={"h1"} variant="h4" className={classes.page_heading}>Oops!</Typography>
                                <Typography style={{color:"rgb(128, 131, 163)"}}>No Invited Freelancers yet</Typography>
                                <Typography style={{color:"rgb(128, 131, 163)"}} variant="subtitle1" className={classes.page_heading}>Invite top candidates before they're booked.</Typography>
                                <Button data-testid="" onClick={this.handleInviteNow} className={classes.invite_now_button}>
                                    Invite Now
                                </Button>
                            </Box>
                        )
                    }

                    <Box className={classes.row} style={{justifyContent:"center"}}>
                        {
                            getFreelancerLoading && (
                                <CircularProgress size={24}/>
                            )
                        }
                        {
                            isShowMoreEnable && !getFreelancerLoading && (
                                <Button data-testid="loadMoreBtn" onClick={this.handleProjectLoadMore} className={classes.back_button} endIcon={<ChevronRightIcon/>}>
                                    Show More
                                </Button>
                            )
                        }
                        
                    </Box>
                    <br/>
                    <br/>
                </Container>
                {
                    this.state.openSendInvitationModal && (
                        <SendInvitationModal
                            open={this.state.openSendInvitationModal}
                            setOpen={this.handleOpenSendInvitationModal}
                            loading={this.state.sendInvitationLoading}
                            onSend={this.handleSendInvitation}
                            freelancerInfo={this.state.selectedInviteFreelancer}
                            clearInput={this.state.clearPopupInput}
                        />
                    )
                }
                {
                    this.state.openDeclineInvitationModal && (
                    <DeclineInvitationModal
                        open={this.state.openDeclineInvitationModal}
                        setOpen={this.handleOpenDeclineInvitatinModal}
                        loading={this.state.declineInvitationLoading}
                        onDecline={this.handleDeclineInvitation}
                        clearInput={this.state.clearPopupInput}
                    />
                    )
                }
               
             </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    row:{
        display:"flex",
        alignItems:"center",
        gap:"10px",
        flexWrap: "wrap"
    } as const,
    page_heading:{
        fontWeight:"bolder",

    } as const,
    freelancer_list_container:{
        display:"flex",
        flexDirection:"column",
        gap:"15px",
        marginTop:"20px",
    } as const,
    not_found_container:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        border:"1px solid rgba(0,0,0,.1)",
        borderRadius:"20px",
        padding:"20px",
        gap:"25px"
    } as const,
    not_found_image:{
        height:"120px",
        width:"120px",
        objectFit:"cover",
    } as const,
    subtitle:{
        color:"#96939c",
        fontWeight:"bold",
        fontSize:"13px"
    } as const,
    back_button:{
        background:"#f2f2f2",
        borderRadius:"20px",
        padding:"10px 20px",
        textTransform:"capitalize",
        fontWeight:"bold"
    } as const, 
    invite_now_button:{
        background:"#00A7A2",
        borderRadius:"20px",
        padding:"10px 20px",
        textTransform:"capitalize",
        color:"white",
        "&:hover":{
            background:"#00A7A2"
        }
    } as const,
    chip_item:{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        margin: 0,
        flexDirection: "row",
        fontWeight: 700,
        borderRadius: " 30px",
        padding: "7px 25px",
        height: "40px",
    } as const,
});

export default withStyles(styles)(SponsorInviteFreelancer);

// Customizable Area End