import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  TextField,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import OtpInput from 'react-otp-input';
// Customizable Area End

// Customizable Area Start
import { ThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Formik, Field, Form, ErrorMessage } from "formik";
import "../../customisableuserprofiles2/assets/css/Basic_details_customizable.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CircularProgress from "@material-ui/core/CircularProgress";
import SponserHeaderStyles from "../../dashboard/src/Sponserheader.web";
import KnowYourCustomerKycVerification from "../../knowyourcustomerkycverification/src/KnowYourCustomerKycVerification.web";
import {
  paypal,
  backArrow,
  savedCards,
} from './assets'
export const theme = createTheme({
  palette: {
  },
  typography: {
    fontFamily: 'inter',
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#fff",
        backgroundColor: "#222222",
        padding: '16px',
        border: 'none'
      },
      arrow: {
        color: "#222222",
      }
    },
    MuiRadio: {
      root: {
        color: '#99cb51',
        width: '1rem',
        height: '1rem',
      },
      colorSecondary: {
        color: '#99cb51',
        '&$checked': {
          color: '#99cb51',
        },

      },
    },
    MuiFormLabel: {
      root: {
        padding: '0 0 20px 0',
        color: '#222',
        fontSize: '18px',
        fontWeight: 700,
        "&.Mui-focused": {
          color: "#222",
        },
      },

    }
  }
});

const StyledTextInputField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    fontSize: '18',
    fontWeight: 800,
    color: '#222',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& input::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14',
      fontWeight: 400,
      color: '#8083a3'
    },
  }
})(TextField);

const StyledRadio = withStyles({
  root: {
    color: "#e4e6e8",
    '&$checked': {
      color: "#ec952d",
    },
  },
  checked: {},
})(Radio);

// Customizable Area End

import FreelancerWithdrawalController, {
  BankDetailsValidation,
  Props, configJSON
} from "./FreelancerWithdrawalController.web";


export class FreelancerWithdrawal extends FreelancerWithdrawalController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderPayeeList=()=>{
    const classes=this.props.classes;
    const {getPayeeLoading,payeeList}=this.state;
    if(getPayeeLoading){
      return(
        <Box style={{
           display:"flex",
           alignItems:"center",
           justifyContent:"center"
        }}>
            <CircularProgress size={24}/>
        </Box>
      )
    }
    if(!getPayeeLoading && payeeList.length===0){
      return(
        <Box style={{
           display:"flex",
           alignItems:"center",
           justifyContent:"center"
        }}>
            <Typography style={{fontWeight:"bolder"}}>No saved account found</Typography>
        </Box>
      )
    }
    return(
      <>
        {
          payeeList.map((payee,index)=>(
            <>
            <FormControlLabel value={payee.payeeId.toString()} control={<StyledRadio />}
                label={<Box className={classes.savedCardBox}>
                      <img src={savedCards} className={classes.savedCardImg} alt='savedcard' />
                    <Box className={classes.savedCardTxtBox}>
                    <Typography className={classes.saveCardBank}>{payee.bankName}</Typography>
                    <Typography className={classes.SavedCardNo}>{payee.accountNumber?.substring(0,3)}*****{payee.accountNumber?.substring(payee.accountNumber.length-3,payee.accountNumber.length)}</Typography>
                    </Box>
                  </Box>}
              />
              {index!==payeeList.length-1 && (
                <Divider className={classes.dividerStyle}></Divider>
              )}
            </>
          ))
        }
      </>
    )
  }
  renderNewBankForm=()=>{
    const {classes}=this.props;

    return(
      <Grid container className={classes.formContainer} >
      <Grid container>

        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle}> {configJSON.title} </InputLabel>
          <Field
            fullWidth
            id="name"
            name="name"
            placeholder={"Please enter name"}
            displayEmpty
            data-testid='name'
            className="field-classes"
            autoComplete='off'
          />
          <ErrorMessage
            name="name"
            component="div"
            className="error-class"
          />

        </Grid>
        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle}> {configJSON.accountNumber} </InputLabel>
          <Field
            fullWidth
            id="accountNumber"
            name="accountNumber"
            placeholder={configJSON.accountNumberPlaceholder}
            displayEmpty
            data-testid='accountNumber'
            className="field-classes"
            autoComplete='off'
          />
          <ErrorMessage
            name="accountNumber"
            component="div"
            className="error-class"
          />

        </Grid>

        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle}> {configJSON.verifyAccountNumber} </InputLabel>
          <Field
            autoComplete='off'
            fullWidth
            id="verifyAccountNumber"
            name="verifyAccountNumber"
            placeholder={configJSON.verifyAccountNumberPlaceholder}
            displayEmpty
            data-testid='verifyAccountNumber'
            className="field-classes"
          />
          <ErrorMessage
            name="verifyAccountNumber"
            component="div"
            className="error-class"
          />

        </Grid>

        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle} htmlFor="bankName">{configJSON.bankName} </InputLabel>
          <ExpandMoreIcon
          />

          <Field
            as="select"
            name="bankName"
            fullWidth
            displayEmpty
            data-testid="bankName"
            className="field-classes"
          >
            <option value="" selected hidden  >{configJSON.bankNamePlaceholder} </option>
            {this.state.bankNameData && this.state.bankNameData.map((bank: string) => (
              <option
                key={bank}
                value={bank}
                className="field-classes"
              >
                {bank}
              </option>
            ))}
          </Field>

          <ErrorMessage
            name="bankName"
            component="div"
            className="error-class"
          />
        </Grid>

        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle} htmlFor="bankAddress">{configJSON.bankAddress} </InputLabel>
           <Field
            fullWidth
            id="bankAddress"
            name="bankAddress"
            placeholder={"Enter bank address"}
            displayEmpty
            data-testid='bankAddress'
            className="field-classes"
            autoComplete='off'
          />

          <ErrorMessage
            name="bankAddress"
            component="div"
            className="error-class"
          />
        </Grid>
        <Grid item sm={12} md={6} className="textfield-group">
          <InputLabel className={classes.formTitle}> {configJSON.ifscCode} </InputLabel>
          <Field
            fullWidth
            id="ifscCode"
            name="ifscCode"
            placeholder={configJSON.ifscCodePlaceholder}
            displayEmpty
            data-testid='ifscCode'
            className="field-classes"
            autoComplete='off'
          />
          <ErrorMessage
            name="ifscCode"
            component="div"
            className="error-class"
          />

        </Grid>
        <Grid item sm={12} className="textfield-group">
          <InputLabel className={classes.formTitle}> {configJSON.phoneNumber} </InputLabel>
          <Field
            fullWidth
            id="phone_number"
            name="phone_number"
            placeholder={configJSON.phoneNumberPlaceHolder}
            displayEmpty
            data-testid='phone_number'
            className="field-classes"
            autoComplete='off'
          />
          <ErrorMessage
            name="phone_number"
            component="div"
            className="error-class"
          />
        </Grid>

      </Grid>


      <Box className={classes.bankBtnBox}>
        <Button
          variant="contained"
          disableElevation
          className={`${classes.commonBtn} ${classes.cancelBtn}`}
          onClick={this.handleCloseForm}
          data-testid="cancel_button"
        >
          {configJSON.cancelBtnLabel}
        </Button>

        <Button
          variant="contained"
          disableElevation
          type="submit"
          data-testid="submit_button"
          className={`${classes.commonBtn} ${this.state.sponsorWithdraw?classes.sponsor_submit_button:classes.submitBtn}`}
          disabled={this.state.createPayeeLoading}
        >
          {this.state.createPayeeLoading?<CircularProgress size={19} />:configJSON.submitBtnLabel}
        </Button>
      </Box>
    </Grid>
    )
  }

  renderWithdrawArea=()=>{
    const classes=this.props.classes;
    const {currentUser,sponsorWithdraw}=this.state;
    const currencySymbol=currentUser?.attributes?.currency_symbol;
    const themeColor=sponsorWithdraw?"#00A7A2":"#ec952d";
    return(
      <Box className={classes.paymentAmtDetailsBox}>
        {
          !this.state.sponsorWithdraw && (
            <>
              <Typography className={classes.paymentTxtTitle} >{configJSON.paymentAmtLabel}</Typography>
                <StyledTextInputField
                  data-testid="payment_amount"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { textAlign: 'right', fontSize: "16px", fontWeight: 700 },
                    startAdornment: <InputAdornment position="start" >{configJSON.totalAmtTxt}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                  }}
                  name="amount"
                  value={this.state.amount}
                  onChange={this.handleInputChange}
                />
                {
                  this.state.withdrawErrorMessage && (
                    <Typography style={{color:"red"}} className={classes.taxItemText}>{this.state.withdrawErrorMessage}</Typography>
                  )
                }
            </>
          )
        }
        <Button
          className={`${classes.withdrawalBtnStyle}  ${this.state.sponsorWithdraw?classes.sponsor_withdraw_button:classes.freelancer_withdraw_button}`}
          disabled={this.getDisableForWithdrawButton()}
          data-testid="withdraw_securely_btn"
          onClick={this.handleWithdrawAmount}
        >{this.state.withdrawLoading?<CircularProgress size={19}/>:configJSON.btnWithdraw}</Button>
      </Box>
    )
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
        currentUser,
        sendOtpLoading,
        verificationLoading,
        resendCountDownTimer,
        resendOtpLoading,
        otpSentSuccessMessage,
        otpSentErrorMessage,
        sponsorWithdraw
      }=this.state;
    const phoneNumber=currentUser?.attributes?.mobile_number;
    // Customizable Area End
    return (

      // Customizable Area Start

      <ThemeProvider theme={theme}>
        {
          sponsorWithdraw?
            <SponserHeaderStyles 
                navigation={this.props.navigation} 
                route={"projects"}
              />:(
              <FreelancerHeader
                navigation={this.props.navigation} history={undefined}
                location={undefined}
                id={""} route={"projects"}
              />
            )
        }
       
        <Dialog open={false} aria-labelledby="form-dialog-title" className={classes.mainmodal}>
          <DialogTitle id="form-dialog-title">Phone Verification</DialogTitle>
          <DialogContent>
            <InputLabel className={classes.inputLabelTxt}>Mobile Number</InputLabel>
            <Box className={classes.verifyInputandBtnConatiner}>
              <PhoneInput
                disabled
                test-id="editmobile"
                inputStyle={{
                  width: "100%",
                  border: "none",
                  borderRadius: "0px",
                  borderBottom: "1px solid #e4e6e8",
                  fontWeight: 700,
                  color: '#000',
                }}
                value={phoneNumber}
              />
            </Box>
            {!this.state.verifyflag && <Box className={`${classes.verifyInputandBtnConatiner1}  otp_input_container`}>
              <OtpInput
                value={this.state.otp}
                inputType="number"
                data-test-id="OtpField"
                shouldAutoFocus={true}
                placeholder="-"
                onChange={(e) => this.handleotpchange(e)}
                numInputs={4}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
              {
                resendCountDownTimer>0?
                  (
                    <Typography>Resend OTP In {resendCountDownTimer} s</Typography>
                  ):(
                    <Button
                      data-test-id="resendOtp"
                      disabled={resendOtpLoading}
                      className={classes.resendbtn}
                      onClick={this.handleResendOTP}
                    >
                      {resendOtpLoading?<CircularProgress size={18}/>:"Resend"}
                    </Button>
                  )
              }
              {
                otpSentSuccessMessage &&  <Typography style={{color:"green",fontSize:"12px"}}>{otpSentSuccessMessage}</Typography>
              }
             {
                otpSentErrorMessage &&  <Typography style={{color:"red",fontSize:"12px"}}>{otpSentErrorMessage}</Typography>
              }
              
            </Box>}
          </DialogContent>
          <DialogActions className={classes.buttondiv}>
            <Box width="100%">
              {
                otpSentErrorMessage && this.state.verifyflag &&  <Typography style={{color:"red",fontSize:"12px",textAlign:"center",margin:"10px 5px"}}>{otpSentErrorMessage}</Typography>
              }
            {this.state.verifyflag && <Button
              data-test-id="verify"
              className={classes.verifyBtn}
              onClick={()=>this.handleSendOTP("send")}
              disabled={sendOtpLoading}
            >
              {sendOtpLoading?<CircularProgress size={18}/>:"Send Otp "}
            </Button>}
            {!this.state.verifyflag && <Button
              className={classes.verifyBtn}
              data-test-id="submit"
              onClick={this.verifyOTP}
              disabled={verificationLoading}
            >
               {verificationLoading?<CircularProgress size={18}/>:"Verify"}
            </Button>}
            </Box>
          </DialogActions>
        </Dialog>
         
        <Box className={classes.headerMainBox}>
          <Grid container className={classes.headerContentBox}>

            <Grid item xs={11} className={classes.withdrawalDetailsBtnGrid}>
              <Box className={classes.btnBox}>
                <Box className={classes.btnBackStyle}
                  data-testid="back_to_paymenthistory_btn"
                  onClick={this.handleBackToEarningsList}>
                  <img src={backArrow} className={classes.arrowStyle} />
                  {configJSON.backTxt}
                </Box>
                <Typography className={classes.headerTextStyle}>{configJSON.headerWithdrawalTxt}</Typography>
              </Box>
              <Box>
                <KnowYourCustomerKycVerification
                    navigation={this.props.navigation}
                    currentUser={this.state.currentUser}
                />
              </Box>

            </Grid>

            <Grid container className={classes.paymentOptionBox}>
              <Grid item xs={12} sm={12} md={8} className={classes.withdrawalDetailsGrid}>
                <Grid container className={classes.withdrawalDetailsContainer}>

                  <Grid item xs={12} >
                    <FormControl component="fieldset" className={classes.FormControlStyle} >
                      <FormLabel style={{ marginBottom: '1vw' }}> {configJSON.savedBankTxt}</FormLabel>
                      <RadioGroup aria-label="selectPaymentOptions" name="selectPaymentOptions"
                        data-testid="payment_option"
                        value={this.state.paymentValue}
                        onChange={this.handlePaymentOptionChange}
                      >

                        {this.renderPayeeList()}

                        <Divider className={classes.dividerStyle}></Divider>

                        <FormControlLabel data-testid='new_bankaccount' value="newBankAccount" control={<StyledRadio />}
                          label={<span className={classes.radioLabelSavedBtnStyle}>{configJSON.newBankAccTxt}</span>}
                        />

                      </RadioGroup>
                    </FormControl>
                    {this.state.paymentValue == 'newBankAccount' && <Formik
                      enableReinitialize={true}
                      initialValues={this.state.bankDetailsData}
                      onSubmit={this.handleSubmitBankDetails}
                      validationSchema={BankDetailsValidation}
                      data-testid='formik'
                    >
                      {() => (
                        <Form translate={undefined}>

                         {this.renderNewBankForm()}

                        </Form>
                      )}
                    </Formik>
                    }
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={3} className={classes.withdrawalDetailsGrid}>

                {this.renderWithdrawArea()}
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <DashboardFooter history={undefined} location={undefined} />
      </ThemeProvider>

      // Customizable Area End
    );
  }
}
// Customizable Area Start

const styles = () => ({
  taxItemContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
 },
 taxItemText:{
    color:"#8083a3",
    fontSize:"12px"
 },
  headerMainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  headerContentBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '88%',
    flexDirection: 'column' as const,
  },
  arrowStyle: {
    width: '20px',
    height: '20px',
    marginRight: '0.5vw',
  },
  btnBackStyle: {
    width: '100px',
    height: '46px',
    borderRadius: '23px',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ededed',
    },
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: "wrap" as const,
  },
  buttondiv: { justifyContent: "center", paddingBottom: "10px" },
  resendbtn: {
    cursor: 'pointer',
    minWidth: '60px',
    borderRadius: '23px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textTransform: 'none' as const,
    height: '30px',
    color: '#f2f2f2',
    backgroundColor: "#ec952d",
    '&:hover': {
      backgroundColor: "#ec952d",
    },
  },
  verifyBtn: {
    cursor: 'pointer',
    borderRadius: '23px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textTransform: 'none' as const,
    height: '35px',
    color: '#f2f2f2',
    backgroundColor: "#ec952d",
    width: "100%",
    '&:hover': {
      backgroundColor: "#ec952d",
    },
  },
  mainmodal: {
    "& .MuiDialog-paperWidthSm": {
      padding: "10px",
      borderRadius: " 20px"
    }
  }
  ,
  paymentTxtTitle: {
    color: '#222222;',
    fontSize: '14px',
    fontWeight: 700,
  },
  headerTextStyle: {
    color: '#222222',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 700,
    margin: '2vw 1vw',
  },
  withdrawalDetailsGrid: {
    margin: '0 1vw 2vw 1vw',
    width: "100%",
    flexWrap: "wrap" as const,
  },
  withdrawalDetailsBtnGrid: {
    margin: '1vw 1vw 1vw 0',
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: "wrap" as const,

  },

  withdrawalDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2vw',
    borderRadius: '14px',
    border: '1px solid #cfd0d1',
    flexWrap: "wrap" as const,
    "@media(max-width:767px)": {
      padding: "4vw"
    },
  },
  paymentAmtDetailsBox: {
    display: 'flex',
    padding: '1vw',
    borderRadius: '14px',
    border: '1px solid #cfd0d1',
    flexWrap: "wrap" as const,
    flexDirection: "column" as const,
    "@media(max-width:767px)": {
      padding: "4vw"
    },
  },

  paymentOptionBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexWrap: "wrap" as const,
    padding: "20px"
  },

  withdrawalBtnStyle: {
    margin: "20px 0 0 0",
    fontSize: '14px',
    fontWeight: 700,
    minWidth: '100px',
    height: '46px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    textTransform: 'none' as const,
    '&:disabled': {
      backgroundColor: '#F2E1B5',
      color: '#F7F0DD',
      cursor: 'not-allowed',
    },
  },
  freelancer_withdraw_button:{
    backgroundColor: '#ec952d',
    '&:hover': {
      backgroundColor: '#ec952d',
    },
  },
  sponsor_withdraw_button:{
    backgroundColor: '#00A7A2',
    '&:hover': {
      backgroundColor: '#00A7A2',
    },
  },
  inputLabelTxt: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "black",
    padding: "0px"
  },
  verifyInputandBtnConatiner: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "100%"
  },
  verifyInputandBtnConatiner1: {
    display: "flex",
    alignItems: "center",
    flexDirection:"column",
    gap: "15px",
    width: "100%", marginTop: 30, marginBottom: 30
  }as const,
  savedCardImg: {
    width: '30px',
    height: '30px',
    marginRight: '1vw',
  },
  radioLabelSavedBtnStyle: {
    fontSize: "15px",
    fontWeight: 600,
    color: '#222222',
    marginLeft: '1vw'
  },
  savedCardBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1vw'
  },
  savedCardTxtBox: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column' as const,
  },

  SavedCardNo: {
    fontSize: "12px",
    fontWeight: 500,
    color: '#8083a3'
  },
  dividerStyle: {
    margin:"20px 10px",
    width: '100%'
  },
  FormControlStyle: {
    width: "100%"
  },
  taxInfoBox: {
    display: 'flex',
    flexWrap: "wrap" as const,
    justifyContent: 'center'
  },
  taxInfoTxtStyle: {
    fontSize: "10px",
    fontWeight: 500,
    color: '#8083a3',
    // marginLeft: '5px'
  },
  taxInfoImgStyle: {
    height: '15px',
    width: '15px',
  },
  taxInfoStyle: {
    fontSize: "10px",
    fontWeight: 600,
    color: '#222',
    marginLeft: '5px'
  },
  paypalImg: {
    width: '100%',
    marginLeft: '1vw',
  },
  taxMainBox: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  formContainer: {
    backgroundColor: "#fff",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    padding: 24,
    marginTop: 18,
    "@media(max-width: 767px)": {
      padding: 0
    },
  },
  formTitle: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#222222",
    margin: 0,
    padding: '8px'
  },
  commonBtn: {
    minWidth: "80px",
    height: '30px',
    fontWeight: 500,
    fontSize: "12px",
    borderRadius: '30px',
    textTransform: 'none' as const,
    border: 'none',
    padding: '5px 10px',
    margin: '20px',
  },
  cancelBtn: {
    backgroundColor: "#f2f2f2",
    color: "#222",
  },
  submitBtn: {
    backgroundColor: "#ec952d",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#eb9734',
    },
  },
  sponsor_submit_button: {
    backgroundColor: "#00A7A2",
    color: "#fff",
    '&:hover': {
      backgroundColor: '#00A7A2',
    },
  },
  bankBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
    width: '98%'
  },
})
export const componentStyle={
  styles:styles()
}
export default withStyles(styles)(FreelancerWithdrawal);
// Customizable Area End