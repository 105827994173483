import React from 'react';
import {
  Chip, Paper, Box, Grid, Typography
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import {
  copy
} from "../../blocks/joblisting/src/assets";
import { message } from "../../blocks/auctionbidding/src/assets"
import {
  avtar
} from "../../blocks/dashboard/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../blocks/auctionbidding/src/MyBid.css";
import { IProjectData } from '../../blocks/dashboard/src/types';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import {download,note} from '../../blocks/scheduling/src/assets'
export const document2 = require('./document2.svg')
interface ProjectChipsProps {
  name: string;
  isActive: boolean;
  state: any;
}

interface ProjectDisplayProps {
  projectList: IProjectData[];
  isShowMore: boolean;
  onTabChange: (index: number) => void;
  tabDataList: ProjectChipsProps[];
  navigate: any;
  loading: boolean;
  loadMore: () => void;
  type: string;
  onSendMessage?: (freelancerId: string, projectId: string) => void;
  shareProject?: (projectId: string) => void;
  onEdit?:(redirect?:boolean,id?:string)=>void;
  copyURL?: (projectId:string) => void;
  page?:string;
  onDraftReuse?:(id:string)=>void;
  onDraftDelete?:(id:number)=>void;
}
const configJSON =require('../../blocks/joblisting/src/config.js')

export const onConvertFileSize = (bytes: number) => {
  if (bytes >= 1024 * 1024) {
    return (`(${(bytes / (1024 * 1024)).toFixed(2)}MB)`);
  } else {
    return (`(${(bytes / 1024).toFixed(2)}KB)`);
  }
}

export const handleDownloadFile = async (event:any, url:string, fileName:string) => {
  event.stopPropagation();
  if (typeof window !== 'undefined' && window.document) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file from ${url}: ${response.statusText}`);
      }

      const blob = await response.blob();
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }
};

const classes:any = {
  filesBox: {
    width: '100%'
  },
  reviewFilesLabel: {
    color: "#222222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    marginTop: "5px"
  },
  reviewSubmitFiles: {
    display: "flex",
    columnGap: 10,
    marginBottom: 5,
    marginTop: 10
  },
  milestoneGridImage: {
    width: "20px",
    marginRight: "1vw",
    height: "20px",
  },
  downloadItemBorder: {
    marginBottom: '1vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1vw',
    borderRadius: '10px',
    border: '1px solid #e4e6e8',
    backgroundColor: '#ffffff',
    width: '88%',
    '@media (max-width: 350px)': {
      width: "120px"
    }
  },
  downloadItemBox: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadfileName: {
    color: '#222222',
    fontSize: '12px',
    fontWeight: 600,
    marginRight: '15px',
    '@media (max-width: 500px)': {
      width: "100px"
    },
    '@media (max-width: 350px)': {
      width: "70px"
    }
  },
  downloadfileSize: {
    color: 'rgba(128,131,163,0.76)',
    fontSize: '10px',
    fontWeight: 500,
  },
  downloadIconStyle: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    cursor: 'pointer',
    marginBottom: "8px",
    height: "20px"
  },
  noFileFound: {
    color: "#757575",
    fontWeight: "bold",
    backgroundColor: "#f9f9f9",
    opacity: 0.9
  }
}

export const renderProjectFile = (doc:any) => {
  return <>
    <Box style={classes.filesBox}>
      <Box style={classes.reviewSubmitFiles}>
        <img src={note} alt="files" />
        <Typography style={classes.reviewFilesLabel}>
          {"Project File"}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {doc.length > 0 ?
          <Grid item xs={12} sm={6} md={6} key={doc[0].id}>
            <Box style={classes.downloadItemBorder}>
              <Box style={classes.downloadItemBox}>
                <img style={classes.milestoneGridImage} src={document2} />
                <Typography style={classes.downloadfileName}>{(doc[0].name)}</Typography>
                <Typography style={classes.downloadfileSize}>{onConvertFileSize(doc[0].size)}</Typography>
              </Box>
              <Box>
                <img
                  src={download}
                  style={classes.downloadIconStyle}
                  alt="download1"
                  onClick={(e) => handleDownloadFile(e, doc[0].url, doc[0].name)}
                />
              </Box>
            </Box>
          </Grid>
          :
          <Grid item xs={12} sm={6} md={6}>
            <Box style={classes.downloadItemBorder}>
              <div style={classes.noFileFound}>No file found</div>
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
  </>
}

const ProjectDisplay: React.FC<ProjectDisplayProps> = ({
  projectList,
  isShowMore,
  onTabChange,
  tabDataList,
  navigate,
  loading,
  loadMore,
  type,
  onSendMessage,
  shareProject,
  onEdit,
  copyURL,
  page,
  onDraftReuse,
  onDraftDelete
}) => {
  function mapExperienceLevel(experienceValue: any) {
    return `${experienceValue} years`
  }
 const handleCopy = async (projectUrl:string) => {
    try {
      await navigator.clipboard.writeText(projectUrl);
      toast.success("Link Copied")
    } catch (error) { toast.error('Failed to copy text');}
  }

  const handleSendMessage=(e:any,data:any) => {
    e.stopPropagation();
    if (onSendMessage) {
      onSendMessage(data?.attributes?.Hired_freelancers?.account_id?.toString(), data?.id)
    }else{
      navigate("Chat")
    }
  }

  return (
    <>
      <div className="projectchips-container" data-test="active-chip" style={{ margin: "15px 0" }}>
        {tabDataList?.map((chip: any, index: number) => (
          <Chip
            data-test-id={`active-chip${index}`}
            key={chip.name}
            label={chip.name}
            style={{
              backgroundColor: chip.isActive ? "#00A7A2" : "white",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              margin: 0,
              flexDirection: "row",
              fontWeight: 700,
              borderRadius: " 30px",
              color: chip.isActive ? "#fff" : "#222",
              padding: chip.isActive ? "7px 20px": "7px 0",
              height: "40px",
            }}
            onClick={() => {
              onTabChange(index)
            }}
            clickable
          />
        ))}
      </div>

      {
        !loading && projectList?.length === 0 && (
          <Paper className="border-block p-0 projectloading-main" >
            <div className='projectloading'> <h2>No Data Found</h2></div>
          </Paper>
        )
      }
      {
        loading && projectList?.length === 0 && (
          <Paper className="border-block p-0 projectloading-main" >
            <div className='projectloading'>
              <CircularProgress size={28} />
            </div>
          </Paper>
        )
      }
      <div className="myProjectHeight">
      {projectList?.length > 0 && projectList?.map((data, index: number) => {
        const numberOfProposals = data?.attributes?.proposal_count || 0;
        const sponsorCurrencyConversion=data?.attributes.currency_conversion?.to;
        const estimatedBudget = data?.attributes?.budget_type === "hourly_price" ? `${data?.attributes.currency_conversion?.to?.currency_symbol}${data?.attributes?.currency_conversion?.to?.amount} /hr` : `${data?.attributes.currency_conversion?.to?.currency_symbol}${data?.attributes?.currency_conversion.to.amount}`
        const eastimatedTime = data?.attributes?.EST_time || 0;
        const projectType = data?.attributes?.budget_type === "hourly_price" ? "Hourly Rated" : "Fixed Price";
        const profileImage = data?.attributes?.Hired_freelancers_photo?.url;
        const escrowAmount=`${sponsorCurrencyConversion?.currency_symbol} ${sponsorCurrencyConversion?.escrow_amount || 0}`;
        const totalPaid=`${sponsorCurrencyConversion?.currency_symbol} ${sponsorCurrencyConversion?.total_paid_amount || 0}`;
        const isFixedPrice=data?.attributes?.budget_type !== "hourly_price";
        const projectURL=`${window.origin}/QuoteProjectDetails/${data.id}`;
        const doc:any = data?.attributes?.documents ?? []
        return (
          
          <Paper className="border-block p-0" test-id="abc">
            {type == "active" &&
              
              <>
                <div className="design-logo-block">
                  <h3>{data.attributes.project_name} </h3>
                  <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                  <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                  <span>{data.attributes.posted_at}</span>
                  <br />
                  <Chip
                    data-test-id="copy-chip"
                    style={{
                      width: '100%',
                      justifyContent: 'flex-start',
                      marginTop: '20px',
                      background: 'rgb(246, 247, 247)',
                      minHeight: '40px',
                      position: 'relative',
                      paddingRight: '30px',
                      height: 'auto'
                    }}
                    icon={<LinkIcon />}
                    label={projectURL}
                    onDelete={()=>handleCopy(projectURL)}
                    deleteIcon={<img src={copy} className="copy-icon" />}
                    className='copy_link_section'
                  />
                  {renderProjectFile(doc)}
                  <div className="chips-container" id='data-test'>
                    {data.attributes.skills?.map((data: any, index: number) => {
                      return (
                        <ul>
                          <li key={index}>
                            <Chip
                              label={data.name}
                              className='chipStyle'
                            />
                          </li>
                        </ul>
                      )
                    })}
                  </div>
                  <p className='descriptionStyle'>
                    {data.attributes.description}
                  </p>
                  <div className="design-content-project" data-test='div' >
                    <div className="design-details">
                      <p>Estimated Budget</p>
                      <h5>{estimatedBudget}</h5>
                    </div>
                    <div className="design-details" data-test='Experience'>
                      <p>Experience</p>
                      <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                    </div>
                    <div className="design-details">
                      <p>Language</p>
                      <h5>{data.attributes.language_of_converse}</h5>
                    </div>
                    <div className="design-details">
                      <p>Freelancer Hired</p>
                      <h5>{data.attributes.freelancer_hired == true ? "Yes" : "No"}</h5>
                    </div>
                  </div>
                </div>
                <div className="project_container1"></div>
                <div className='design-logo-block project_edit_invite'>
                  <button style={{fontWeight:"bold"}}  className="Praposal_view" onClick={() => {
                    navigate("ListOfAllPraposals", { id: data?.id })
                  }}>
                    View Proposals &nbsp;&nbsp; <span className='praposal_span'>

                      {numberOfProposals}</span>

                  </button>
                  <button style={{fontWeight:"bold"}}  className="Praposal_view" onClick={() => {
                    onEdit && onEdit(true,data.id)
                  }}>
                    Edit Project
                  </button>
                  <button style={{fontWeight:"bold"}} className="total_bids" onClick={() => {
                     navigate("freelancers", { projectId: data?.id })
                  }}>
                    Invite Freelancer
                  </button>
                  <button className="total_bids">
                    <Link to="/Chat"><img src={message} alt="message" width={25} /></Link>
                  </button>
                </div>
              </>}
            {
              (type == 'inprogress' || type == 'in-progress') &&
              <div className="design-logo-block" onClick={() => {
                navigate("Projects", { type: "scheduling", status: "projectinprogress", id: data.id })
              }}>
                <h3>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <br />
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Hired Freelancers</span>{" "}
                </div>
                <div className="active-bids-block-wrapper d-flex">
                  <div className="active-bids-block-content d-flex" style={{ padding: "5px 0px 0px" }}>
                    {
                      profileImage ? <img src={profileImage} alt="person-image" style={{ height: "40px", width: "40px" }} />
                        : <AccountCircleIcon style={{ height: "40px", width: "40px" }} />

                    }
                    <div className="active-bids-content" style={{ padding: "6px 6px 0px" }}>
                      <p>{data?.attributes?.Hired_freelancers?.full_name}</p>
                    </div>
                  </div>
                  {<div className="active-bids-details">
                    <button className="total_bids" style={{
                      maxWidth: "100%", width: "100%", fontSize:

                        "13px", padding: "5px 18px"
                    }}
                      onClick={(e:any)=>handleSendMessage(e,data)}
                    >

                      <img src={message} alt="message" width={25} />
                    </button>
                  </div>}
                </div>
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className='chipStyle'
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>
                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap" as const, rowGap: "18px" as const,

                  columnGap: "50px",
                }} data-test='div' >
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                  }
                 
                  <div className="design-details">
                    <p>Total Amount Paid</p>
                    <h5>{totalPaid}</h5>
                  </div>
                  <div className="design-details">
                    <p>Total Amount in Escrow</p>
                    <h5>{escrowAmount} </h5>
                  </div>
                  <div className="design-details">
                    <p>No. of Milestone Completed</p>
                    <h5> {data?.attributes?.No_of_milestone_completed} </h5>
                  </div>
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
            }

            {
              type == 'draft' &&
              <>
              <div className="design-logo-block">
                <h3 className='projectName_style'>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className= "chipStyle"
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>
                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap", rowGap: "18px",

                  columnGap: "50px"
                }} data-test='div' >
                  
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                  }
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
                <div style={{ display: "flex", gap: "10px "}} className='design-logo-block'>
                <button style={{fontWeight:"bold",padding:"10px 20px"}}  className="Praposal_view" onClick={() => {
                   if(onDraftReuse){
                    onDraftReuse(data.id)
                   }
                }}>
                  Reuse & Edit
                </button>
                <button style={{fontWeight:"bold",marginRight:"20px",padding:"10px 20px"}} className="total_bids" onClick={() => {
                   if(onDraftDelete){
                    onDraftDelete(Number(data.id))
                   }
                }}>
                  Delete
                </button>
              </div>
             </>
            }
            {
              (type == 'delete' || type == "deleted" || type =="closed") &&
              <div className="design-logo-block" style={{cursor:"pointer"}} onClick={() => {
                navigate("Projects", { type: "scheduling", status: "closedproject", id: data.id })
              }}>
                <h3>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <br />
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Hired Freelancers</span>{" "}
                </div>
                <div className="active-bids-block-wrapper d-flex">
                  <div className="active-bids-block-content d-flex" style={{ padding: "5px 0px 0px" }}>
                    {
                      profileImage ? <img src={profileImage} alt="person-image" style={{ height: "40px", width: "40px" }} />
                        : <AccountCircleIcon style={{ height: "40px", width: "40px" }} />

                    }
                    <div className="active-bids-content" style={{ padding: "6px 6px 0px" }}>
                      <p>{data?.attributes?.Hired_freelancers?.full_name}</p>
                    </div>
                  </div>
                  {<div className="active-bids-details">
                    <button className="total_bids" 
                        onClick={(e:any)=>handleSendMessage(e,data)}
                    style={{
                        maxWidth: "100%", width: "100%", fontSize:

                        "13px", padding: "5px 18px"
                    }}>

                      <img src={message} alt="message" width={25} />
                    </button>
                  </div>}
                </div>
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className='chipStyle'
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>
                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap", rowGap: "18px",

                  columnGap: "50px"
                }} data-test='div' >
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                    }
                  <div className="design-details">
                    <p>Total Amount Paid</p>
                    <h5>{totalPaid}</h5>
                  </div>
                  <div className="design-details">
                    <p>Total Amount in Escrow</p>
                    <h5>{escrowAmount} </h5>
                  </div>
                  <div className="design-details">
                    <p>No. of Milestone Completed</p>
                    <h5> {data?.attributes?.No_of_milestone_completed} </h5>
                  </div>
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
            }
            {
              type == 'onhold' &&
              <div className="design-logo-block"
              onClick={() => {
                navigate("Projects", { type: "scheduling", id: data.id, status: "projectonhold" })
              }}>
                <h3>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <br />
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Hired Freelancers</span>{" "}
                </div>
                <div className="active-bids-block-wrapper d-flex">
                  <div className="active-bids-block-content d-flex" style={{ padding: "5px 0px 0px" }}>
                    {
                      profileImage ? <img src={profileImage} alt="person-image" style={{ height: "40px", width: "40px" }} />
                        : <AccountCircleIcon style={{ height: "40px", width: "40px" }} />

                    }
                    <div className="active-bids-content" style={{ padding: "6px 6px 0px" }}>
                      <p>{data?.attributes?.Hired_freelancers?.full_name}</p>
                    </div>
                  </div>
                </div>
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className='chipStyle'
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>
                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap", rowGap: "18px",

                  columnGap: "50px"
                }} data-test='div' >
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                  }
                  <div className="design-details">
                    <p>Total Amount Paid</p>
                    <h5>{totalPaid}</h5>
                  </div>
                  <div className="design-details">
                    <p>Total Amount in Escrow</p>
                    <h5>{escrowAmount} </h5>
                  </div>
                  <div className="design-details">
                    <p>No. of Milestone Completed</p>
                    <h5> {data?.attributes?.No_of_milestone_completed} </h5>
                  </div>
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
            }
            {

              type == 'completed' &&
              <div className="design-logo-block"
                onClick={() => {
                  navigate("Projects", { type: "scheduling", id: data.id, status: "completedproject" })
                }}>
                <h3>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <br />
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Hired Freelancers</span>{" "}
                </div>
                <div className="active-bids-block-wrapper d-flex">
                  <div className="active-bids-block-content d-flex" style={{ padding: "5px 0px 0px" }}>
                    {
                      profileImage ? <img src={profileImage} alt="person-image" style={{ height: "40px", width: "40px" }} />
                        :  <AccountCircleIcon style={{ height: "40px", width: "40px" }} />

                    }
                    <div className="active-bids-content" style={{ padding: "6px 6px 0px" }}>
                      <p>{data?.attributes?.Hired_freelancers?.full_name}</p>

                    </div>
                  </div>
                </div>
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className='chipStyle'
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>

                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap", rowGap: "18px",

                  columnGap: "50px"
                }} data-test='div' >
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                  }
                  <div className="design-details">
                    <p>Total Amount Paid</p>
                    <h5>{totalPaid}</h5>
                  </div>
                  <div className="design-details">
                    <p>Total Amount in Escrow</p>
                    <h5>{escrowAmount} </h5>
                  </div>
                  <div className="design-details">
                    <p>No. of Milestone Completed</p>
                    <h5> {data?.attributes?.No_of_milestone_completed} </h5>
                  </div>
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
            }
            {
              type == 'dispute' &&
              <div className="design-logo-block"
              onClick={()=>navigate("Projects",{type:"scheduling",status:"disputeproject",id:data.id})}>
                <h3>{data.attributes.project_name}</h3>
                <span>{projectType}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>Est.Time: {eastimatedTime}</span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                <span>{data.attributes.posted_at}</span>
                <br />
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Hired Freelancers</span>{" "}
                </div>
                <div className="active-bids-block-wrapper d-flex">
                  <div className="active-bids-block-content d-flex" style={{ padding: "5px 0px 0px" }}>
                    {
                      profileImage ? <img src={profileImage} alt="person-image" style={{ height: "40px", width: "40px" }} />
                        : <AccountCircleIcon style={{ height: "40px", width: "40px" }} />

                    }
                    <div className="active-bids-content" style={{ padding: "6px 6px 0px" }}>
                      <p>{data?.attributes?.Hired_freelancers?.full_name}</p>

                    </div>
                  </div>
                </div>
                <div className="project_container1" style={{ margin: "0 -25px", padding: "5px" }}></div>
                <div className="chips-container" id='data-test'>
                  {data.attributes.skills?.map((data: any, index: number) => {
                    return (
                      <ul>
                        <li key={index}>
                          <Chip
                            label={data.name}
                            className='chipStyle'
                          />
                        </li>
                      </ul>
                    )
                  })}
                </div>
                {renderProjectFile(doc)}
                <p className='descriptionStyle'>
                  {data.attributes.description}
                </p>
                <div className="design-content d-flex" style={{
                  flexWrap: "wrap", rowGap: "18px",

                  columnGap: "50px"
                }} data-test='div' >
                  {
                    isFixedPrice && (
                      <div className="design-details">
                        <p>Total Budget</p>
                        <h5>{estimatedBudget}</h5>
                      </div>
                    )
                  }
                  <div className="design-details">
                    <p>Total Amount Paid</p>
                    <h5>{totalPaid}</h5>
                  </div>
                  <div className="design-details">
                    <p>Total Amount in Escrow</p>
                    <h5>{escrowAmount} </h5>
                  </div>
                  <div className="design-details">
                    <p>No. of Milestone Completed</p>
                    <h5> {data?.attributes?.No_of_milestone_completed} </h5>
                  </div>
                  <div className="design-details" data-test='Experience'>
                    <p>Experience</p>
                    <h5>{mapExperienceLevel(data.attributes.expected_experience)}</h5>
                  </div>
                  <div className="design-details">
                    <p>Language</p>
                    <h5>{data.attributes.language_of_converse}</h5>
                  </div>
                </div>
              </div>
            }
          </Paper>
         
        )
      })
      }
      {
        loading && projectList?.length > 0 && (
          <Paper className="border-block p-0 projectloading-main" >
            <div className='projectloading'>
              <CircularProgress size={28} />
            </div>
          </Paper>
        )
      }
      {
        isShowMore && !loading && (
          <div className="news-button">
            {projectList?.length > 0 ?<button className="view-all" onClick={loadMore} style={{background:"#f2f2f2",fontWeight:"bold"}}>
             {page == "dashboard" ? "View all projects" : "Show More"}               <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "#000000" , marginTop:"1px" }} height="1em" viewBox="0 0 320 512">
                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
              </svg>
            </button>: null}
          </div>
        )
      }
      </div>
    </>
  );
};
export default ProjectDisplay;