import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { ChangeEvent } from "react";
import { INegotiation } from "../../dashboard/src/freelancer-projects/bid.types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    negotiation: INegotiation;
    isLastItem: boolean;
    isFixedPrice: boolean;
    bid_id:string;
    relodeBid:()=>void;
    profileCurrency:string;
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    isOpen: boolean;
    anchorEl: any;
    bidAmount:string;
    sendNewBidLoading:boolean;
    bidAmountError:string;
    rejectBidLoading:boolean;
    acceptBidLoading:boolean;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class NegotiationItemController extends BlockComponent<Props, S, SS> {
    sendNewBidApiCallId:string="";
    rejectBidApiCallId:string="";
    acceptBidApiCallId:string="";
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [];
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            anchorEl: null,
            isOpen:false,
            bidAmount:"",
            sendNewBidLoading:false,
            bidAmountError:"",
            rejectBidLoading:false,
            acceptBidLoading:false
           
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
          const apiReqCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (apiReqCallId === this.sendNewBidApiCallId) {
            this.handleSendNewBidPayload(responseJson);
          }else if (apiReqCallId === this.acceptBidApiCallId) {
            this.handleAcceptBidPayload(responseJson);
          }else if (apiReqCallId === this.rejectBidApiCallId) {
            this.handleRejectBidPayload(responseJson);
          }
        }
      }
      handleSendNewBidPayload=(responseJson:any)=>{
        this.setState({
            sendNewBidLoading:false,
        })
        if(responseJson?.data?.id){
            toast.success("New Quote Sent Successfully!");
            this.props.relodeBid();
            this.setState({
                isOpen:false,
                bidAmount:""
            })
        }else{
            toast.error("New Quote Sent Failed!")
        }
      } 
      handleAcceptBidPayload=(responseJson:any)=>{
        this.setState({
            acceptBidLoading:false,
        })
        if(responseJson?.status===200 && responseJson?.message){
            toast.success(configJSON.bidAcceptedSuccess);
            this.props.relodeBid();
        }else{
            toast.error(configJSON.bidAcceptedFailed)
        }
      } 
       handleRejectBidPayload=(responseJson:any)=>{
        this.setState({
            rejectBidLoading:false,
        })
        if(responseJson?.status===200 && responseJson?.message){
            toast.success(configJSON.bidRejectedSuccess);
            this.props.relodeBid()
        }else{
            toast.error(configJSON.bidRejectedFaied)
        }
      }
    
    handleChangeAmount=(e:ChangeEvent<HTMLInputElement>)=>{
        this.setState({
            bidAmount:e.target.value
        })
    }
    formateDate=(date:string)=>{
        const inputDate = new Date(date);
        const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        ];
        const day = inputDate.getUTCDate();
        const month = months[inputDate.getUTCMonth()];
        const year = inputDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }
    handleTooltipClose = () => {
        this.setState({ isOpen: false });
    };
    handleTooltipOpen = (event: any) => {
        this.setState({ isOpen: true, anchorEl: event?.currentTarget });
    };
    rejectBid=(negotiationId:string)=>{
        this.setState({
            rejectBidLoading:true
        });
        const token = localStorage.getItem("loginSuccessToken");
        const header = {
          token: token,
          "Content-Type": "application/json",
        };
        const apiRequestMessage = CreateRestApiMessage({
          header,
          apiUrl: configJSON.changeBidStatusApiUrl,
          body: JSON.stringify({
              bid_id:this.props.bid_id,
              negotiate_id: negotiationId,
              status: "rejected"
          }),
          method: "POST",
        });
    
        this.rejectBidApiCallId = apiRequestMessage.messageId;
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    }
    acceptBid=(negotiationId:string)=>{
        this.setState({acceptBidLoading:false});
        const token = localStorage.getItem("loginSuccessToken");
        const header = {
          token: token,
          "Content-Type": "application/json",
        };
        const apiRequestMessage = CreateRestApiMessage({
          header,
          apiUrl: configJSON.changeBidStatusApiUrl,
          body: JSON.stringify({
              bid_id:this.props.bid_id,
              negotiate_id: negotiationId,
              status: "accept"
          }),
          method: "POST",
        });
    
        this.acceptBidApiCallId = apiRequestMessage.messageId;
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    }
    sendNewBid=()=>{
        const {bidAmount}=this.state;
        if(!bidAmount?.trim() || Number(bidAmount)<=0){
            this.setState({
                bidAmountError:"Please enter valid Quote Amount"
            })
            return;
        }
        this.setState({
            sendNewBidLoading: true,
            bidAmountError:""
          });
          const token = localStorage.getItem("loginSuccessToken");
          const header = {
            token: token,
            "Content-Type": "application/json",
          };
          const apiRequestMessage = CreateRestApiMessage({
            header,
            apiUrl: `${configJSON.sendNewBidApiUrl}?amount=${bidAmount}&bid_id=${this.props.bid_id}`,
            body: null,
            method: "GET",
          });
      
          this.sendNewBidApiCallId = apiRequestMessage.messageId;
          runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    }
    isNegotiationsFromSponsor=(role:string|null)=>{
        if(!role){
            return false;
        }
        return role?.toLowerCase()?.includes("sponsor") || role?.toLowerCase()?.includes("both");
    }
}
