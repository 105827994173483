
// Customizable Area Start
import React from "react";
import ProjectFilterController, {
  Props,
  configJSON,
} from "./ProjectFilterController.web";
import {
  Typography,
  Box,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { filterIcon } from "../assets";
import { withStyles } from "@material-ui/core/styles";
import GenericTab from "../../../../components/src/GenericTab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MultiSelect from "../../../../components/src/MultiSelect.web";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
let sortingItems = [
  "Newest First",
  "Oldest First",
  "Highest Budget First",
  "Lowest Budget First",
  "Most Quotes First",
  "Least Quotes First",
];
const TabTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        opacity: "none !important",
        borderRadius: '30px',
        textTransform: 'none',
        fontWeight: 'bold',
        color:'red',
        width: 'auto',
        '&:hover': {
          backgroundColor: 'red',
        },
      },
    },
    MuiTab: {
      textColorInherit: {
        color: '#8083A3', 
        opacity: 1,
      },
    },
  },
});
const theme = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#8083A3',
      },
    },
    MuiInputBase:{
      input:{
        color:'#8083A3',
        fontFamily:'Inter',
        fontSize:'14px',
        fontWeight:700,
        fontStyle:'normal'
      }
    },
    MuiSelect:{
      selectMenu:{
        height:'24px'
      }
    }
  },
});
class ProjectFilter extends ProjectFilterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if(this.props.isUserFreelancer){
      sortingItems = sortingItems.slice(0,4);
    }
    const isUrlFreelancers = window.location.pathname.includes("/freelancers")
    if(isUrlFreelancers){
      sortingItems = sortingItems.slice(0,2);
    }
    const classes = this.props.classes;
    const {
      openFilter,
      searchText,
      activeSortingItem,
      dataList,
      selectedCategories,
      selectedExperience,
      selectedLanguage,
      selectedLocation,
      selectedSkill,
      selectedSpecialitie,
    } = this.state;
    const loading = this.props?.loading;
    const activeTabColor = this.props?.activeTabColor || "#EC952D";
    const {sortInOutside, disableSort}=this.props;
    return (
      <>
        <Box className="resSearchBarBox" style={webStyle.searchBarBoxBid}>
          <TextField
            data-testid="searchInputId"
            variant="outlined"
            fullWidth
            placeholder={this.state.searchPlaceholder}
            className="input-box"
            style={{ width: "100%" }}
            onKeyPress={this.handleSearchTextKeyPressed}
            value={searchText}
            onChange={this.handleSearchTextChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                    <button
                      onClick={this.handleClearSearchText}
                      className={searchText ? classes.clearIconButton : ""}
                      style={searchText ? {backgroundColor:activeTabColor} : { visibility: "hidden", width: "46px", height: "46px", marginRight: "5px" }}
                      disabled={!searchText}
                    >
                      <ClearIcon style={searchText ? {backgroundColor:activeTabColor} : { visibility: "hidden" }} />
                    </button>
                  <button
                    onClick={this.onSearchHandler}
                    disabled={loading}
                    style={{
                      backgroundColor: activeTabColor,
                      borderRadius: "50%",
                      width: "46px",
                      height: "46px",
                      border: "none",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: loading ? "notAllowed" : "pointer",
                      opacity: loading ? 0.5 : 1,
                    }}
                  >
                    <SearchIcon />
                  </button>
                </InputAdornment>
              ),
              style: { borderRadius: "35px", paddingRight: "5px" ,display:"flex",justifyContent:"space-between"},
            }}
          />
           {
            !disableSort && sortInOutside && (
              <div style={webStyle.sortDiv}>
              <InputLabel id="select-label" style={webStyle.sortText}>
                 {configJSON.sortAndistoText}
              </InputLabel>
              <FormControl style={webStyle.abc}>
                  <Select
                      data-test-id="optionButtonId"
                      style={{ width: '70%' }}
                      fullWidth
                      labelId="select-label"
                      value={this.state.selectedSort }
                      onChange={this.handleOuterSortChange}
                      displayEmpty
                      disableUnderline
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={() => (
                          <div style={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              zIndex: -1
                          }}>
                              <ExpandMoreIcon />
                          </div>
                      )}
                                                      >
                      <MenuItem value="" disabled>
                          Order
                      </MenuItem>
                      {this.state.sortingOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                              {option.name}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
            </div>
            )
          }
           <style>
            {`
              .MuiInput-formControl .MuiSelect-selectMenu {
                font-weight: 400
              }
              .tabscroll > div {
                overflow: auto !important;
              }
              @media(max-width: 767px) {
                .resSearchBarBox {
                  flex-wrap: wrap;
                }
              }
            `}
           </style>
          <button
            data-testid="toggleFilter"
            onClick={this.handleOpenFilter}
            style={{...webStyle.filterButton,backgroundColor:activeTabColor}}
          >
            <span>
              <img style={{ maxWidth: "unset" }} src={filterIcon} />
            </span>
            {configJSON.filterTxt}
          </button>
        </Box>

        {openFilter ? (
          <Box style={webStyle.projectFilterBox}>
            <Box style={webStyle.filterHeadLayout}>
              <Typography
                style={{ fontSize: "18px", fontWeight: 600, color: "#000" }}
              >
                Filter
              </Typography>
              <Typography>
                <button
                  onClick={this.handleClearSelectedOptions}
                  style={webStyle.closeBtn}
                >
                  <ClearIcon style={{ fontSize: "15px" }} />
                </button>
              </Typography>
            </Box>
            <ThemeProvider theme={TabTheme}>
              {
                !sortInOutside && (
                  <GenericTab
                    data-testid="genericTab"
                    className={"tabLabels"}
                    activeTabColor={activeTabColor}
                    tabLabels={sortingItems}
                    activeTab={activeSortingItem}
                    handleTabChange={this.handleSortingTabChange}
                  />
                )
              }
            
            </ThemeProvider>
 <ThemeProvider theme={theme}>
            <Grid style={{ marginTop: "20px" }} container spacing={2}>
              <Grid item xs={12} sm={6} className="abcdef">
                <MultiSelect
                  data-testid="categories"
                  label="Categories"
                  onChange={this.handleCategoryChange}
                  selectedOptions={selectedCategories}
                  values={dataList.categoriesList.map(list => list.name)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultiSelect
                  data-testid="Speciality"
                  label="Sub Category"
                  onChange={this.handleSpecialityChange}
                  selectedOptions={selectedSpecialitie}
                  values={dataList.specialitieList}
                  disabled={!dataList.specialitieList || dataList.specialitieList.length === 0}
                  disabledPlaceholder="Please Select Category First"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultiSelect
                  data-testid="language"
                  label="Language"
                  onChange={this.handleLanguageChange}
                  selectedOptions={selectedLanguage}
                  values={dataList.languageList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultiSelect
                  data-testid="skills"
                  label="Skills"
                  onChange={this.handleSkillChange}
                  selectedOptions={selectedSkill}
                  values={dataList.skillList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultiSelect
                  data-testid="location"
                  label="Location"
                  onChange={this.handleLocationChange}
                  selectedOptions={selectedLocation}
                  values={dataList.locationList}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <MultiSelect
                  data-testid="experience"
                  label="Experience"
                  onChange={this.handleExperienceChange}
                  selectedOptions={selectedExperience}
                  values={dataList.experienceList}
                />
              </Grid>
            </Grid>
            </ThemeProvider>
            <Box style={webStyle.filterBoxButtons}>
              <button
                data-testid="clearFilter"
                onClick={this.handleClearSelectedOptions}
                style={{
                  color: "#000",
                  borderRadius: "26px",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "0px 18px",
                  background: "#F2F2F2",
                  height: "40px",
                  width: "100px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                data-testid="filterBtn"
                style={{
                  color: "rgb(255, 255, 255)",
                  borderRadius: "26px",
                  fontSize: "14px",
                  fontWeight: 600,
                  padding: "0px 18px",
                  background: activeTabColor,
                  height: "40px",
                  width: "100px",
                  border: "none",
                  cursor:"pointer",
                  // cursor: btnCondition ? "not-allowed" : "pointer",
                  // opacity: btnCondition ? 0.5 : 1,
                }}
                onClick={this.onFilterHandler}
                // disabled={btnCondition}
              >
                Apply
              </button>
            </Box>
          </Box>
        ) : null}
      </>
    );
  }
}

const webStyle = {
  abc: {
    minWidth: 110,
  } as const,
    sortText: {
      marginBottom: '0px',
      color: "#000", 
      fontWeight: 700
      // marginLeft: "22px"
  } as const,
  sortDiv: {
      display: 'flex',
      alignItems: 'center',
      marginRight:"-50px",
      gap: "5px"
  } as const,
  filterButton: {
    color: "rgb(255, 255, 255)",
    borderRadius: "26px",
    fontSize: "14px",
    fontWeight: 600,
    padding: "0px 18px",
    height: "46px",
    width: "104px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchBarBoxBid: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    gap: "30px",
  },
  clearIconButton: {
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    border: "none",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    cursor: "pointer",
  },
  searchBarBox: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    gap: "30px",
  },
  BrowseHeadingBox: {
    marginBottom: "40px",
  },
  boxStyle: {
    backgroundColor: "#F5F5FA",
    padding: "20px 20px",
    borderRadius: "14px",
    border: "1px solid #E4E6E8",
  },
  browseItemsImage: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    gap: "10px",
    color: "#000",
  },
  browseItemslayout: {
    display: "flex",
    cursor: "pointer",
    padding: "20px 0",
    justifyContent: " space-between",
    "&:hover": {
      color: "red",
    },
  },
  projectDescriptionBox: {
    borderRadius: "15px",
    margin: "30px 0",

    border: "1px solid #E4E6E8",
  },
  projectFilterBox: {
    borderRadius: "15px",
    border: "1px solid #E4E6E8",
    margin: "24px 0px",
    padding: "18px 24px",
  },
  filterHeadLayout: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  closeBtn: {
    width: "24px",
    height: "24px",
    background: "transparent",
    border: "1px solid",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",
  },
  filterBoxButtons: {
    display: "flex",
    justifyContent: " end",
    alignItems: "center",
    gap: "30px",
    marginTop: "30px",
  },
  projectHeader: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
  },
  projectSubInfo: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    gap: "20px",
  },
  category: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    marginTop: "20px",
    flexFlow: "wrap",
    gap: " 10px",
    maxWidth: "600px",
  },
  categoryBox: {
    background: "#E4E6E8",
    color: "#8083A3",
    padding: "10px 20px",
    borderRadius: "20px",
  },
  projectDescriptionText: {
    marginTop: "20px",
  },
  projectDetails: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    margin: "20px 0",
  },
  bidTheProjects: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
  },
  loadMoreBtn: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: "300px",
    justifyContent: "center",
    margin: "auto",
  } as const,
  projectDetailsTab: {
    marginTop: "24px",
  },
};

export default withStyles(webStyle)(ProjectFilter);

// Customizable Area End
