import React from 'react'
import { Typography, Box, Divider , Tooltip, Grid } from "@material-ui/core";
import {configJSON} from '../../blocks/dashboard/src/freelancer-projects/FreelancerBrowseProjectController.web'
import { chatIcon,sponserProfile } from '../../blocks/dashboard/src/assets';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { IProjectData } from '../../blocks/dashboard/src/types';
import {Link} from 'react-router-dom'
import { info_circle } from '../../blocks/auctionbidding/src/assets';
import {getProjectCostElement as getProjectCostInMultiCurrency} from './ProjectCardProgress.web';
import { renderProjectFile } from './ProjectDisplay';
interface Props{
    card:IProjectData;
    navigation?:any;
}

const ProjectCard2:React.FC<Props> = ({card, navigation}) => { 


  const projectType=card?.attributes?.budget_type==="hourly_price"?"Hourly Rated":"Fixed Price";
  const eastimatedTime=card?.attributes?.EST_time || 0;
  const getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
    return (
      <>
        {sponsorCost?.currency_symbol} {sponsorCost?.amount} {"   "}
        {  sponsorCost?.currency != freelancerCost?.currency && <>
            <span style={{ color: "#EC952D", fontWeight: 400, fontSize: "12px" }} >({freelancerCost?.currency_symbol}{freelancerCost?.amount})</span>
            <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${sponsorCost?.currency}) into (${freelancerCost?.currency}) based on today's conversion rate`}>
              <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
            </Tooltip></>}
        {hourlyRated && " /hr"}
      </>
    )
  } 

  const convertedCost=card.attributes?.currency_conversion;
  const freelancerConvertedCost=convertedCost?.freelancer;
  const sponsorConvertedCost=convertedCost?.sponsor;
  const doc = card?.attributes?.documents ?? []
    return (
        <Box style={webStyle.projectDescriptionBox}
      //  onClick={()=>{navigation?.navigate("FreelancerProjects", { type: "view-certificate" });}}>
             onClick={()=> navigation.navigate("CompletedPrjSummary",{ projectid:card?.id})}>
        <Box>
            <Typography style={webStyle.cardProjectName}>{card.attributes.project_name}</Typography>
        </Box>
        <Grid container style={webStyle.projectSubInfo}>
            <Grid item>
            <Typography style={webStyle.projectSubInfoType}>{projectType}</Typography>

            </Grid>
            <Grid item>
            <span style={{ color: 'gray', padding:"5px 5px 0px" }}> • </span>
            </Grid>

            <Grid item>
            <Typography style={webStyle.projectSubInfoType}>{eastimatedTime}</Typography>

            </Grid>
            <Grid item>
            <span style={{ color: 'gray',  padding:"5px 5px 0px" }}> • </span>

            </Grid>

            <Grid item>
            <Typography style={webStyle.projectSubInfoType}>{card.attributes.posted_at}</Typography>

            </Grid>
        </Grid>
        <Divider />
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "14px 26px" }}>
            <Box>
            <div style={{ display: "flex", padding: "10px 0 0" }} className='design-logo-block p-0'>
                  <span>Sponsor</span>{" "}
                </div>
                <Box style={{display:"flex" ,alignItems:"center",justifyContent:"space-between"}}>
                {
                    card?.attributes?.profile_image != null ?
                    <img style={{ height: "40px",width:"40px",objectFit:"cover",margin:"4px 8px 0px 0px" }} src={card?.attributes?.profile_image[0]?.url} />:
                    <AccountCircleIcon style={{height: "40px",width:"40px",margin:"4px 8px 0px 0px" }}/>
                }
                <Typography style={{fontSize:"16px", color:"#000"}}>{card.attributes.profile_full_name}</Typography>
                </Box>
            </Box>
            {/* <Box>
            <Link to="/Chat"><img src={chatIcon} alt="message" width={25} /></Link>
            </Box> */}
        </Box>
       <Divider />

        <Box style={webStyle.showSimilarContainer}>
        <Box style={webStyle.category}>
                    {card?.attributes?.skills.map((skill: any) => (
                        <Box style={webStyle.categoryBox}>{skill.name}</Box>
                    ))}
                </Box>
        <Box style={{marginLeft:"1.8vw", marginTop:"20px"}}>
            {renderProjectFile(doc)}
        </Box>
        <Box style={webStyle.projectDescriptionText}>
            <Typography style={webStyle.projectDescriptionTextDesign}>{card.attributes.description}</Typography>
        </Box>
        <Grid container style={webStyle.projectDetails} spacing={2}>
            {
                card?.attributes?.budget_type ==="fixed_price" && (
                    <Grid item xs={12} sm={6} md>
                        <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.totalBudgetTxt}</Typography>
                        <Typography noWrap={true} style={webStyle.projectSub_subDetails}>  
                        {getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}                          
                            </Typography>
                    </Grid>
                )
            }
           
            <Grid item xs={12} sm={6} md>
                <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.totalAmtPaidTxt}</Typography>
                <Typography noWrap={true} style={webStyle.projectSub_subDetails}>{getProjectCostInMultiCurrency(freelancerConvertedCost,sponsorConvertedCost,false,"total_paid_amount")}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md>
                <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.totalAmtEscrowTxt}</Typography>
                <Typography style={webStyle.projectSub_subDetails}>{getProjectCostInMultiCurrency(freelancerConvertedCost,sponsorConvertedCost,false,"escrow_amount")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md>
                <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.totalMilestonesTxt}pc2</Typography>
                <Typography noWrap={true} style={webStyle.projectSub_subDetails}>{card.attributes.No_of_milestone_completed}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.experienceTxt}</Typography>
                <Typography noWrap={true} style={webStyle.projectSub_subDetails}>{card.attributes.experience_required} years</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography noWrap={true} style={webStyle.projectSubDetails}>{configJSON.languageTxt}</Typography>
                <Typography noWrap={true} style={webStyle.projectSub_subDetails}>{card.attributes.language_of_converse}</Typography>
            </Grid>
        </Grid>
        </Box>
    </Box>
      );
}
 
export default ProjectCard2;

const webStyle = {
    root: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    showSimilarContainer: {
        alignItems: "center",
    },
    favouriteIcon: {
        cursor: 'pointer',
        borderRadius: '50%', 
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
        background: '#fff'
    },
    likeIconDesign: {
        borderRadius: '50%',
        cursor: 'pointer',
        background: '#fff',
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
    },
    projectSub_subDetails: {
        display: "inline",
        fontSize: '16px',
        color: '#000',
        fontWeight: 600
    },
    projectSubDetails: {
        color: '8083A3',
        fontSize: '12px'
    },
        messageWithIcon: {
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between",
            padding: "14px 20px",
            height: "46px", 
            border: "none", 
            backgroundColor: "#F2F2F2",
            cursor: "pointer",
            color: "white",
            borderRadius: "23px",
        } as const,
    projectDescriptionTextDesign: {
        fontSize: '14px',
        color: '#000',
        fontWeight: 500
    },
    projectSubInfoType: {
        color: '#8083A3',
        fontSize: '12px',
    },
    projectDescriptionBox: {
        border: '1px solid rgb(228, 230, 232)',
        maxWidth: "915px",
        borderRadius: '15px',
        margin: '24px 0px 24px 8px',
    },
    projectSubInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: "0px 26px 18px 26px",
        gap: '5px',
    },
    cardProjectName: {
        fontSize: '18px',
        fontWeight: 600,
        color: "#000000",
        padding: "24px 26px 8px 26px"
    },
 
    category: {
        display: 'flex',
        // justifyContent: ' space-between',
        alignItems: 'center',
        padding:"17px 26px 0px 26px",
        flexFlow: 'wrap',
        gap: ' 10px',
        maxWidth: '600px',
    },
    showMoreBtn: {
        color: '#000',
        borderRadius: '26px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 18px',
        background: '#F2F2F2',
        height: '40px',
        width: '151px',
        border: 'none',
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
    },
    categoryBox: {
        background: '#E4E6E8',
        color: '#8083A3',
        padding: '10px 20px',
        borderRadius: '20px',
    },
    projectDescriptionText: {
        padding:"14px 26px"
    },
    projectDetails: {
        alignItems: 'center',
        display: 'flex',
        padding:"3px 26px 18px 26px",
    },
    projectDetailss: {
        padding:"0px 26px 18px 26px"
    },
    loadMoreBtn: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '300px',
    } ,

}