import React from "react";
import {
  // Customizable Area Start
  Box,
  Typography,
  LinearProgress,
  Grid,
  Container,
  Paper,
  Avatar,
  CircularProgress,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Button,
  ThemeProvider,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { createTheme } from "@material-ui/core/styles";
import { ArrowForwardTwoTone } from "@material-ui/icons";
import GenericTab from "../../../components/src/GenericTab";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomBarChart from "../../../components/src/CustomBarChart.web";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';

import {
  location1,
  documenttext,
  sms_image,
  time,
} from "./assets";

const theme = createTheme({});

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 4,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 2,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 3,
    partialVisibilityGutter: 30
  }
};

theme.typography.h3 = {
  fontSize: '1.5rem',
  [theme.breakpoints.up('sm')]:{
    fontSize: '3rem',
  },
}
theme.typography.h5 = {
  fontSize: '1rem',
  [theme.breakpoints.up('md')]:{
    fontSize: '1.5em',
  },
}

// Customizable Area End
import FreelancerDashboardController, {
  Props, configJSON
} from "./FreelancerDashboardController.web";
import DashboardFooter from "./DashboardFooter.web";
import FreelancerHeader from "./FreelancerHeader.web";
import ProjectCard from "../../../components/src/ProjectCard.web";
import ProjectLocalTime from "../../../components/src/ProjectLocalTime.web";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { info_circle } from "../../auctionbidding/src/assets";
const tabs = [
  "All",
  "Open Quote Projects",
  "Non-Quote Projects",
];

export default class FreelancerDashboard extends FreelancerDashboardController {


  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  renderCustomizedLabel = async ({
    cx,
    cy,
    midAngle,
    outerRadius,
    index
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    outerRadius: number;
    index: number;
  }) => {
    const radius = outerRadius + 10;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180)) + 90;
    const value = this.state.piedata[index].value;

    return (
      <g>
        <circle cx={x} cy={y} r={20} fill="#fff" stroke="none" />
        <text x={x} y={y} fill="#000000" textAnchor="middle">
          {value}
        </text>
      </g>
    );
  };

  renderGetStarted = () => {
    return  <button style={webStyle.getstartednow_btn} data-testid="getStartedButton">
        <Link className="text-decoration-none" data-testid="getStartedLinkTo" style={webStyle.getStartedButtonLink} to={"/FreelancerProjects/browse-all"}>
          Get Started Now
          <ArrowForwardTwoTone />

        </Link>
      </button>
  }

  trimdata = (value: any) => {
    if (value.length > 12) {
      return value.slice(0, 12) + "..."
    }
    else {
      return value
    }
  }

  renderTalents = () => {
    return (
      <div className="container fake">
        <div className="talent-slider-block" test-id="25">
          <h2>Jobs You Might Be Interested In</h2>
          <div className="talent-slider-wrapper" data-id="abc">
            {this.state?.talentdata && <Carousel
              data-test-id="23"
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass="container-with-dots"
              dotListClass=""
              renderArrowsWhenDisabled={false}
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              renderDotsOutside={false}
              className="fakeclass"
              keyBoardControl
              minimumTouchDrag={80}
              arrows
              pauseOnHover
              renderButtonGroupOutside={false}

              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024
                  },
                  items: 4,
                  partialVisibilityGutter: 40
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0
                  },
                  items: 1,
                  partialVisibilityGutter: 30
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464
                  },
                  items: 2,
                  partialVisibilityGutter: 30
                }
              }}
            >
              {this.state.talentdata.map((data: any, index: number) => {
                return (
                  <div 
                      style={{cursor:"pointer"}} 
                      onClick={this.getNavigation(data.attributes.name)}
                      className="talent-block" id="12"
                   >
                    <div className="talent-image">
                      <img src={data.attributes.category_icon[0].url} alt="category_icon" />
                    </div>
                    <div className="talent-content">
                      <h3>{data.attributes.name}</h3>
                      <p>{data.attributes.project_count} Projects</p>
                    </div>
                  </div>
                )
              })}
            </Carousel>}
          </div>
        </div>
      </div>
    )
  }

  renderCharts = () => {
    return <Paper style={webStyle.borderBlock}>
      {this.state.freelancerProjectListError !== "" ? (
        <>
          <Box style={webStyle.freelancerBoxStyle}>
            <Typography
              style={webStyle.chartHeading}
              data-test-id="chartHeadingForErrors"
              align="left"
            >
              {configJSON.performanceChartLabel}
            </Typography>
          </Box>
          <Typography align="center" style={webStyle.msgTxtStyle} >
            {this.state.freelancerProjectListError}
          </Typography>
        </>
      ) : (
        <>
          {this.state.freelancerProjectListData &&
            this.state.freelancerProjectListData.length === 1 &&
            this.state.freelancerProjectListData[0].project_name === "All Projects" ? (
            <>
              <Box style={webStyle.freelancerBoxStyle}>
                <Typography              
                  style={webStyle.chartHeading}
                  data-test-id="chartHeading"
                  align="left"
                >
                  {configJSON.performanceChartLabel}
                </Typography>
              </Box>
              <Typography align="center" style={webStyle.msgTxtStyle} >
                {configJSON.NoChartLabel}
              </Typography>
            </>
          ) : (
            <>
              <Box style={webStyle.freelancerBoxStyle}>
                {!this.state.projectListLoader ? (
                  <>
                    <Typography
                      style={webStyle.chartHeading}
                      data-test-id="chartHeading"
                      align="left"
                    >
                      {configJSON.performanceChartLabel}
                    </Typography>

                    <Select
                      value={this.state.chartValues}
                      onChange={this.handleChartValueChange}
                      data-testid="chartList"
                      name="chartValues"
                      displayEmpty
                      style={{ width: '110px', marginRight: '10px' }}
                      inputProps={{ 'aria-label': 'Without label' }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {this.state.freelancerProjectListData &&
                        this.state.freelancerProjectListData.map((list) => {
                          return (
                            <MenuItem key={list.project_id} value={list.project_id}>
                              {this.truncateText(list.project_name)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </>
                ) : (
                  <Box style={webStyle.loaderStyle}>
                    <CircularProgress size={24} style={{ color: 'grey' }} />
                  </Box>
                )}
              </Box>
              {!this.state.projectChartLoader ? (
                <CustomBarChart data={this.state.FreelancerProjectChartList} isAllProjects={this.state.chartValues === ""} />
              ) : (
                <Box style={{ height: "400px", ...webStyle.loaderStyle }}>
                  <CircularProgress size={40} />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Paper>
  }

  renderAmount(value: any) {
    const convertedCost=value.attributes?.currency_conversion;
    const freelancerConvertedCost=convertedCost?.freelancer;
    const sponsorConvertedCost=convertedCost?.sponsor;
   
    if (value.attributes.project.bid_status !== "non_bid_project") {
      return (
        <>
          {sponsorConvertedCost?.currency_symbol} {sponsorConvertedCost?.amount}  {value.attributes.project.budget_type === "hourly_price" && " /hr"} {"   "}
          {  sponsorConvertedCost?.currency != freelancerConvertedCost?.currency &&
            <>
              <span style={{ color: "#EC952D", fontWeight: 400, fontSize: "12px" }} >({freelancerConvertedCost?.currency_symbol}{freelancerConvertedCost?.amount} {value.attributes.project.budget_type === "hourly_price" && " /hr"})</span>
              <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${sponsorConvertedCost?.currency}) into (${freelancerConvertedCost?.currency}) based on today's conversion rate`}>
                <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
              </Tooltip></> }
         
        </>
      )}
      else {
          return <span>Non quote</span>;
        }
  }


  renderMoreJobs = () => {
    return <Box style={webStyle.browseMore}>
      <button
        data-test-id="navigateBtn"
        onClick={() => this.props.navigation.navigate("FreelancerProjects", { type: `browse-all` })}
        style={{
          background: "#EC952D",
          color: "#fff",
          padding: "10px 36px 10px 36px",
          border: "none",
          fontSize: "14px",
          borderRadius: "32px",
          fontWeight: "bold",
          cursor: "pointer"
        }}
      >Browse More Jobs
      </button>
    </Box>
  }
  renderProfileCount = () => {
    const { currentUser: loggedInUser } = this.state;
    const fullname = loggedInUser?.attributes?.full_name;
    const photo = loggedInUser?.attributes?.photo?.[0]?.url;
    const city = loggedInUser?.attributes?.city;
    const country = loggedInUser?.attributes?.country;
    const timeZone = loggedInUser?.attributes?.time_zone
    const role = loggedInUser?.attributes?.role_name.toLowerCase().includes("both");
    function generateUserProfileCountElement(userprofilecount: number) {
      if (userprofilecount >= 100) {
        return (
          <h3>Completed</h3>
        );
      } else {
        return (
          <h3 style={{ marginLeft: "17px" }}>
            {userprofilecount + "%"}
          </h3>
        );
      }
    }
    return <Paper
      style={{
        border: "1px solid #E4E6E8",
        position: "relative",
        padding: "25px",
        borderRadius: "14px",
        marginBottom: "18px",
        boxShadow: "none",
        background: "rgb(245 245 250) !important",
        width: '100%',
        boxSizing: 'border-box'
      }}
    >
      <Grid container
      style={{ 
        justifyContent:"space-between",
        gap: "0px",
        display:"flex",
        flexWrap:"wrap",
      }}
      >
        <Grid item xs={8}>
          <Avatar
            style={{ height: "60px", width: "60px" }}
            src={photo}
            alt="Remy Sharp"
          />
          <Typography style={webStyle.profilename_text}>
            {fullname}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{display:"inline-flex", position:"relative"}}>
          <div className="card">
            <div className="percent">
              <svg>
                <circle cx={50} cy={50} r={40} />
                <circle cx={50} cy={50} r={40} style={{ "--percent": role ? this.state?.bothUserProfilePercentage : this.state.userprofilecount } as React.CSSProperties}></circle>
              </svg>
              <div className="number">
              {role ? generateUserProfileCountElement(this.state?.bothUserProfilePercentage): generateUserProfileCountElement(this.state?.userprofilecount)}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box display="flex" pb={3}>
        <Box display="flex" alignItems="center" pr={1}>
          <img style={webStyle.location_img} src={location1} />
          <Typography style={webStyle.subtext}>
            {city}, {country}
          </Typography><span style={{ color: 'gray', margin: '0 5px' , fontWeight:"normal"}}> • </span>
          <img style={webStyle.location_img} src={time} />
          <ProjectLocalTime timezoneData={timeZone} />
        </Box>
        
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={12}>
          <button
            data-testid="navigateToProfileSetup"
            onClick={this.navigateToProfileSetup}
            style={{
              border: "1px solid #000000",
              background: "#ffffff",
              color: " #222222",
              fontFamily: "inter",
              fontSize: "14px",
              width: "100%",
              borderRadius:"25px",
              height:"60px",
              fontWeight: "bold",
              cursor: "pointer"
            }}
          >
        {this.state.userprofilecount >= 100 ? "Go To Your Profile" : "Complete Your Profile"}
          </button>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Link to={"/FreelancerEarnings"}>
            <Button style={{
                background:"#EC952D",
                width:"100%",
                color:"white",
                borderRadius:"25px",
                height:"60px",
                textTransform:"capitalize",
                fontWeight:"bolder",
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between"

              }}
              >
                <Box style={{display:"flex",alignItems:"center",gap:"10px", padding: "0px 10px"}}>
                  <HomeWorkIcon/>
                  View My Earnings
                </Box>
                <ArrowRightAltIcon/>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  }

  renderactiveproject = () => {
    return (
      this.state.activeInProgressProject ? (
        <>
          <Box style={webStyle.activebid_sub_box1} paddingY={2}>
            <Typography style={webStyle.text_fw}>
              {this.state.activeInProgressProject.attributes.project_name}
            </Typography>
            <Box
              alignItems="center"
              justifyContent="space-between"
              style={{ gap: "0px" }}
              display="flex"
            >
              <Box
                paddingY={1.9}
                justifyContent="space-between"
                display="flex"
                alignItems="center"
                data-id="profile_image"
              >
                {this.state.activeInProgressProject.attributes?.profile_image ? <img
                  alt="person-image"
                  data-id="profile_image1"
                  src={this.state.activeInProgressProject?.attributes?.profile_image[0]?.url}
                  style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }}
                />
                  : <AccountCircleIcon data-id="profile_image2" style={{ height: "60px", width: "60px" }} />}
                <Box pl={1}>
                  <Typography
                    style={{ fontSize: "10px", fontFamily: "inter", cursor: "pointer" }}
                  >
                    {this.state.activeInProgressProject?.attributes.profile_full_name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <LinearProgress variant="determinate" value={this.getActiveProjectProgress()} />
            <Typography variant="body2" align="left" style={{ cursor: "pointer" }}>
              {this.getActiveProjectProgress()?.toFixed(0)}% completed
            </Typography>
          </Box>
          <Grid container spacing={1} style={{ marginTop: "10px"}}>
            <Grid item xs={12} sm={6} md={12}>
              <Box
                data-id="inProgressProjectNavigate"
                onClick={() => this.inProgressProjectNavigate("progress")}
                justifyContent="space-between"
                display="flex"
                style={webStyle.buttonDesign}
              >
                <Box display="flex" justifyContent="center">
                <img src={documenttext} />
                <Typography
                  variant="body2"
                  style={webStyle.project_summary}
                >
                  Project Summary
                </Typography>
                </Box>
                <ArrowRightIcon />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Box data-id="inProgressProjectNavigate2" onClick={() => this.inProgressProjectNavigate("message")} display="flex" justifyContent="space-between" 
                style={webStyle.buttonDesign}>
                <Box display="flex" justifyContent="center">
                <img src={sms_image} />
                <Typography style={webStyle.project_msg} variant="body2">
                  Messages
                </Typography>
                </Box>
                <ArrowRightIcon />
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography style={{ textAlign: 'center', padding: '25px', fontSize: '1rem', fontWeight: 400, lineHeight: 1.5, letterSpacing: '0.00938em' }}>No Projects Running</Typography>
      )
    )
  }

  renderViewAllButton = () => {
    if (!this.state.activeBidList?.data?.length) {
      return null;
    }
  
    return (
      <div className="lancerview-all" id="all" onClick={this.navigateToMyBidsProject} data-testId="viewAll">
        <span className="link">
          View All
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            style={{ fill: "rgb(236, 149, 45)" }} 
            height="1em" 
            viewBox="0 0 320 512"
          >
            <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
          </svg>
        </span>
      </div>
    );
  };
  // Customizable Area End

  render() {
    const {
      projectList,
      projectLoading,
      activeTab,
      searchText,

    } = this.state;
    const activeTabColor = this.props?.activeTabColor;
    const loading = this.props?.loading;
    return (
      // Customizable Area Start

      <div style={webStyle.root}>
        <FreelancerHeader
          navigation={this.props.navigation}
          history={undefined}
          location={undefined}
          id={""} route={"dashboard"}
        />
        {this.state.bannerImage?.map((data: any, index: any) => <>
          {data.attributes.advertisement_for.includes("lancer_dashboard_page") &&


            (<div
              className="lancer-banner" style={{ backgroundImage: `url(${data.attributes.image[0]?.url})`, }}
            >
              <div 
                className="banner-containt1"
                >

              <Box>
                <ThemeProvider theme={theme}>
                <Typography
                  variant="h4"
                  align="center"
                  style={webStyle.parallaxtext1}
                >
                  Find Your Next Project and Unleash Your Creativity!
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  style={webStyle.parallaxtext2}
                >
                  Find a Project You'll Like: Joined the Community of Skilled
                  Freelancers
                  <br />and Unleash Your Creativity Today!
                </Typography>
                </ThemeProvider>
                
              </Box>
              <Box  data-testid="getStartedBox" position="absolute" bottom="-25px">

                {this.renderGetStarted()}
                </Box>
              </div>
            </div>)
          }
        </>)}

        {this.renderTalents()}

        <Container style={{ marginTop: "20px", marginBottom: "50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>

              {this.renderCharts()}

              <Box display="flex" justifyContent="space-between">
                <Grid container spacing={2}>

                <Grid item xs={12} sm={6} style={{ padding: theme.spacing(2)}}>
                  <Typography style={webStyle.text}>
                    Browse Latest Projects
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}  style={{ padding: theme.spacing(2)}}>
                <Box style={webStyle.input_container}>
                  <TextField
                    onChange={this.handleSearchTextChange}
                    placeholder={"Search Project, Skill, or Category"}
                    className="input-box"
                    style={{ width: "100%" }}
                    id="down"
                    onKeyPress={this.handleSearchTextKeyPressed}
                    value={searchText}
                    data-testid="searchInputId"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchText && (
                            <button
                              style={webStyle.clearIconButton}
                              onClick={this.handleClearSearchText}
                            >
                              <ClearIcon />
                            </button>
                          )}
                          <button
                            style={{
                              backgroundColor: activeTabColor || '#EC952D',
                              borderRadius: "50%",
                              width: "46px",
                              height: "46px",
                              border: "none",
                              color: "#fff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: loading ? "notAllowed" : "pointer",
                              opacity: loading ? 0.5 : 1,
                            }}
                            onClick={this.onSearchHandler}
                            disabled={loading}
                          >
                            <SearchIcon />
                          </button>
                        </InputAdornment>
                      ),
                      style: { borderRadius: "35px", paddingRight: "5px" },
                    }}
                  />
                </Box>
                </Grid>
                </Grid>
              </Box>
              <Box
                mb={3}
              >
                <GenericTab
                  className={"alltabs"}
                  activeTabColor={"#EC952D"}
                  tabLabels={tabs}
                  activeTab={activeTab}
                  handleTabChange={this.handleTabChange}
                />
              </Box>

              {!projectList?.length && !projectLoading && (
                <Paper className="border-block p-0 projectloading-main" >
                  <div className='projectloading'> <h2>No Project Found</h2></div>
                </Paper>
              )}
              {projectList?.slice(0, 4).map((card: any, index: number) => (
                <ProjectCard
                  key={card?.id}
                  card={card}
                  onSubmitBidClick={this.navigateToSubmitBidPageCards}
                  onBodyClick={this.navigateToBidProjectDetailsCards}
                  fav={this.addFavouritesProjectCards}
                  onShowSimilar={this.onShowSimilar}
                />
              ))}
              {projectLoading && (
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              {this.renderMoreJobs()}
            </Grid>
            <Grid item xs={12} md={4}>
              {this.renderProfileCount()}
              
              <Paper style={webStyle.activebid_mainbox1}>
                <Box
                  display="flex"
                  justifyContent="space=between"
                  alignItems="center"
                  style={webStyle.activebid_subbox}
                >
                  <Typography style={webStyle.activebid_text}>
                    {configJSON.activeProjectsLabel} 
                  </Typography>
                </Box>
                {this.renderactiveproject()}
              </Paper>
              <Paper style={webStyle.activebid_mainbox}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space=between"
                  style={webStyle.activebid_subbox}
                >
                  <Typography style={webStyle.activebid_text}>
                    Active Quotes
                  </Typography>
                </Box>

                {this.state.activeBidList?.data?.length ? (
                  this.state.activeBidList.data.map((data: any, index: any) => {
                    return (
                      <Box data-testid="projectCard" key={index} pt={3} style={webStyle.activebid_sub_box1} onClick={()=>this.handleProjectDetailNavigation(data?.id)}>
                        <Typography style={webStyle.text_fw}>
                          {data.attributes.project.project_name}
                        </Typography>
                        <Box
                          pb={3}
                          pt={1.2}
                          alignItems="center"
                          justifyContent="space-between"
                          display="flex"
                          style={{ gap: "0px" }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            {data.attributes?.sponsor_photo[0]?.url ? <img
                              src={data.attributes?.sponsor_photo[0]?.url}
                              data-id="profile_image3"
                              alt="person-image"
                              style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }}
                            /> : <AccountCircleIcon data-id="profile_image4" style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }} />}
                            <Box pl={1}>
                              <Typography style={webStyle.name_text} variant="h6">
                                {data.attributes.sponsor.full_name}
                              </Typography>
                              {data?.attributes?.sponsor_skills?.length > 0 && <Typography style={webStyle.expert_text}>
                                {data.attributes.sponsor_skills[0]?.name} {data.attributes.sponsor_skills[1]?.name}
                              </Typography>}
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              textAlign="center"
                              alignItems="center"
                              style={{
                                borderRadius: "18px",
                                background: "#EC952D40"
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "inter",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#000000",
                                  padding: "6.5px 10px 6.5px 10px"
                                }}
                              >
                                {this.renderAmount(data)}
                              </Typography>
                            </Box>
                            <Typography style={data.attributes.project.project_status === "active" ? webStyle.bidaccepted_text : webStyle.bidrejected_text}>
                              {data.attributes.project.project_status}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Typography style={{ textAlign: 'center', padding: '25px', fontSize: '1rem', fontWeight: 400, lineHeight: 1.5, letterSpacing: '0.00938em' }} variant="body1">No Quotes found</Typography>
                )
                }
                {this.renderViewAllButton()}
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <DashboardFooter history={undefined} location={undefined} param={"browse-all"} />
      </div>

      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  buttonDesign:{
    padding: "0px 10px",
    justifyItems: "center",
    alignItems: "center",
    height: "35px",
    cursor: "pointer"
  },
  clearIconButton: {
    backgroundColor: "#EC952D",
    borderRadius: "50%",
    width: "46px",
    height: "46px",
    border: "none",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    cursor: "pointer",
  },
  parallaxtext1: {
    fontFamily: "inter",
    fontWeight: "bold",
    color: "white",
    lineHeight: "1.5"
  } as const,
  parallaxtext2: {
    fontFamily: "inter",
    color: "white",
    lineHeight: "2"
  } as const,
  getStartedButtonLink: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: "white"
  },
  getstartednow_btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#EC952D",
    color: "#fff",
    padding: "16px 36px",
    borderRadius: "32px",
    border: "none",
    fontSize: "14px",
    fontWeight: "bold"
  } as const,
  getstart_btnbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
    width: "1103px",
    margin: "40px auto",
    backgroundColor: "#ffffff",
    borderRadius: "18px",
    border: "1px solid #EC952D",
    color: "#8083A3",
    position: "relative"
  } as const,
  clearIcon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "red"
  } as const,
  text: {
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "1.5rem",
    fontFamily: "inter",
    color: "#222222"
  } as const,
  carousal_container: {
    flexWrap: "wrap",
    padding: "20px 25px",
    border: "1px solid #E4E6E8",
    maxWidth: "236px",
    borderRadius: "20px"
  } as const,
  carousalimg_box: {
    background: "#ffffff",
    width: "69px",
    height: "69px",
    borderRadius: "50%",
    border: "1px solid #E4E6E8",
    textAlign: "center",
  } as const,
  carousalimg_box_img: {
    maxWidth: " 100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  } as const,
  carousalheading: {
    color: "#222222",
    fontFamily: "inter",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0px",
  } as const,
  borderBlock: {
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    marginBottom: "30px",
    boxShadow: "none",
  },
  freelancerBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px'
  },
  blockHeading: {
    display: "flex",
    // textAlign: "center",
    paddingBottom: "24px",
    // justifyContent: "space-around"
  } as const,
  disable_chip: {
    color: "#8083A3",
    backgroundColor: "#F0F1F1",
    margin: "0px 12px 12px 0px"
  } as const,
  projectbidText: {
    border: "1px lightGrey",
    marginRight: "8px",
    color: "#222222",
    backgroundColor: "#ffffff",
    fontFamily: "inter",
    fontSize: "12px",
    fontWeight: "bold"
  } as const,

  yellow_btn: {
    background: "#EC952D",
    color: "#fff",
    padding: "15px 30px",
    borderRadius: "32px",
    border: "none",
    fontSize: "14px",
    fontWeight: "700"
  },
  select: {
    width: "120px"
  },
  link_text: {
    fontSize: "14px",
    fontFamily: "inter",
    marginTop: "20px"
  },
  card_header: {
    fontFamily: "inter",
    fontWeight: "bold"
  } as const,
  card_content: {
    fontFamily: "inter"
  },
  project_msg: {
    paddingLeft: "10px",
    fontWeight: "bold",
    fontFamily: "inter",
    cursor: "pointer",
  } as const,
  project_summary: {
    paddingLeft: "10px",
    fontWeight: "bold",
    fontFamily: "inter",
    cursor: "pointer",
  } as const,
  project_detail: {
    paddingRight: "158px",
    fontWeight: "bold",
    fontFamily: "inter",
    cursor: "pointer",
  } as const,

  switchbox_text1: {
    fontWeight: "bold",
    fontFamily: "inter",
    marginRight: "58px"
  } as const,
  switchbox_text2: {
    fontWeight: "bold",
    fontFamily: "inter",
    marginRight: "111px"
  } as const,
  //browse latest project css
  talent_contents: {
    maxWidth: "150px",
    paddingLeft: "15px"
  },
  talent_images: {
    background: "rgb(230, 246, 246, 0.68)",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  } as const,
  search: {
    display: "flex",
    alignIntem: "center",
    borderRadius: "30px"
  },
  input_container: {
    width: "100%",
    position: "relative"
  } as const,
  talent_blocks: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "20px 25px",
    border: "1px solid #E4E6E8",
    maxWidth: "235px",
    borderRadius: "20px"
  } as const,
  activebid_text: {
    fontFamily: "inter",
    fontWeight: "bold",
    fontSize: "20px"
  } as const,
  bidrejected_text: {
    color: "#D90000",
    justifyContent: "center",
    display: "flex",
    fontSize: "10px"
  } as const,
  bidaccepted_text: {
    color: "#16A700",
    justifyContent: "center",
    display: "flex",
    fontSize: "10px"
  } as const,
  hour_text: {
    fontFamily: "inter",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#EC952D"
  } as const,
  activebid_sub_box1: {
    borderBottom: "1px solid #E4E6E8"
  } as const,

  activebid_subbox: {
    gap: "0px"
  },
  expert_text: {
    fontSize: "10px",
    fontFamily: "inter"
  } as const,
  text_fw: {
    fontWeight: "bold",
    fontFamily: "inter",
    fontSize: "15px",
    cursor: "pointer",
  } as const,
  text_fw1: {
    fontWeight: "bold",
    fontFamily: "inter",
    fontSize: "16px"
  },
  name_text: {
    fontWeight: "bold",
    fontFamily: "inter",
    fontSize: "14px"
  } as const,
  activebid_mainbox: {
    padding: "20px",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    boxShadow: "none"
  },
  activebid_mainbox1: {
    padding: "20px",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    boxShadow: "none",
    margin: "24px 0px 24px 0px"
  },
  profilename_text: {
    marginTop: "12px",
    marginBottom: "6px",
    fontSize: "20px",
    fontFamily: "inter",
    fontWeight: "bold"
  } as const,
  location_img: {
    marginRight: "6px"
  },
  subtext: {
    fontFamily: "inter",
    fontSize: "14px",
    color:"#8083a3"
  },
  progressBar: {
    color: "green",
    width: "100px",
    height: "100px",
    marginBottom: "6px"
  },
  completedtext: {

    textAlign: "center",
    fontSize: "12px",
    color: "#8083A3",

  } as const,
  percentagetext: {
    position: "absolute",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222222"
  } as const,
  searchBar: {
    display: "flex",
    alignItems: "center",
    height: "58px",
    width: "321px",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(1),
    backgroundColor: "#f9f9f9"
  },
  searchInput: {
    flex: 1,
    paddingLeft: theme.spacing(1)
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "46px",
    width: "46px",
    backgroundColor: "yellow"
  },
  logodesign_text: {
    fontFamily: "inter",
    fontWeight: "bold",
    fontSize: "18px"
  } as const,
  SimilarIcons: {
    border: "1px solid #E4E6E8",
    borderRadius: "20px",
    padding: "5px",
    width: "36px",
    background: "white",
    margin: "0px 5px"
  },
  root: {
    flexGrow: 1,
    height: "100vh"
  },
  parallextextbox: {
    // position: "absolute",
    // marginTop: "81px",
    // left: "5.5%"
  } as const,
  paralleximg: {
    backgroundSize: 'cover',
    height: 'calc(100vh-70px)',
    position: "relative"
  },

  p1_btn: {
    color: "1B59E3"
  },
  navbar: {
    height: 84
  },

  horizontalCards: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20
  },
  horizontalCard: {
    width: 285,
    height: 109,
    backgroundColor: "#f2f2f2"
  },
  graph: {
    width: 810,
    height: 431,
    backgroundColor: "#f2f2f2"
  },
  card: {
    width: 390,
    height: 244,
    backgroundColor: "#f2f2f2"
  },
  project_card: {
    padding: "25px",
    border: "1px solid #E4E6E8",
    borderRadius: "14px",
    marginBottom: "24px",
    boxShadow: "none"
  },
  switch_box: {
    borderRadius: "10px",
    backgroundColor: "#EC952D",
    color: "#ffffff",
    cursor: "pointer"
  },
  bidsContainer: {
    width: 390,
    height: 1666,
    backgroundColor: "#f2f2f2",
    marginTop: 20
  },
  browserlatest_container: {
    backgroundColor: "#ffffff",
    marginTop: 20
  },
  typographySearchGrid: {
    marginTop: 20
  },
  typography: {
    width: 321,
    height: 58,
    backgroundColor: "#f2f2f2",
    marginRight: 20
  },
  tabsGrid: {
    marginTop: "20px"
  },
  tabs: {
    marginTop: "20px",
    height: "42px",
    backgroundColor: "#ffffff"
  },
  latestnews_container: {
    // marginTop: "300px",
    marginTop: "40px",
    width: "100%",
    backgroundColor: "#f2f2f2"
  },
  logodesign_btn: {
    background: "#F0F1F1",
    fontFamily: "inter",
    fontSize: "12px",
    width: "25%",
    justifyContent: "flex-start !important",
    marginTop: "20px !important",
    minHeight: "40px !important",
    position: "relative"
  } as const,
  chartHeading: {
    fontSize: "1.25rem",
    fontFamily: "inter",
    fontWeight: 700,
    color: "rgb(34, 34, 34)"
  },
  loaderStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  chartPaperStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px'
  },
  selectStyle: {
    width: '110px',
    marginRight: '10px'
  },
  chartLoaderStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: "400px"
  },
  msgTxtStyle: {
    padding: '30px',
    fontSize: '20px',
    fontWeight: 700,
    color: '#222'
  },
  browseMore: {
    display:"flex",
    justifyContent: "center",
  }
};

// Customizable Area End
