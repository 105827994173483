import React from "react";
import { Box,IconButton ,Tooltip} from "@material-ui/core";
import MyBidController from "./FreelancerBidNegotionController.web";
import { calander, wallet, UploadImg, download,info_circle } from "./assets";
import NegotiationItem from "./NegotiationItem.web";

export const getCostDisplayElement = (freelancerCostData: {currency: string, currency_symbol: string, amount: number}, sponsorCostData: {currency: string, currency_symbol: string, amount: number}, hourlyRated: boolean) => {
    return (
      <>
        {sponsorCostData?.currency_symbol} {sponsorCostData?.amount} {""}
        {  sponsorCostData?.currency != freelancerCostData?.currency &&
             <>
                <span style={{color:"#EC952D" , fontWeight:400, fontSize:"12px"}} >({freelancerCostData?.currency_symbol}{freelancerCostData?.amount})</span>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} 
                    title={`Converted from (${sponsorCostData?.currency}) into (${freelancerCostData?.currency}) based on today's conversion rate`}
                    arrow
                    placement="top-start"
                    >
                    <img src={info_circle} alt="info" width={18} height={18} />
                </Tooltip>
            </>
        }
        {hourlyRated && " /hr"}
      </>
    )
  } 
export default class MyBidNegotition extends MyBidController {

    render() {
        const {project,bid}=this.props;
        const isFixedPrice =project?.attributes?.budget_type==="fixed_price";
        const negotiations=bid?.attributes?.negotiations || [];
        return (
            <>
                <Box className="projects_filter_wrapper">
                    <h2 style={{color:"black"}} className="freelancer_resume">Milestones</h2>
                            <Box  className="mouse_event mildstone_outer_wrapper">
                                {
                                    bid?.attributes?.project_milestone?.data?.map((milestoneItem,index)=>(
                                    <div key={`${milestoneItem.title}-${index}`} style={{
                                        background:"#f6f6f9",
                                        borderRadius:"15px",
                                        padding:"10px",
                                        marginBottom:"15px"
                                    }}>
                                    <Box className="project_common_container">
                                    <span style={{color:"black"}}  className="project_owner_name">{milestoneItem?.attributes?.milestone_title}</span>
                                      </Box>
                                        <Box className="project_container1"></Box>
                                        <Box className="project_common_container milestone_container" 
                                        style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                                           {milestoneItem?.attributes?.milestone_description}
                                        </Box>
                    
                                        <Box className="project_container1"></Box>
                    
                                        <Box className="bid_negititation project_common_container">
                                            <Box className="mybid_dashboard_manu1">
                                                <img src={calander} alt="image" width={20} />
                                                <Box className="date_text">{milestoneItem?.attributes?.calculated_due_date}</Box>
                                            </Box>
                                            <Box className="mybid_dashboard_manu1 myBid_verify">
                                                <Box 
                                                    style={{
                                                        display:"flex",
                                                        alignItems:"center",
                                                        gap:"5px",
                                                        margin: 0,
                                                    }}
                                                    className="date_text">
                                                     <img src={wallet} alt="image" width={20} />   
                                                    {getCostDisplayElement(milestoneItem.attributes?.currency_conversion?.freelancer,milestoneItem.attributes?.currency_conversion?.sponsor,false)}
                                                </Box>
                                                <button className="verified-btn">Verified</button>
                                            </Box>
                                        </Box>
                                    </div>   
                                    ))
                                }
                             </Box>
                </Box>
                <Box className="projects_filter_wrapper">
                    <span style={{color:"black"}}  className="project_owner_name">Related Files</span>
                    <Box className="relatedFilesBox" justifyContent="start">
                        {
                            project.attributes.documents.length === 0 && 
                            <div data-testid="noFile" style={{color: "#757575",fontWeight: "bold",backgroundColor: "#f9f9f9",opacity: 0.9, marginTop:"10px"}}>
                                No File Found
                            </div>
                        }
                        {
                            project?.attributes?.documents?.map((item)=>{
                                const fileSize=this.converFileSize(item?.size);
                                return(
                                    <label
                                        key={item.blob_id}
                                        className="upload_tag_container"
                                        htmlFor="contained-button-file"
                                    >
                                        <Box display="flex" flexWrap="wrap" alignItems="center" gridGap="5px">
                                            <img src={UploadImg} alt="upload" width={20} height={20} />
                                            <span className="file_name_div">{item?.name}</span>
                                            <Box className="size_div">({fileSize})</Box>
                                        </Box>
                                        <IconButton data-testid="downloadBtn" onClick={()=>this.downloadFile(item?.url,item?.name)}>
                                           <img src={download} alt="upload" width={26} height={27} />
                                        </IconButton>
                                    </label>
                                )
                            })
                        }
                    </Box>
                </Box>
                            <Box className="mouse_event">
                                {
                                    negotiations?.map((negotiationItem,index)=>{
                                        const isLastItem=negotiations?.length-1===index;
                                        return(
                                            <NegotiationItem
                                                key={negotiationItem?.id}
                                                isFixedPrice={isFixedPrice}
                                                isLastItem={isLastItem}
                                                negotiation={negotiationItem}
                                                bid_id={bid?.id}
                                                relodeBid={this.props.relodeBid}
                                                profileCurrency={project?.attributes?.currency_conversion?.freelancer?.currency_symbol}
                                            />
                                        )
                                    })
                                }
                        </Box>
            </>
        );
    }
}