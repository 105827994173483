import React from "react";
import "./MyBid.css";
import { Box, Checkbox, FormControlLabel,FormControl,RadioGroup,Radio,Typography,Divider, Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { location, leftArrow, info_circle,images } from "./assets"
import PersonalDetailsController, { IProjectCost } from "../src/PersonalDetailsController.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import TermsAndServiceModal from "../../../components/src/TermsAndServiceModal.web";
import { configJSON } from "./AuctionBiddingController";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import ProjectLocalTime from "../../../components/src/ProjectLocalTime.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getSponsorTaxAmountElement as getSponsorProjectTaxAmount } from "../../../components/src/utill/getMultiCurrencyHtml";

const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            color: "#fff",
            fontWeight:400,
            backgroundColor: "#222222",
            padding:'8px',
            border:'none',
            borderRadius:'5px',
            maxWidth:'250px'

          },
          arrow: {
           color: "#222222",
          }
        },
        MuiSelect:{
            select: {
            paddingRight: '24px',
            fontWeight:700,
            color:'red'
        },
      }
      }
    
});
export default class SendAnOffer extends PersonalDetailsController {

    getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {freelancerCost?.amount} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                        <Tooltip  enterTouchDelay={0} leaveTouchDelay={3000} id="111" title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                        <p id="offer" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost.currency_symbol}{sponsorCost.amount})</p>
                        </Tooltip>
                }
                {hourlyRated && " /hr"}
            </>
        )
    } 

    getProjectTotalCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean,param?:string) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {param ? freelancerCost?.total_project_cost : freelancerCost?.total_project_cost_with_tax} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span  id="1" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost.currency_symbol}{param ? sponsorCost.total_project_cost : sponsorCost.total_project_cost_with_tax})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000}  title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                            <img  id="sendoffer" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                        </Tooltip>
                    </>
                }
                {hourlyRated && " /hr"}
            </>
        )
    } 

    getHireTotalProjectCostElement = (freelancerCost: any, sponsorCost: any, rateperhour: boolean) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {!rateperhour ? freelancerCost?.total_project_cost : freelancerCost?.total_first_milestone_cost_with_tax} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span  id="6008" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost.currency_symbol}{!rateperhour ? sponsorCost.total_project_cost : sponsorCost.total_first_milestone_cost_with_tax})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                            <img  id="sendoffer" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                        </Tooltip>
                    </>
                }
            </>
        )
    } 

    getHireProjectMilestoneCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean,param?:string) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {param ? freelancerCost?.first_milestone_cost:freelancerCost?.total_first_milestone_cost_with_tax} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost?.currency_symbol}{param ? sponsorCost?.first_milestone_cost:sponsorCost?.total_first_milestone_cost_with_tax})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCost?.currency}) into (${sponsorCost?.currency}) based on today's conversion rate`}>
                            <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
                        </Tooltip></>}
                {hourlyRated && " /hr"}
            </>
        )
    } 

    renderBackFunction = () => {
        return (
            <Box className="personal_details_container">
                <Box className="sendAnOffer_header_wrapper">
                    <Box
                        className="Negotiate_Bid newBid_container"
                        onClick={this.handleGoBackButton}
                    >
                        <img src={leftArrow} alt="image" style={{ paddingRight: "10px" }} />
                        <span>Back</span>
                    </Box>
                    <span className="SendAnOffer_container">Review & Send an Offer</span>
                </Box>
                
            </Box>
        );
    };
    renderSendAnOffer = () => {
        if (!this.state.AuctionBiddingData) {
            return;
        }
        return (
            <Box className="personal-details-wrapper My-cv-latter frelancer_details back-color" style={webStyle.sendBoxStyle}>

                <Box style={webStyle.freelancerOfferBoxStyle}>
                    <Grid container>
                        <Grid item md={3} sm={12}>
                            <Box className="imageBox">
                        <Box className="imageInnerBox">
                              <img src={this.state.AuctionBiddingData.attributes?.photo && this.state.AuctionBiddingData.attributes.photo.length > 0 ? this.state.AuctionBiddingData.attributes.photo[0]?.url : images} />
                         </Box>
                        <svg viewBox="0 0 16 16">                                                                             
                            <circle
                                cx="8"
                                cy="8"
                                r="7"
                                fill="#16A700"
                                stroke="white"
                                stroke-width="2"
                            />
                        </svg>
                            </Box>
                        </Grid>
                        <Grid item md={9} sm={12}>
                            <Box>
                                <Box className="heading1">{this.state.AuctionBiddingData.attributes?.profile_name}</Box>
                                <Box className="location_container margin-container" style={webStyle.offerSkillStyle}>
                                    {this.state.AuctionBiddingData.attributes?.profile_skills?.map((e: any, index: any) => {
                                        let skill;
                                        if (this.state.AuctionBiddingData.attributes.profile_skills.length != index + 1) {
                                            skill = e + " | "
                                        }
                                        else {
                                            skill = e
                                        }
                                        return (
                                            skill
                                        )
                                    })}
                                </Box>
                                <Box style={webStyle.offerLocation} className="bid_negititation price_negotiation margin-container">
                                    <Box display="flex">
                                        <img src={location} alt="location" width={17} />
                                        <span>{this.state.AuctionBiddingData.attributes.location}</span>
                                    </Box>
                                    <Box display="flex" >
                                        <AccessTimeIcon className="watchIcon" />
                                
                                        <ProjectLocalTime
                                            timezoneData={this.state.AuctionBiddingData.attributes.freelancer_time_zone } 
                                            /> &nbsp; <span>{configJSON.localTimeLabel}</span> 
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    };
    renderPaymentTerms = () => {
        return (
            <>
                <Box className="personal_details_container">
                    <h1>Payment Terms</h1>
                </Box>
                <Box className="Payment_termsAndCondition">
                {configJSON.paymentPolicyTxt1}
                <span onClick={()=>this.getTermOfServiceInfo(configJSON.lancerscapePaymentPolicy, "")} 
                    style={webStyle.lancerPaymentStyle}
                    data-testid="paymentPolicy"
                    >
                    {configJSON.paymentPolicyTxt2}
                </span>
                {configJSON.paymentPolicyTxt3}
                </Box>
            </>
        );
    };
    renderPaymentOption = () => {
        const {projectCost}=this.state;
        const isFixedPriceProject=this.state.showAllProjectsData?.budget_type==="fixed_price";
        const convertedCost=projectCost?.currency_conversion;
        const freelancerConvertedCost=convertedCost?.freelancer;
        const sponsorConvertedCost=convertedCost?.sponsor;
        return  <Box className="mouse_event">
                    <Box className="aboutTheSponsor_Box project_common_container">
                        <Box className="bid_negititation payment_responsive">
                            <Box className="price_negotiation_outer_wrapper">
                                <Box className="price_negotiation">Payment Options</Box>
                                <Box className="price_cantainer1">{this.state.AuctionBiddingData?.attributes?.project?.budget_type=="hourly_price" ? 'Hourly Price' : 'Fixed Price'}</Box>
                            </Box>

                            <Box className="price_negotiation_outer_wrapper">
                                <Box className="price_negotiation fixedPrice">
                                    Pay a fixed price for your project&nbsp;&nbsp;
                                    <Tooltip  enterTouchDelay={0} leaveTouchDelay={3000} 
                                        title={`This is the price you and ${this.state.AuctionBiddingData?.attributes?.profile_name} have agreed upon.`}
                                        arrow
                                        placement="top-start"
                                        >
                                        <img src={info_circle} alt="info" width={18} height={18} />
                                    </Tooltip>
                                </Box>
                                <Box className="price_cantainer1">
                                {isFixedPriceProject ? this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false) :this.getHireProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="project_container1"></Box>

                    <Box className="project_common_container">
                        <Box className="fixedPrice">
                            <span className="freelancer_resume">
                                Deposit funds into Escrow&nbsp;&nbsp;
                            </span>
                            <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} 
                                title={configJSON.escrowLabel}
                                arrow
                                placement="top-start"
                            >
                                <img src={info_circle} alt="info" width={18} height={18} />
                            </Tooltip>
                        </Box>
                        <Box>
                            <FormControl component="fieldset" className="depositFundRes">
                            <RadioGroup value={this.state.selectedOption} onChange={this.handleOptionChange}  name="payment_option" >
                                {
                                    isFixedPriceProject && (
                                        <>
                                            <FormControlLabel 
                                                style={{margin:"0px"}}
                                                value="full_project" control={<Radio color="primary" />}
                                                label={ 
                                                    <Typography style={{ color: "#8083a3", fontSize: "15px" }}>
                                                        Deposit {this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false)} for the whole project
                                                    </Typography>
                                                }
                                            />
                                            <Box style={{marginLeft:"30px"}}>
                                                {
                                                    projectCost?.taxForTotalProject?.map((item)=>(
                                                        <Box style={webStyle.taxItemContainer}>
                                                            <Typography style={webStyle.taxItemText}>{item?.name}({item.cost})</Typography>
                                                            <Typography style={webStyle.taxItemText}>
                                                                {getSponsorProjectTaxAmount({
                                                                    freelancer:item?.lancer_currency,
                                                                    sponsor:{
                                                                        amount:item.total_cost,
                                                                        currency:sponsorConvertedCost.currency,
                                                                        currency_symbol:sponsorConvertedCost.currency_symbol,
                                                                    }
                                                                })}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }
                                                <Divider/>
                                                    <Box style={webStyle.taxItemContainer}>
                                                        <Typography style={webStyle.taxItemText}>Project Cost</Typography>
                                                        <Typography style={webStyle.taxItemText}>{this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"true")}</Typography>
                                                    </Box>
                                                    <Divider/>
                                                    <Box style={webStyle.taxItemContainer}>
                                                        <Typography style={webStyle.taxItemText}>Total Cost</Typography>
                                                        <Typography style={webStyle.taxItemText}>{this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}</Typography>
                                                    </Box>
                                            </Box>
                                        </>
                                    )
                                }
                                {
                                    isFixedPriceProject ? (this.state.showAllProjectsData.project_milestones.length>1) && (
                                        this.milestoneFunds(freelancerConvertedCost , sponsorConvertedCost , projectCost)
                                        ) : (
                                            this.milestoneFunds(freelancerConvertedCost , sponsorConvertedCost , projectCost)
                                        )
                                }
                            </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
        };
    milestoneFunds = (freelancerConvertedCost: any , sponsorConvertedCost: { currency_symbol: any; currency: any; } , projectCost: IProjectCost | null)=>{
      return(
        <>
        <FormControlLabel 
            style={{fontSize:"13px",margin:"0px"}} 
            value="first_milestone" 
            control={<Radio color="primary"/>} 
            label={
                <Typography style={{ color: "#8083a3", fontSize: "15px" }}>
                    Deposit  {this.getHireProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false)} lesser amount to cover the first milestone 
                </Typography>
            } 
        />
         <Box style={{marginLeft:"30px"}}>
            {
                projectCost?.taxForFirstMilestone?.map((item)=>(
                    <Box style={webStyle.taxItemContainer}>
                        <Typography style={webStyle.taxItemText}>{item?.name}({item.cost})</Typography>
                        <Typography style={webStyle.taxItemText}>
                            {getSponsorProjectTaxAmount({
                                sponsor:{
                                    amount:item.total_cost,
                                    currency_symbol:sponsorConvertedCost.currency_symbol,
                                    currency:sponsorConvertedCost.currency,
                                },
                                freelancer:item?.lancer_currency,
                            })}
                        </Typography>
                    </Box>
                ))
            }
            <Divider/>
                <Box style={webStyle.taxItemContainer}>
                    <Typography style={webStyle.taxItemText}>Milestone Cost</Typography>
                    <Typography style={webStyle.taxItemText}>{this.getHireProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"true")}</Typography>
                </Box>
                <Divider/>
                <Box style={webStyle.taxItemContainer}>
                    <Typography style={webStyle.taxItemText}>Total Cost</Typography>
                    <Typography style={webStyle.taxItemText}>{this.getHireProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}</Typography>
                </Box>
         </Box>
        </>
      )
    }
    renderWorkDescription = () => {
        return (
            <>
                <Box className="personal_details_container">
                    <h1>Work Description</h1>
                </Box>
                <Box className="mouse_event">
                    <Box className="project_common_container">
                        <Box className="Payment_termsAndCondition padding text-left">
                            Related Job Listing
                        </Box>
                        <Box className="profile_wrapper">
                            <Box className="project_owner_name">{this.state.showAllProjectsData?.project_name}</Box>
                            <Box className="Payment_termsAndCondition">{`(#${this.state.showAllProjectsData?.id})`}</Box>
                        </Box>
                    </Box>

                    <Box className="project_container1"></Box>

                    <Box>
                        <Box className="project_common_container">
                            <Box className="Payment_termsAndCondition padding text-left">
                                Add Description of Work
                            </Box>
                            <Box style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="Logodesign_div">
                                {this.state.showAllProjectsData?.description}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    };
    renderTermsAndConditions = () => {
        return (
            <>
                <Box className="Payment_termsAndCondition">
                    <FormControlLabel

                        control={<Checkbox name="checkedA" value={this.state.termsAccepted} onChange={this.handleTermsChange} />}
                        label
                        className="termsConditionCheckbox"
                    />
                    Yes, I understand and agree to the{" "}
                    <span 
                        data-testid="termsOfService"
                        style={{cursor:"pointer"}} 
                        onClick={()=>this.getTermOfServiceInfo("Terms of Service", "")}>Lancerscape Terms of Services</span>, including the{" "}
                        <span 
                        data-testid="userAggrements"
                        style={{cursor:"pointer"}} 
                        onClick={()=>this.getTermOfServiceInfo("User Agreement", "")}>User Agreement
                        </span> and 
                        <span 
                        onClick={()=>this.getTermOfServiceInfo(configJSON.privacyPolicy,"")}
                        data-testid="privacyPolicy"
                        style={{cursor:"pointer"}} 
                        > {configJSON.privacyPolicy}
                    </span>.
                </Box>
                <Box className="all_btns_wrapper">
                    <button className="save_as_draft" style={{display:"none"}}>Save as Draft</button>
                   
                    {/* this button will be removed once the payment api will be provided from BE */}

                    <button onClick={this.handleGoBackButton} className="save_as_draft">Cancel</button>
                    <button data-testid="goToPayAndHire" onClick={this.handlePay} className="btn-continue" >
                        {this.state.hireFreelancerLoading?<CircularProgress size={24} />:"Hire"}
                    </button>
                </Box>
            </>
        );
    };
    renderProjectMilestone = () => {

       
        return  <Box className="paymentTermsBox">
                    <Box className="projectTitleContain">
                        <Box className="projectTitleContainLeftBox">
                            <span className="personNameSpan">{configJSON.projectMilestonesHeading}</span>
                            <span className="personSkills">{configJSON.projectMilestoneDetails}</span>
                        </Box>
                        <Box>
                        </Box>
                    </Box>

                    <Box className="paymentTermsContainBox desktopView">
                        <Box className="milestoneHeadingBox">
                            <span className="headingSerialSpan">{configJSON.milestoneNo}</span>
                            <span className="headingTitleSpan">{configJSON.milestoneTitle}</span>
                            <span className="headingDescriSpan">{configJSON.milestoneDesc}</span>
                            <span className="headingDateSpan">{configJSON.milestoneDueDate}</span>
                            <span className="headingAmountSpan">{configJSON.milestoneAmt}</span>
                        </Box>
                        {this.state.AuctionBiddingData?.attributes?.project_milestone?.data?.map((item:any, index:number) => {
                            const convertedCost=item.attributes?.currency_conversion;
                            const freelancerConvertedCost=convertedCost?.freelancer;
                            const sponsorConvertedCost=convertedCost?.sponsor;
                            return (
                            <Box key={item.id} className="milestoneRowBox">
                                <span className="serialSpan">{index + 1}</span>
                                <span className="titleSpan">
                                    <div data-testid={`addmilestone${index + 1}`}>
                                        <label className="addmilestonelabel">{item?.attributes.milestone_title}</label>
                                    </div>
                                </span>
                                <span className="descriSpan">
                                    <div data-testid={`addmilestoneinputdesc${index + 1}`}>
                                        <label style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="addmilestonelabel">{item?.attributes.milestone_description}</label>
                                    </div>
                                </span>
                                <span className="dateSpan">
                                    <div data-testid={`addmilestoneinputdate${index + 1}`}>
                                        <label className="addmilestonelabel">{!item.attributes.calculated_due_date ? "No data found" : moment(item?.attributes.calculated_due_date).format('DD-MM-YYYY')}</label>
                                    </div>
                                </span>
                                <span className="amountSpan">
                                  
                                    <div data-testid={`addmilestoneinputnumber${index + 1}`}>
                                        <label className="addmilestonelabel">{this.getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}</label>
                                    </div>
                                </span>
                            </Box>
                        )})}
                    </Box>
                    <Box className="mobileView">
                        <TableContainer>
                                <Table
                                    aria-label="simple table"
                                    className="table-block"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="payment-tablecell"><span className="headingSerialSpan">{configJSON.milestoneNo}</span></TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                            <span className="headingTitleSpan">{configJSON.milestoneTitle}</span>
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                            <span className="headingDescriSpan">{configJSON.milestoneDesc}</span>
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                            <span className="headingDateSpan">{configJSON.milestoneDueDate}</span>
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                            <span className="headingAmountSpan">{configJSON.milestoneAmt}</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.AuctionBiddingData?.attributes?.project_milestone?.data?.map((item:any, index:number) => {
                                        const convertedCost=item.attributes?.currency_conversion;
                                        const freelancerConvertedCost=convertedCost?.freelancer;
                                        const sponsorConvertedCost=convertedCost?.sponsor;
                                                return (

                                                    <TableRow key={item.id} className="milestoneRowBox" >
                                                        <TableCell
                                                            className="payment-tablerowcell"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                           {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div data-testid={`addmilestone${index + 1}`}>
                                                                <label className="addmilestonelabel">{item?.attributes.milestone_title}</label>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left" className="resDescription">
                                                            <div data-testid={`addmilestoneinputdesc${index + 1}`} className="resDescWidth">
                    <label style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} className="addmilestonelabel">{item?.attributes.milestone_description}</label>
                  </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                        <div data-testid={`addmilestoneinputdate${index + 1}`}>
                    <label className="addmilestonelabel">{!item.attributes.calculated_due_date ? "No data found" : moment(item?.attributes.calculated_due_date).format('DD-MM-YYYY')}</label>
                  </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                        <div data-testid={`addmilestoneinputnumber${index + 1}`}>
                    <label className="addmilestonelabel">{this.getProjectCostElement(freelancerConvertedCost, sponsorConvertedCost, false)}</label>
                  </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        ) }
                                    </TableBody>
                                </Table>
                        </TableContainer>
                    </Box>

                </Box>
         
         };
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />
                <Box className="body_wrapper">
                    {this.renderBackFunction()}
                    {this.renderSendAnOffer()}
                    {this.renderPaymentTerms()}
                    {this.renderPaymentOption()}
                    {this.renderProjectMilestone()}
                    {this.renderWorkDescription()}
                    {this.renderTermsAndConditions()}
                </Box>
                <Sponserfooter navigation={this.props.navigation}  />
                <TermsAndServiceModal
                    open={this.state.termsAndService?.open}
                    description={this.state.termsAndService?.description}
                    loading={this.state.termsAndService?.loading}
                    setOpen={this.handleCloseTermsAndServiceModal}
                    title={this.state.termsAndService?.title}
                />
            </ThemeProvider>
        );


    }
}

 

const webStyle={
     taxItemContainer:{
        display:"flex",alignItems:"center",justifyContent:"space-between",
     },
     taxItemText:{
        color:"#8083a3",
        fontSize:"12px"
     },
     freelancerOfferBoxStyle:{
        display:'flex',
        justifyContent:'space-between',
     },
     offerSkillStyle:{
        fontSize:'14px',
        fontWeight:500
     },
     offerLocation:{
        color: '#8083a3',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        display:'flex',
        alignItems:'center'
    },
    sendBoxStyle:{
        backgroundColor:'rgba(245,245,250,0.7)'
},
lancerPaymentStyle:{
     cursor:'pointer',
     textDecoration: 'underline',
     color: '#00a7a2',
     fontWeight: 600,
      margin:'0 2px'
    },
}