import React from "react";
import {
    Box,
    Button,
    Typography,

    // Customizable Area Start
    Grid, TextField, Divider,
    InputAdornment,
    IconButton,
    Dialog, DialogActions, DialogContent
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const Linkdin = require("../src/assets/Linkdin.png")
export const Facebook = require("../src/assets/Fb.png")
import { lancerscapelogo } from "../../dashboard/src/assets";
export const contact = require("../src/assets/Help_and_Support.png")

const theme = createTheme({

});
const labelStyle = {
    color: '#8083A3',
};
const inputStyle = {
    height: "45px",
    width: "100%",
};

// Customizable Area End
import SignInController, {
    Props,
} from "./SignInController.web";
import FacebookSignin from "../../../components/src/auth/FacebookSignin.web";
import LinkedinSignin from "../../../components/src/auth/LinkedinSignin.web";
import GoogleSignin from "../../../components/src/auth/GoogleSignin.web";
import ContactusForm from "../../../components/src/ContactusForm";

export default class SignIn extends SignInController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const {facebookSignupLoading}=this.state;
        return (
            // Customizable Area Start
            <>
                <style>
                {`
                    .MuiDialog-paperWidthSm{
                        width:100%;
                        max-width:400px;
                        border-radius:15px;
                    }
                `}
                </style>
                <div
                    id="Main" style={{ paddingBottom: "35px" }}>
                    <Box display='flex' justifyContent='end' style={{marginTop:'6px',marginRight:'10px'}}>
                        <img style={{height:'5vh'}} src={contact} alt="" />
                        <a style={{marginTop:'5px',marginLeft:'5px' , color:'#8083A3', textDecoration:'none',cursor:'pointer'}}
                            data-testid="openDialogClick" onClick={()=>this.handleOpenDialog()}>Help and Support
                        </a>
                    </Box>
                    <Grid >
                        <Box py={4}
                            style={{ textAlign: "center" }}>
                            <img
                                style={webStyle.logo_img1} src={lancerscapelogo} alt=""
                            />
                        </Box>
                        <Box
                            boxShadow={1}
                            display="flex"
                            maxWidth={450}
                            maxHeight={766}
                            borderRadius="18px"
                            border=" 1px solid #E4E6E8"
                            flexDirection="column"
                            justifyContent="center"
                            margin="auto"
                        >
                            <Typography align="center" style={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "20px",
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            }}>
                                Sign In
                            </Typography>
                            <Divider />
                            <Box 
                            paddingX={3}>
                                <Grid container direction="column" >
                                    <Grid style={webStyle.facebookLinkedin_mainbox1}>
                                        <FacebookSignin
                                            facebookLoginLoading={facebookSignupLoading}
                                            submitHandler={this.handleFacebookLoginResponse}
                                            buttonText="Sign In with Facebook"
                                        />
                                     </Grid>
                                    <Grid style={webStyle.linkedin_mainbox1} >
                                        <LinkedinSignin
                                            errorHandler={this.handleLinkedinLoginError}
                                            submitHandler={this.handleLinkedinLoginResponse}
                                            buttonText="Sign In with Linkedin"
                                            loading={this.state.linkedinSignInLoading}
                                        />
                                    </Grid>
                                    <Grid style={webStyle.google_mainbox1} >
                                        <GoogleSignin
                                            errorHandler={this.onGoogleLoginError}
                                            submitHandler={this.onGoogleLoginSuccess}
                                            buttonText="Sign In with Google"
                                            loading={this.state.googleSignInLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            <Box margin="auto"
                                paddingX={3}
                            >
                                <form onSubmit={this.handleValidate}>
                                <TextField
                                    InputProps={{
                                        style: inputStyle,
                                    }}
                                    autoFocus={true}
                                    tabIndex = {0}
                                    data-test-id="emailInput"
                                    fullWidth
                                    type="email"
                                    margin="normal"
                                    id="email"
                                    label="Email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={this.state.email}
                                    helperText={this.state.signinErrors?.email}
                                    error={this.state.signinErrors?.email?true:false}
                                    onChange={this.handleEmailChange}
                                    InputLabelProps={{ style: labelStyle }}
                                >
                                </TextField>
                                
                                <TextField
                                    InputProps={{
                                        style: inputStyle,
                                        endAdornment:(
                                            <InputAdornment position="end">
                                              <IconButton
                                               tabIndex={-1}
                                                onClick={this.handleSigninVisibility}
                                              >
                                                {this.state.signinVisible ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    tabIndex={1}
                                    data-test-id="passInput"
                                    fullWidth
                                    margin="normal"
                                    id="password"
                                    type={this.state.signinVisible?"text":"password"}
                                    label="Password"
                                    placeholder="Enter your password"
                                    name="password"
                                    helperText={this.state.signinErrors?.password}
                                    error={this.state.signinErrors?.password?true:false}
                                    onChange={this.handlePasswordChange}
                                    InputLabelProps={{ style: labelStyle }}
                                >
                                </TextField>
                                <Typography tabIndex={-1}
                                    data-testid="forgotPassword"
                                    style={webStyle.fp_text}
                                    onClick={this.navigateToForgotPassword}
                                > Forgot Password?</Typography>
                                <div tabIndex={2}>
                                <Button className="butttext" style={{
                                    borderRadius: "20px",
                                    marginBottom: "30px",
                                    marginTop: "15px",
                                    color: "white",
                                    padding: "0px",
                                    height: "43px",
                                    // width: "390px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    overflow:'hidden',
                                    backgroundColor: "#ec952d"
                                }}
                                    fullWidth
                                    id="login"
                                    type="submit"
                                    variant="contained"
                                    disableFocusRipple
                                >
                                     {this.state.signinLoading?<CircularProgress size={24} />:"Sign In"}
                                    </Button>
                                    </div>
                              </form>
                            </Box>
                            <Box justifyContent="center" alignItems="center" style={webStyle.p2_box}>
                                <Typography style={{
                                    // marginTop: "21px",
                                    // marginLeft: "95px",
                                    color: "#8083A3",
                                    fontSize: "14px",
                                    fontWeight: "normal"
                                }}>
                                    Don't have an account?
                                    <span
                                        data-test-id="signupbutton"
                                        style={{
                                            fontWeight: 'bold',
                                            marginLeft: '5px',
                                            cursor: "pointer"
                                        }}
                                        onClick={this.SignUpOnclick}
                                    >Sign Up
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </div>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                    fullWidth
                    maxWidth="sm"
                    style={webStyle.mainmodal as React.CSSProperties}
                >
                    <DialogContent style={webStyle.dialogContent}>
                        <ContactusForm handleCloseDialog={this.handleCloseDialog}/>
                    </DialogContent>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    p_color: {
        color: "#EC952D"
    },

    logo_img1: {
        height: "40px",
        width: "150px",

    },

    facebookLinkedin_mainbox1: {
        paddingBottom: "10px",
        paddingTop: "30px"
    },
    facebookLinkedin_subbox1: {
        height: "38px",
        borderRadius: "23px",
        border: "1px solid #E4E6E8",
        padding: "6px",
        justifyContent: "space-around"
    },
    facebook_img1: {
        marginLeft: "-20px",
        marginTop: "5px",
        height: "16px",
        paddingTop: "4px",
        position: "absolute"
    },
    linkedin_img1: {
        marginLeft: "-20px",
        marginTop: "7px",
        height: "14px",
        paddingTop: "5px",
        position: "absolute"
        
    },

    linkedin_mainbox1: {
        paddingBottom: "10px"
    },
    google_mainbox1: {
        paddingBottom: "30px"
    },
    fp_text: {
        cursor: "pointer",
        textAlign: "right" as const,
        color: "black",
        marginBottom: "45px",
        fontSize:"14px",
        fontWeight:700,
    },

    p2_box: {
        cursor: "hover",
        height: "60px",
        backgroundColor: " #F5F5FA",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        display:'flex'
    },
    mainmodal: {
        padding:"0px",
      },
    dialogContent: {
        padding: "0px",
    }
};
// Customizable Area End


